import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import IndoorFlooringPIPs from './IndoorFlooringPIPs';

const IndoorFlooring = ({ data, appendChanged, projectPIP, pipValues }) => {
  return (
    <>
      <Grid container direction="row" xs={12} style={{ height: 'Auto' }}>
        <Grid container alignItems="flex-start" item xs={12} style={{ marginLeft: '2%', marginTop: '1%' }}>
          <Typography variant="h5">Indoor Flooring</Typography>
        </Grid>
      </Grid>
      <Grid alignItems="flex-start" direction="row" xs={12} item>
        {data &&
          data.map((pip, index) => {
            return (
              <>
                <IndoorFlooringPIPs
                  key={index}
                  _id={pip._id}
                  displayName={pip.displayName}
                  appendChanged={(a, b) => appendChanged(a, b)}
                  disabledValue={false}
                  values={pip?.options}
                  projectPIP={projectPIP && projectPIP}
                  pipValues={pipValues}
                />
              </>
            );
          })}
      </Grid>
    </>
  );
};

export default IndoorFlooring;
