import React, { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles, Container, Backdrop, CircularProgress, Box } from '@material-ui/core';
import PageTitle from '../insightsPage/insights/components/PageTitle';
import ComponentTitle from '../insightsPage/insights/components/ComponentTitle';
import IGBCStatus from './components/IGBCStatus';
import IGBCSummary from './components/IGBCSummary';
import IGBCCategoriesTogglers from './components/IGBCCategoriesTogglers';
import IGBCCategoriesTable from './components/IGBCCategoriesTable';
import { IGBCCategoryContext } from '../../../projectRouting/Index';
import { PreviousPageContext } from '../../../routing/IndexRouting';
import { useAppApiRequest } from '../../../hooks/useAppApiRequest';
import ApiError from '../../common/Error/ApiError';
import { DummyResponse } from './dummyData';
import { IGBCCategories } from './utils/types';

const useStyles = makeStyles((theme) => ({
  main: {
    backgroundColor: 'rgba(24, 50, 119, 0.07)',
  },
  container: {
    paddingTop: theme.spacing(10),
    height: '100vh',
    paddingLeft: '2%',
    paddingRight: '2%',
  },
}));

export default function Certification(props) {
  const classes = useStyles();
  const params = useParams();
  const projectId = params && params.id;
  const [categories, setCategories] = useState([]);
  const [igbcCertificationData, setigbcCertificationData] = useState('');
  const [categoriesData, setCategoriesData] = useState('');
  const [selectedCategoryData, setSelectedCategoryData] = useState('');
  const [dataUploadprogress, setDataUploadprogress] = useState('');
  const [documentUploadPercentage, setDocumentUploadPercentage] = useState('');
  const [bargraphData, setBargraphdata] = useState([]);
  const { igbcCategoryContext, handleIGBCCategory } = useContext(IGBCCategoryContext);
  const { setPreviousPage } = useContext(PreviousPageContext);
  const { data: response, isError: isErrorCategory, isFetching: isFetchingCategory } = useAppApiRequest(`/igbc-category`, { method: 'GET' });
  const { data: re, isError: isErrorData, isFetching: isFetchingData } = useAppApiRequest(`/projectv2/${projectId}/igbc-data`, { method: 'GET' }, [projectId]);

  useEffect(() => {
    setPreviousPage('certification');
  }, []);

  useEffect(() => {
    if (response && response.data && response.data && response.data.data.data) {
      let a = response.data.data.data.find((d) => d.categoryName === IGBCCategories.sustainableDesign)?.categoryName;
      let b = response.data.data.data.find((d) => d.categoryName === IGBCCategories.waterConservation)?.categoryName;
      let c = response.data.data.data.find((d) => d.categoryName === IGBCCategories.energyEfficiency)?.categoryName;
      let d = response.data.data.data.find((d) => d.categoryName === IGBCCategories.material)?.categoryName;
      let e = response.data.data.data.find((d) => d.categoryName === IGBCCategories.residentHealth)?.categoryName;
      let f = response.data.data.data.find((d) => d.categoryName === IGBCCategories.innovation)?.categoryName;

      setCategories([a, b, c, d, e, f]);
    }
  }, [response]);

  useEffect(() => {
    if (re && re.data && re.data.data && re.data.data.data) {
      const IGBCdata = re.data.data.data.certifications.find((certification) => certification.name === 'IGBC');
      const categories = IGBCdata && IGBCdata.categories;
      console.log(IGBCdata.categories);
      let a = IGBCdata.categories.find((d) => d.name === IGBCCategories.sustainableDesign);
      let b = IGBCdata.categories.find((d) => d.name === IGBCCategories.waterConservation);
      let c = IGBCdata.categories.find((d) => d.name === IGBCCategories.energyEfficiency);
      let d = IGBCdata.categories.find((d) => d.name === IGBCCategories.material);
      let e = IGBCdata.categories.find((d) => d.name === IGBCCategories.residentHealth);
      let f = IGBCdata.categories.find((d) => d.name === IGBCCategories.innovation);
      setBargraphdata([a, b, c, d, e, f]);
      setigbcCertificationData(IGBCdata && IGBCdata);
      setDataUploadprogress(IGBCdata && IGBCdata.dataUploadprogress ? IGBCdata.dataUploadprogress : 0);
      setDocumentUploadPercentage(IGBCdata && IGBCdata.overall && IGBCdata.overall.documentUploadPercentage ? IGBCdata.overall.documentUploadPercentage : 0);
      setCategoriesData(categories && categories);
    }
  }, [re]);

  useEffect(() => {
    if (igbcCategoryContext && categoriesData) {
      const data = categoriesData.find((data) => data.name === igbcCategoryContext);

      if (data) setSelectedCategoryData(data && data);
      else setSelectedCategoryData('');
    }
  }, [igbcCategoryContext, categoriesData]);

  if (isErrorCategory || isErrorData) {
    return (
      <div className={classes.container}>
        <ApiError />
      </div>
    );
  }

  return (
    <div className={classes.main}>
      <Backdrop open={isFetchingCategory || isFetchingData} className={classes.backdrop} style={{ zIndex: 3, color: '#66E3BE' }}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <Box className={classes.container}>
        <PageTitle title="Building Certification" />

        <IGBCStatus />

        <ComponentTitle title="IGBC Summary" marginbottom={0} />

        <IGBCSummary
          igbcCertificationData={igbcCertificationData && igbcCertificationData}
          dataUploadprogress={dataUploadprogress && dataUploadprogress}
          projectId={projectId}
          bargraphData={bargraphData && bargraphData}
        />
      </Box>

      <Box>
        <IGBCCategoriesTogglers
          categories={categories && categories}
          igbcCategoryContext={igbcCategoryContext && igbcCategoryContext}
          handleIGBCCategory={(data) => handleIGBCCategory(data)}
        />
      </Box>

      <Box style={{ paddingLeft: '2%', paddingRight: '2%' }}>
        <IGBCCategoriesTable
          igbcCategoryContext={igbcCategoryContext && igbcCategoryContext}
          selectedCategoryData={selectedCategoryData && selectedCategoryData}
        />
      </Box>
    </div>
  );
}
