import React from 'react';
import { Typography, Grid } from '@material-ui/core';
import numberWithCommas from '../../../../../utils/numberToComma';
import useStyles from './styles';
import { useInterventionDetailsContext } from '../context/InterventionDetails';

const InteriorPaintsProjectInputs = (props) => {
  const classes = useStyles();
  const {
    get: { getQuantityValue, getQuantityLabel, getQuantityUnit },
    schema,
  } = useInterventionDetailsContext();
  const projectCategory = getQuantityValue(schema.interiorPaints_projectSubcategory);
  const penthouseEnabled = getQuantityValue(schema.interiorPaints_penthouseEnabled);

  return (
    <>
      {projectCategory === 'apartment' && !penthouseEnabled && (
        <Grid container item xs={12} direction="column">
          <Typography className={classes.textHeader1}>Project Inputs</Typography>
          <Grid container item xs={12} spacing={0} className={classes.gridContainer}>
            {[
              schema.interiorPaints_studio,
              schema.interiorPaints_oneBHK,
              schema.interiorPaints_twoBHK,
              schema.interiorPaints_threeBHK,
              schema.interiorPaints_fourBHK,
              schema.interiorPaints_studioCarpetArea,
              schema.interiorPaints_carpetAreaOneBHK,
              schema.interiorPaints_carpetAreaTwoBHK,
              schema.interiorPaints_carpetAreaThreeBHK,
              schema.interiorPaints_carpetAreaFourBHK,
            ].map((item, index) => (
              <div key={index} className={classes.gridItem}>
                <Typography className={classes.textHeader5}>{getQuantityLabel(item)}</Typography>
                <Typography className={classes.textHeader3}>
                  {getQuantityValue(item)} {getQuantityUnit(item)}
                </Typography>
              </div>
            ))}
          </Grid>
          <Grid container item xs={12} style={{ paddingTop: '50px', paddingBottom: '20px' }} />
        </Grid>
      )}

      {projectCategory === 'villa' && (
        <Grid container item xs={12} direction="column">
          <Typography className={classes.textHeader1}>Project Inputs</Typography>
          <Grid container item xs={12} spacing={0} className={classes.gridContainer}>
            {[
              schema.interiorPaints_villaOneBHK,
              schema.interiorPaints_villaTwoBHK,
              schema.interiorPaints_villaThreeBHK,
              schema.interiorPaints_villaFourBHK,
              schema.interiorPaints_villaCarpetAreaOneBHK,
              schema.interiorPaints_villaCarpetAreaTwoBHK,
              schema.interiorPaints_villaCarpetAreaThreeBHK,
              schema.interiorPaints_villaCarpetAreaFourBHK,
            ].map((item, index) => (
              <React.Fragment key={index}>
                <div className={classes.gridItem}>
                  <Typography className={classes.textHeader5}>{getQuantityLabel(item)}</Typography>
                  <Typography className={classes.textHeader3}>
                    {getQuantityValue(item)} {getQuantityUnit(item)}
                  </Typography>
                </div>
                {(index + 1) % 4 === 0 && <div className={classes.gridItemInvisible}></div>}
              </React.Fragment>
            ))}
          </Grid>
          <Grid container item xs={12} style={{ paddingTop: '50px', paddingBottom: '20px' }} />
        </Grid>
      )}

      {projectCategory === 'apartment' && penthouseEnabled && (
        <Grid container item xs={12} direction="column">
          <Typography className={classes.textHeader1}>Project Inputs</Typography>
          <Grid container item xs={12} spacing={0} className={classes.gridContainer}>
            {[
              schema.interiorPaints_studio,
              schema.interiorPaints_oneBHK,
              schema.interiorPaints_twoBHK,
              schema.interiorPaints_threeBHK,
              schema.interiorPaints_fourBHK,
              schema.interiorPaints_studioCarpetArea,
              schema.interiorPaints_carpetAreaOneBHK,
              schema.interiorPaints_carpetAreaTwoBHK,
              schema.interiorPaints_carpetAreaThreeBHK,
              schema.interiorPaints_carpetAreaFourBHK,
            ].map((item, index) => (
              <div key={index} className={classes.gridItem}>
                <Typography className={classes.textHeader5}>{getQuantityLabel(item)}</Typography>
                <Typography className={classes.textHeader3}>
                  {getQuantityValue(item)} {getQuantityUnit(item)}
                </Typography>
              </div>
            ))}
          </Grid>
          <Grid container item xs={12} spacing={0} className={classes.gridContainer}>
            {[
              schema.interiorPaints_penthouseOneBHK,
              schema.interiorPaints_penthouseTwoBHK,
              schema.interiorPaints_penthouseThreeBHK,
              schema.interiorPaints_penthouseFourBHK,
              schema.interiorPaints_penthouseCarpetAreaOneBHK,
              schema.interiorPaints_penthouseCarpetAreaTwoBHK,
              schema.interiorPaints_penthouseCarpetAreaThreeBHK,
              schema.interiorPaints_penthouseCarpetAreaFourBHK,
            ].map((item, index) => (
              <React.Fragment key={index}>
                <div className={classes.gridItem}>
                  <Typography className={classes.textHeader5}>{getQuantityLabel(item)}</Typography>
                  <Typography className={classes.textHeader3}>
                    {getQuantityValue(item)} {getQuantityUnit(item)}
                  </Typography>
                </div>
                {(index + 1) % 4 === 0 && <div className={classes.gridItemInvisible}></div>}
              </React.Fragment>
            ))}
          </Grid>
          <Grid container item xs={12} style={{ paddingTop: '50px', paddingBottom: '20px' }} />
        </Grid>
      )}
    </>
  );
};

export default InteriorPaintsProjectInputs;
