import AdvancedInterventionDetails from '../../defaultIntervention/AdvancedInterventionDetails';
import BasicInterventionDetails from '../../defaultIntervention/BasicInterventionDetails';
import NaturalStpBasicParameters from '../../nstp/NaturalStpBasicParameters';
import NaturalStpAdvancedParameters from '../../nstp/NaturalStpAdvancedParameters';
import NaturalStpPipParameters from '../../nstp/NaturalStpPipParameters';
import HWMApartmentCustomAdvancedParameters from '../../hwmApartment/HWMApartmentCustomAdvancedParameters';
import HWMApartmentCustomBasicParameters from '../../hwmApartment/HWMApartmentCustomBasicParameters';
import HWMApartmentProjectInputs from '../../hwmApartment/HWMApartmentProjectInputs';
import HWMClubHouseProjectInputs from '../../hwmClubhouse/HWMClubhouseProjectInputs';
import HWMClubHouseCustomAdvancesParameters from '../../hwmClubhouse/HWMClubhouseCustomAdvanceParameters';
import HWMClubHouseCustomBasicParameters from '../../hwmClubhouse/HWMClubhouseCustomBasicParameters';
import HWMVillaCustomBasicParameters from '../../hwmVilla/HWMVillaCustomBasicParameters';
import HWMVillaCustomAdvancesParameters from '../../hwmVilla/HWMVillaCustomAdvancesParameters';
import HWMVillaProjectInputs from '../../hwmVilla/HWMVillaProjectInputs';
import HWMPentHouseProjectInputs from '../../hwmPenthouse/HWMPentHouseProjectInputs';
import HWMPentHouseCustomAdvancesParameters from '../../hwmPenthouse/HWMPentHouseCustomAdvancesParameters';
import HWMPentHouseCustomBasicParameters from '../../hwmPenthouse/HWMPentHouseCustomBasicParameters';
import HWMSwimmingPoolCustomBasicParameters from '../../hwmSwimmingpool/HWMSwimmingPoolCustomBasicParameters';
import HWMSwimmingPoolCustomAdvancesParameters from '../../hwmSwimmingpool/HWMSwimmingPoolCustomAdvancesParameters';
import HWMSwimmingPoolProjectInputs from '../../hwmSwimmingpool/HWMSwimmingPoolProjectInputs';
import SPVApartmentCustomBasicParameters from '../../spvApartment/SPVApartmentCustomBasicParameters';
import SPVApartmentProjectInputs from '../../spvApartment/SPVApartmentProjectInputs';
import SPVApartmentCustomAdvancedParameters from '../../spvApartment/SPVApartmentCustomAdvancedParameters';
import SPVVillaCustomAdvancesParameters from '../../spvVilla/SPVVillaCustomAdvancedParameters';
import SPVVillaCustomBasicParameters from '../../spvVilla/SPVVillaCustomBasicParameters';
import SPVVillaProjectInputs from '../../spvVilla/SPVVillaProjectInputs';
import TwrCustomBasicParameters from '../../twr/TwrCustomBasicParameters';
import TwrCustomAdvanceParameters from '../../twr/TwrCustomAdvancedParameters';
import TwrCustomPipParameters from '../../twr/TwrCustomPipParameters';
import SwmCustomBasicParameters from '../../swm/SwmCustomBasicParameters';
import SwmProjectInputParameters from '../../swm/SwmProjectInputParameters';
import SwmCustomAdvanceParameters from '../../swm/SwmCustomAdvanceParameters';
import EfficientFixturesKitchenSinkCustomBasicParameters from '../../efficientFixturesKitchenSink/efficientFixturesKitchenSinkCustomBasicParameters';
import EfficientFixturesKitchenSinkCustomAdvancedParameters from '../../efficientFixturesKitchenSink/efficientFixturesKitchenSinkCustomAdvancedParameters';
import EfficientFixturesKitchenSinkProjectInputs from '../../efficientFixturesKitchenSink/efficientFixturesKitchenSinkProjectInputs';
import EfficientFixturesShowerCustomAdvancedParameters from '../../efficientFixturesShower/efficientFixturesShowerCustomAdvancedParameters';
import EfficientFixturesShowerProjectInputs from '../../efficientFixturesShower/efficientFixturesShowerProjectInputs';
import EfficientFixturesShowerCustomBasicParameters from '../../efficientFixturesShower/efficientFixturesShowerCustomBasicParameters';
import EfficientFixturesDualFlushCustomBasicParameters from '../../efficientFixturesDualFlush/efficientFixturesDualFlushCustomBasicParameters';
import EfficientFixturesDualFlushCustomAdvancedParameters from '../../efficientFixturesDualFlush/efficientFixturesDualFlushCustomAdvancedParameters';
import EfficientFixturesDualFlushProjectInputs from '../../efficientFixturesDualFlush/efficientFixturesDualFlushProjectInputs';
import EfficientFixturesFaucetCustomAdvancedParameters from '../../efficientFixturesFaucet/EfficientFixturesFaucetCustomAdvancedParameters';
import EfficientFixturesFaucetCustomBasicParameters from '../../efficientFixturesFaucet/EfficientFixturesFaucetCustomBasicParameters';
import EfficientFixturesFaucetProjectInputs from '../../efficientFixturesFaucet/EfficientFixturesFaucetProjectInputs';
import EfficientFixturesHealthFaucetCustomAdvancedParameters from '../../efficientFixturesHealthFaucet/EfficientFixturesHealthFaucetCustomAdvancedParameters';
import EfficientFixturesHealthFaucetCustomBasicParameters from '../../efficientFixturesHealthFaucet/EfficientFixturesHealthFaucetCustomBasicParameters';
import EfficientFixturesHealthFaucetProjectInputs from '../../efficientFixturesHealthFaucet/EfficientFixturesHealthFaucetProjectInputs';
import SPVPenthouseCustomBasicParameters from '../../spvPenthouse/SPVPenthouseCustomBasicParameters';
import SPVPenthouseProjectInputs from '../../spvPenthouse/SPVPenthouseProjectInputs';
import SPVPenthouseCustomAdvancedParameters from '../../spvPenthouse/SPVPenthouseCustomAdvancedParameters';
import SPVClubhouseCustomBasicParameters from '../../spvClubhouse/SPVClubhouseCustomBasicParameters';
import SPVClubhouseProjectInputs from '../../spvClubhouse/SPVClubhouseProjectInputs';
import SPVClubhouseCustomAdvancedParameters from '../../spvClubhouse/SPVClubhouseCustomAdvancedParameters';
import WWMCustomAdvancedParameters from '../../wwm/WWMCustomAdvancedParameters';
import WWMCustomBasicParameters from '../../wwm/WWMCustomBasicParameters';
import WWMProjectInputs from '../../wwm/WWMProjectInputs';
import CSPCustomAdvancedParameters from '../../csp/CSPCustomAdvancedParameters';
import CSPCustomBasicParameters from '../../csp/CSPCustomBasicParameters';
import CSPProjectInputs from '../../csp/CSPProjectInputs';
import EPNSCustomBasicParameters from '../../exteriorPaintsNonSunny/EPNSCustomBasicParameters';
import EPNSCustomAdvancesParameters from '../../exteriorPaintsNonSunny/EPNSCustomAdvancedParameters';
import EPNSProjectInputs from '../../exteriorPaintsNonSunny/EPNSProjectInputs';
import EPSCustomBasicParameters from '../../exteriorPaintsSunny/EPSCustomBasicParameters';
import EPSCustomAdvancesParameters from '../../exteriorPaintsSunny/EPSCustomAdvancedParameters';
import EPSProjectInputs from '../../exteriorPaintsSunny/EPSProjectInputs';
import RRWHCustomBasicParameters from '../../rrwh/rrwhCustomBasicParameters';
import RRWHCustomAdvancesParameters from '../../rrwh/rrwhCustomAdvancedParameters';
import RRWHProjectInputs from '../../rrwh/rrwhProjectInputs';
import SRWHCustomBasicParameters from '../../srwh/srwhCustomBasicParameters';
import SRWHCustomAdvancesParameters from '../../srwh/srwhCustomAdvancedParameters';
import SRWHProjectInputs from '../../srwh/srwhProjectInputs';
import StructuralConcreteCustomBasicParameters from '../../structuralConcrete/StructuralConcreteCustomBasicParameters';
import StructuralConcreteCustomAdvancedParameters from '../../structuralConcrete/StructuralConcreteCustomAdvancedParameters';
import StructuralConcreteProjectInputs from '../../structuralConcrete/StructuralConcreteProjectInputs';
import StructuralSteelCustomAdvancedParameters from '../../structuralSteel/StructuralSteelCustomAdvancedParameters';
import StructuralSteelCustomBasicParameters from '../../structuralSteel/StructuralSteelCustomBasicParameters';
import StructuralSteelProjectInputs from '../../structuralSteel/StructuralSteelProjectInputs';
import InteriorPaintsCustomAdvancedParameters from '../../interiorPaints/InteriorPaintsCustomAdvancedParameters';
import InteriorPaintsCustomBasicParameters from '../../interiorPaints/InteriorPaintsCustomBasicParameters';
import InteriorPaintsProjectInputs from '../../interiorPaints/InteriorPaintsProjectInputs';
import { capitalizeFilterValues, ProductFilterKeys, getFilterDetailsForProduct } from '../../../intervention/utils';
import IndoorFlooringLnDCustomBasicParameters from '../../indoorFlooringLnD/IndoorFlooringLnDCustomBasicParamters';
import IndoorFlooringLnDCustomAdvancedParameters from '../../indoorFlooringLnD/IndoorFlooringLnDCustomAdvanceParamters';
import IndoorFlooringLnDProjectInputs from '../../indoorFlooringLnD/IndoorFlooringLnDProjectInputs';
import IndoorFlooringBedroomProjectInputs from '../../indoorFlooringBedroom/IndoorFlooringBedroomProjectInputs';
import IndoorFlooringBedroomCustomAdvancedParameters from '../../indoorFlooringBedroom/IndoorFlooringBedroomCustomAdvanceParamters';
import IndoorFlooringBedroomCustomBasicParameters from '../../indoorFlooringBedroom/IndoorFlooringBedroomCustomBasicParamters';
import IndoorFlooringKitchenProjectInputs from '../../indoorFlooringKitchen/IndoorFlooringKitchenProjectInputs';
import IndoorFlooringKitchenCustomAdvancedParameters from '../../indoorFlooringKitchen/IndoorFlooringKitchenCustomAdvanceParamters';
import IndoorFlooringKitchenCustomBasicParameters from '../../indoorFlooringKitchen/IndoorFlooringKitchenCustomBasicParamters';
import IndoorFlooringBathroomProjectInputs from '../../indoorFlooringBathroom/IndoorFlooringBathroomProjectInputs';
import IndoorFlooringBathroomCustomAdvancedParameters from '../../indoorFlooringBathroom/IndoorFlooringBathroomCustomAdvanceParamters';
import IndoorFlooringBathroomCustomBasicParameters from '../../indoorFlooringBathroom/IndoorFlooringBathroomCustomBasicParamters';
import IndoorFlooringUnBProjectInputs from '../../indoorFlooringUnB/IndoorFlooringUnBProjectInputs';
import IndoorFlooringUnBCustomAdvancedParameters from '../../indoorFlooringUnB/IndoorFlooringUnBCustomAdvanceParamters';
import IndoorFlooringUnBCustomBasicParameters from '../../indoorFlooringUnB/IndoorFlooringUnBCustomBasicParamters';

const KongInterventionsComponentsMapper = {
  hwmApartment: {
    schemaKey: 'hwmApartment',
    product: {
      selected: 'hwmApartment__pi_selectedProductIdentifier',
      default: 'hwmApartment_product',
    },
    basic: HWMApartmentCustomBasicParameters,
    advanced: HWMApartmentCustomAdvancedParameters,
    project: HWMApartmentProjectInputs,
    filterProducts: ({ products, schema, get }) => {
      const { getQuantityValue } = get;
      const option = getQuantityValue(schema.hwmApartment__pi_option);
      const swhType = getQuantityValue(schema.hwmApartment__pi_swhType);
      const swhVariant = getQuantityValue(schema.hwmApartment__pi_swhVariant);
      const hpType = getQuantityValue(schema.hwmApartment__pi_hpType);

      return products?.reduce((memo, product) => {
        if (option === 'solar water heater') {
          if (product?.subcategory?.name === 'Solar Water Heaters') {
            if (swhType === 'central') {
              if (product?.filters[ProductFilterKeys?.system_types]?.includes('Centralized Solar Heater')) {
                memo.push(product);
              }
            }

            if (swhType === 'individual' && product?.filters[ProductFilterKeys?.variant]?.includes(swhVariant?.toUpperCase())) {
              if (product?.filters[ProductFilterKeys?.system_types]?.includes('Individual Solar Heater')) {
                memo.push(product);
              }
            }
          }
        }

        if (option === 'heat pump') {
          if (hpType === 'commercial') {
            if (
              product?.subcategory?.name === 'Heat Pumps' &&
              (product?.filters[ProductFilterKeys?.system_types]?.includes('Swimming Pool Heat Pumps') ||
                product?.filters[ProductFilterKeys?.system_types]?.includes('Commercial Heat pumps'))
            ) {
              memo.push(product);
            }
          }

          if (hpType === 'domestic') {
            if (product?.subcategory?.name === 'Heat Pumps' && product?.filters[ProductFilterKeys?.system_types]?.includes('Domestic Heat Pumps')) {
              memo.push(product);
            }
          }
        }

        if (option === 'hybrid') {
          if (product?.subcategory?.name === 'Hybrid system') {
            memo.push(product);
          }
        }

        return memo;
      }, []);
    },
    productSubcategoryAndFilter: ({ product, schema, get }) => {
      const { getQuantityValue } = get;
      const category = 'Hot Water Management';
      const option = getQuantityValue(schema.hwmApartment__pi_option);
      const swhType = getQuantityValue(schema.hwmApartment__pi_swhType);
      const hpType = getQuantityValue(schema.hwmApartment__pi_hpType);
      const filterByValues = [];
      let subCategory = '';

      if (option === 'solar water heater') {
        subCategory = 'Solar Water Heaters';
        if (swhType === 'central') {
          filterByValues.push('Centralized Solar Heater');
        }

        if (swhType === 'individual') {
          filterByValues.push('Individual Solar Heater');
        }
      }

      if (option === 'heat pump') {
        subCategory = 'Heat Pumps';
        if (hpType === 'commercial') {
          filterByValues.push('Commercial Heat pumps');
        }

        if (hpType === 'domestic') {
          filterByValues.push('Domestic Heat Pumps');
        }
      }

      if (option === 'hybrid') {
        /*on miro subcategory is 'Hybrid, but on Google Sheet(riff) the subcategory is 'Hybrid system' hence making subcategory as 'Hybrid system'*/
        subCategory = 'Hybrid system';
      }
      return getFilterDetailsForProduct({ product, category, subCategory }, filterByValues);
    },
  },
  spvApartment: {
    schemaKey: 'spvApartment',
    product: {
      selected: 'spvApartment__pi_selectedProductIdentifier',
      default: 'spvApartment_product',
    },
    basic: SPVApartmentCustomBasicParameters,
    advanced: SPVApartmentCustomAdvancedParameters,
    project: SPVApartmentProjectInputs,
    filterProducts: ({ products, schema, get }) => {
      const { getQuantityValue } = get;
      const updatedProducts = capitalizeFilterValues(products, 'Inverter Type');

      const panelType = getQuantityValue(schema.spvApartment__pi_panelType);
      const inverterType = getQuantityValue(schema.spvApartment__pi_inverterType);

      return updatedProducts?.reduce((memo, product) => {
        if (
          product?.filters[ProductFilterKeys?.panel_type]?.includes(panelType) &&
          product?.filters[ProductFilterKeys?.inverter_type]?.includes(inverterType)
        ) {
          memo.push(product);
        }
        return memo;
      }, []);
    },
    productSubcategoryAndFilter: ({ product, schema, get }) => {
      const { getQuantityValue } = get;
      const category = 'SPV';
      const subCategory = '';
      const panelType = getQuantityValue(schema.spvApartment__pi_panelType);
      const inverterType = getQuantityValue(schema.spvApartment__pi_inverterType);
      return getFilterDetailsForProduct({ product, category, subCategory }, [panelType, inverterType]);
    },
  },
  spvPenthouse: {
    schemaKey: 'spvPenthouse',
    product: {
      selected: 'spvPenthouse__pi_selectedProductIdentifier',
      default: 'spvPenthouse_product',
    },
    basic: SPVPenthouseCustomBasicParameters,
    advanced: SPVPenthouseCustomAdvancedParameters,
    project: SPVPenthouseProjectInputs,
    filterProducts: ({ products, schema, get }) => {
      const { getQuantityValue } = get;
      const updatedProducts = capitalizeFilterValues(products, 'Inverter Type');
      const panelType = getQuantityValue(schema.spvPenthouse__pi_panelType);
      const inverterType = getQuantityValue(schema.spvPenthouse__pi_inverterType);

      return updatedProducts?.reduce((memo, product) => {
        if (
          product?.filters[ProductFilterKeys?.panel_type]?.includes(panelType) &&
          product?.filters[ProductFilterKeys?.inverter_type]?.includes(inverterType)
        ) {
          memo.push(product);
        }
        return memo;
      }, []);
    },
    productSubcategoryAndFilter: ({ product, schema, get }) => {
      const { getQuantityValue } = get;
      const category = 'SPV';
      const subCategory = '';
      const panelType = getQuantityValue(schema.spvPenthouse__pi_panelType);
      const inverterType = getQuantityValue(schema.spvPenthouse__pi_inverterType);
      return getFilterDetailsForProduct({ product, category, subCategory }, [panelType, inverterType]);
    },
  },
  spvClubhouse: {
    schemaKey: 'spvClubhouse',
    product: {
      selected: 'spvClubhouse__pi_selectedProductIdentifier',
      default: 'spvClubhouse_product',
    },
    basic: SPVClubhouseCustomBasicParameters,
    advanced: SPVClubhouseCustomAdvancedParameters,
    project: SPVClubhouseProjectInputs,
    filterProducts: ({ products, schema, get }) => {
      const { getQuantityValue } = get;
      const updatedProducts = capitalizeFilterValues(products, 'Inverter Type');
      const panelType = getQuantityValue(schema.spvClubhouse__pi_panelType);
      const inverterType = getQuantityValue(schema.spvClubhouse__pi_inverterType);

      return updatedProducts?.reduce((memo, product) => {
        if (
          product?.filters[ProductFilterKeys?.panel_type]?.includes(panelType) &&
          product?.filters[ProductFilterKeys?.inverter_type]?.includes(inverterType)
        ) {
          memo.push(product);
        }
        return memo;
      }, []);
    },
    productSubcategoryAndFilter: ({ product, schema, get }) => {
      const { getQuantityValue } = get;
      const category = 'SPV';
      const subCategory = '';
      const panelType = getQuantityValue(schema.spvClubhouse__pi_panelType);
      const inverterType = getQuantityValue(schema.spvClubhouse__pi_inverterType);
      return getFilterDetailsForProduct({ product, category, subCategory }, [panelType, inverterType]);
    },
  },
  efficientFixturesHealthFaucet: {
    schemaKey: 'efficientFixturesHealthFaucet',
    product: {
      selected: 'efficientFixturesHealthFaucet__pi_selectedProductIdentifier',
      default: 'efficientFixturesHealthFaucet_product',
    },
    basic: EfficientFixturesHealthFaucetCustomBasicParameters,
    advanced: EfficientFixturesHealthFaucetCustomAdvancedParameters,
    project: EfficientFixturesHealthFaucetProjectInputs,
    filterProducts: ({ products, schema, get }) => {
      return products;
    },
    productSubcategoryAndFilter: ({ product }) => {
      const category = 'Plumbing Fixtures';
      const subCategory = 'Health Faucet';
      return getFilterDetailsForProduct({ product, category, subCategory });
    },
  },
  efficientFixturesFaucet: {
    schemaKey: 'efficientFixturesFaucet',
    product: {
      selected: 'efficientFixturesFaucet__pi_selectedProductIdentifier',
      default: 'efficientFixturesFaucet_product',
    },
    basic: EfficientFixturesFaucetCustomBasicParameters,
    advanced: EfficientFixturesFaucetCustomAdvancedParameters,
    project: EfficientFixturesFaucetProjectInputs,
    filterProducts: ({ products, schema, get }) => {
      return products;
    },
    productSubcategoryAndFilter: ({ product }) => {
      const category = 'Plumbing Fixtures';
      const subCategory = 'Faucets';
      return getFilterDetailsForProduct({ product, category, subCategory });
    },
  },
  efficientFixturesKitchenSink: {
    schemaKey: 'efficientFixturesKitchenSink',
    product: {
      selected: 'efficientFixturesKitchenSink__pi_selectedProductIdentifier',
      default: 'efficientFixturesKitchenSink_product',
    },
    basic: EfficientFixturesKitchenSinkCustomBasicParameters,
    advanced: EfficientFixturesKitchenSinkCustomAdvancedParameters,
    project: EfficientFixturesKitchenSinkProjectInputs,
    filterProducts: ({ products, schema, get }) => {
      return products;
    },
    productSubcategoryAndFilter: ({ product }) => {
      const category = 'Plumbing Fixtures';
      const subCategory = 'Kitchen Sink';
      return getFilterDetailsForProduct({ product, category, subCategory });
    },
  },
  efficientFixturesShower: {
    schemaKey: 'efficientFixturesShower',
    product: {
      selected: 'efficientFixturesShower__pi_selectedProductIdentifier',
      default: 'efficientFixturesShower_product',
    },
    basic: EfficientFixturesShowerCustomBasicParameters,
    advanced: EfficientFixturesShowerCustomAdvancedParameters,
    project: EfficientFixturesShowerProjectInputs,
    filterProducts: ({ products, schema, get }) => {
      return products;
    },
    productSubcategoryAndFilter: ({ product }) => {
      const category = 'Plumbing Fixtures';
      const subCategory = 'Shower';
      return getFilterDetailsForProduct({ product, category, subCategory });
    },
  },
  efficientFixturesDualFlush: {
    schemaKey: 'efficientFixturesDualFlush',
    product: {
      selected: 'efficientFixturesDualFlush__pi_selectedProductIdentifier',
      default: 'efficientFixturesDualFlush_product',
    },
    basic: EfficientFixturesDualFlushCustomBasicParameters,
    advanced: EfficientFixturesDualFlushCustomAdvancedParameters,
    project: EfficientFixturesDualFlushProjectInputs,
    filterProducts: ({ products, schema, get }) => {
      return products;
    },
    productSubcategoryAndFilter: ({ product }) => {
      const category = 'Plumbing Fixtures';
      const subCategory = 'Flushing';
      return getFilterDetailsForProduct({ product, category, subCategory });
    },
  },
  spvVilla: {
    schemaKey: 'spvVilla',
    product: {
      selected: 'spvVilla__pi_selectedProductIdentifier',
      default: 'spvVilla_product',
    },
    basic: SPVVillaCustomBasicParameters,
    advanced: SPVVillaCustomAdvancesParameters,
    project: SPVVillaProjectInputs,
    filterProducts: ({ products, schema, get }) => {
      const { getQuantityValue } = get;
      const updatedProducts = capitalizeFilterValues(products, 'Inverter Type');
      const panelType = getQuantityValue(schema.spvVilla__pi_panelType);
      const inverterType = getQuantityValue(schema.spvVilla__pi_inverterType);

      return updatedProducts?.reduce((memo, product) => {
        if (
          product?.filters[ProductFilterKeys?.panel_type]?.includes(panelType) &&
          product?.filters[ProductFilterKeys?.inverter_type]?.includes(inverterType)
        ) {
          memo.push(product);
        }
        return memo;
      }, []);
    },
    productSubcategoryAndFilter: ({ product, schema, get }) => {
      const { getQuantityValue } = get;
      const category = 'SPV';
      const subCategory = '';
      const panelType = getQuantityValue(schema.spvVilla__pi_panelType);
      const inverterType = getQuantityValue(schema.spvVilla__pi_inverterType);
      return getFilterDetailsForProduct({ product, category, subCategory }, [panelType, inverterType]);
    },
  },
  hwmClubhouse: {
    schemaKey: 'hwmClubhouse',
    product: {
      selected: 'hwmClubhouse__pi_selectedProductIdentifier',
      default: 'hwmClubhouse_product',
    },
    basic: HWMClubHouseCustomBasicParameters,
    advanced: HWMClubHouseCustomAdvancesParameters,
    project: HWMClubHouseProjectInputs,
    filterProducts: ({ products, schema, get }) => {
      const { getQuantityValue } = get;
      const option = getQuantityValue(schema.hwmClubhouse__pi_option);
      const swhType = getQuantityValue(schema.hwmClubhouse__pi_swhType);
      const swhVariant = getQuantityValue(schema.hwmClubhouse__pi_swhVariant);
      const hpType = getQuantityValue(schema.hwmClubhouse__pi_hpType);

      return products?.reduce((memo, product) => {
        if (option === 'solar water heater') {
          if (product?.subcategory?.name === 'Solar Water Heaters') {
            if (swhType === 'central') {
              if (product?.filters[ProductFilterKeys?.system_types]?.includes('Centralized Solar Heater')) {
                memo.push(product);
              }
            }

            if (swhType === 'individual' && product?.filters[ProductFilterKeys?.variant]?.includes(swhVariant?.toUpperCase())) {
              if (product?.filters[ProductFilterKeys?.system_types]?.includes('Individual Solar Heater')) {
                memo.push(product);
              }
            }
          }
        }

        if (option === 'heat pump') {
          if (hpType === 'commercial') {
            if (
              product?.subcategory?.name === 'Heat Pumps' &&
              (product?.filters[ProductFilterKeys?.system_types]?.includes('Swimming Pool Heat Pumps') ||
                product?.filters[ProductFilterKeys?.system_types]?.includes('Commercial Heat pumps'))
            ) {
              memo.push(product);
            }
          }

          if (hpType === 'domestic') {
            if (product?.subcategory?.name === 'Heat Pumps' && product?.filters[ProductFilterKeys?.system_types]?.includes('Domestic Heat Pumps')) {
              memo.push(product);
            }
          }
        }
        return memo;
      }, []);
    },
    productSubcategoryAndFilter: ({ product, schema, get }) => {
      const { getQuantityValue } = get;
      const category = 'Hot Water Management';
      const option = getQuantityValue(schema.hwmClubhouse__pi_option);
      const swhType = getQuantityValue(schema.hwmClubhouse__pi_swhType);
      const hpType = getQuantityValue(schema.hwmClubhouse__pi_hpType);
      const filterValues = [];
      let subCategory = '';
      if (option === 'solar water heater') {
        subCategory = 'Solar Water Heaters';
        if (swhType === 'central') {
          filterValues.push('Centralized Solar Heater');
        }
        if (swhType === 'individual') {
          filterValues.push('Individual Solar Heater');
        }
      }

      if (option === 'heat pump') {
        subCategory = 'Heat Pumps';
        if (hpType === 'commercial') {
          filterValues.push('Commercial Heat pumps');
        }

        if (hpType === 'domestic') {
          filterValues.push('Domestic Heat Pumps');
        }
      }
      return getFilterDetailsForProduct({ product, category, subCategory }, filterValues);
    },
  },
  hwmVilla: {
    schemaKey: 'hwmVilla',
    product: {
      selected: 'hwmVilla__pi_selectedProductIdentifier',
      default: 'hwmVilla_product',
    },
    basic: HWMVillaCustomBasicParameters,
    advanced: HWMVillaCustomAdvancesParameters,
    project: HWMVillaProjectInputs,
    filterProducts: ({ products, schema, get }) => {
      const { getQuantityValue } = get;
      const option = getQuantityValue(schema.hwmVilla__pi_option);
      const swhType = getQuantityValue(schema.hwmVilla__pi_swhType);
      const swhVariant = getQuantityValue(schema.hwmVilla__pi_swhVariant);
      const hpType = getQuantityValue(schema.hwmVilla__pi_hpType);

      return products?.reduce((memo, product) => {
        if (option === 'solar water heater') {
          if (product?.subcategory?.name === 'Solar Water Heaters') {
            if (swhType === 'central') {
              if (product?.filters[ProductFilterKeys?.system_types]?.includes('Centralized Solar Heater')) {
                memo.push(product);
              }
            }

            if (swhType === 'individual' && product?.filters[ProductFilterKeys?.variant]?.includes(swhVariant?.toUpperCase())) {
              if (product?.filters[ProductFilterKeys?.system_types]?.includes('Individual Solar Heater')) {
                memo.push(product);
              }
            }
          }
        }

        if (option === 'heat pump') {
          if (hpType === 'commercial') {
            if (
              product?.subcategory?.name === 'Heat Pumps' &&
              (product?.filters[ProductFilterKeys?.system_types]?.includes('Swimming Pool Heat Pumps') ||
                product?.filters[ProductFilterKeys?.system_types]?.includes('Commercial Heat pumps'))
            ) {
              memo.push(product);
            }
          }

          if (hpType === 'domestic') {
            if (product?.subcategory?.name === 'Heat Pumps' && product?.filters[ProductFilterKeys?.system_types]?.includes('Domestic Heat Pumps')) {
              memo.push(product);
            }
          }
        }
        return memo;
      }, []);
    },
    productSubcategoryAndFilter: ({ product, schema, get }) => {
      const { getQuantityValue } = get;
      const category = 'Hot Water Management';
      const option = getQuantityValue(schema.hwmVilla__pi_option);
      const swhType = getQuantityValue(schema.hwmVilla__pi_swhType);
      const hpType = getQuantityValue(schema.hwmVilla__pi_hpType);
      let subCategory = '';

      const filterValues = [];

      if (option === 'solar water heater') {
        subCategory = 'Solar Water Heaters';
        if (swhType === 'individual') {
          filterValues.push('Individual Solar Heater');
        }
      }

      if (option === 'heat pump') {
        subCategory = 'Heat Pumps';
        if (hpType === 'domestic') {
          filterValues.push('Domestic Heat Pumps');
        }
      }
      return getFilterDetailsForProduct({ product, category, subCategory }, filterValues);
    },
  },
  twr: {
    schemaKey: 'twr',
    product: {
      selected: 'twr__pi_selectedProductIdentifier',
      default: 'twr_product',
    },
    basic: TwrCustomBasicParameters,
    advanced: TwrCustomAdvanceParameters,
    project: TwrCustomPipParameters,
    filterProducts: ({ products, schema, get }) => {
      return products;
    },
    productSubcategoryAndFilter: ({ product }) => {
      const category = 'Waste Water Treatment';
      const subCategory = 'RO (Capex)';
      return getFilterDetailsForProduct({ product, category, subCategory });
    },
  },
  nstp: {
    schemaKey: 'nstp',
    product: {
      selected: 'nstp__pi_selectedProductIdentifier',
      default: 'nstp_product',
    },
    basic: NaturalStpBasicParameters,
    advanced: NaturalStpAdvancedParameters,
    project: NaturalStpPipParameters,
    filterProducts: ({ products, schema, get }) => {
      return products;
    },
    productSubcategoryAndFilter: ({ product }) => {
      const category = 'Waste Water Treatment';
      const subCategory = 'STP (Anaerobic),STP (Hybrid),STP (Soil-based)';
      return getFilterDetailsForProduct({ product, category, subCategory });
    },
  },
  hwmPenthouse: {
    schemaKey: 'hwmPenthouse',
    product: {
      selected: 'hwmPenthouse__pi_selectedProductIdentifier',
      default: 'hwmPenthouse_product',
    },
    basic: HWMPentHouseCustomBasicParameters,
    advanced: HWMPentHouseCustomAdvancesParameters,
    project: HWMPentHouseProjectInputs,
    filterProducts: ({ products, schema, get }) => {
      const { getQuantityValue } = get;
      const option = getQuantityValue(schema.hwmPenthouse__pi_option);
      const swhType = getQuantityValue(schema.hwmPenthouse__pi_swhType);
      const swhVariant = getQuantityValue(schema.hwmPenthouse__pi_swhVariant);
      const hpType = getQuantityValue(schema.hwmPenthouse__pi_hpType);

      return products?.reduce((memo, product) => {
        if (option === 'solar water heater') {
          if (product?.subcategory?.name === 'Solar Water Heaters') {
            if (swhType === 'central') {
              if (product?.filters[ProductFilterKeys?.system_types]?.includes('Centralized Solar Heater')) {
                memo.push(product);
              }
            }

            if (swhType === 'individual' && product?.filters[ProductFilterKeys.variant]?.includes(swhVariant?.toUpperCase())) {
              if (product?.filters[ProductFilterKeys?.system_types]?.includes('Individual Solar Heater')) {
                memo.push(product);
              }
            }
          }
        }

        if (option === 'heat pump') {
          if (hpType === 'commercial') {
            if (
              product?.subcategory?.name === 'Heat Pumps' &&
              (product?.filters[ProductFilterKeys?.system_types]?.includes('Swimming Pool Heat Pumps') ||
                product?.filters[ProductFilterKeys?.system_types]?.includes('Commercial Heat pumps'))
            ) {
              memo.push(product);
            }
          }

          if (hpType === 'domestic') {
            if (product?.subcategory?.name === 'Heat Pumps' && product?.filters[ProductFilterKeys?.system_types]?.includes('Domestic Heat Pumps')) {
              memo.push(product);
            }
          }
        }
        return memo;
      }, []);
    },
    productSubcategoryAndFilter: ({ product, schema, get }) => {
      const { getQuantityValue } = get;
      const option = getQuantityValue(schema.hwmPenthouse__pi_option);
      const swhType = getQuantityValue(schema.hwmPenthouse__pi_swhType);
      const hpType = getQuantityValue(schema.hwmPenthouse__pi_hpType);
      const filterByValues = [];
      const category = 'Hot Water Management';
      let subCategory = '';

      if (option === 'solar water heater') {
        subCategory = 'Solar Water Heaters';
        if (swhType === 'individual') {
          filterByValues.push('Individual Solar Heater');
        }
      }

      if (option === 'heat pump') {
        subCategory = 'Heat Pumps';
        if (hpType === 'domestic') {
          filterByValues.push('Domestic Heat Pumps');
        }
      }
      return getFilterDetailsForProduct({ product, category, subCategory }, filterByValues);
    },
  },
  hwmSwimmingPool: {
    schemaKey: 'hwmSwimmingPool',
    product: {
      selected: 'hwmSwimmingPool__pi_selectedProductIdentifier',
      default: 'hwmSwimmingPool_product',
    },
    basic: HWMSwimmingPoolCustomBasicParameters,
    advanced: HWMSwimmingPoolCustomAdvancesParameters,
    project: HWMSwimmingPoolProjectInputs,
    filterProducts: ({ products, schema, get }) => {
      const { getQuantityValue } = get;
      const hpType = getQuantityValue(schema.hwmSwimmingPool__pi_hpType);

      return products?.reduce((memo, product) => {
        if (hpType === 'swimming pool') {
          if (product?.subcategory?.name === 'Heat Pumps' && product?.filters[ProductFilterKeys?.system_types]?.includes('Swimming Pool Heat Pumps')) {
            memo.push(product);
          }
        }
        return memo;
      }, []);
    },
    productSubcategoryAndFilter: ({ product, schema, get }) => {
      const { getQuantityValue } = get;
      const category = 'Hot Water Management';
      const subCategory = 'Heat Pumps';
      const hpType = getQuantityValue(schema.hwmSwimmingPool__pi_hpType);
      const filterByValues = [];
      if (hpType === 'swimming pool') {
        filterByValues.push('Swimming Pool Heat Pumps');
      }
      return getFilterDetailsForProduct({ product, category, subCategory }, filterByValues);
    },
  },
  swm: {
    schemaKey: 'swm',
    product: {
      selected: 'swm__pi_selectedProductIdentifier',
      default: 'swm_product',
    },
    basic: SwmCustomBasicParameters,
    advanced: SwmCustomAdvanceParameters,
    project: SwmProjectInputParameters,
    filterProducts: ({ products, schema, get }) => {
      return products;
    },
    productSubcategoryAndFilter: ({ product }) => {
      const category = 'Water Management';
      const subCategory = '';
      return getFilterDetailsForProduct({ product, category, subCategory });
    },
  },
  wwm: {
    schemaKey: 'wwm',
    product: {
      selected: 'wwm__pi_selectedProductIdentifier',
      default: 'wwm_product',
    },
    basic: WWMCustomBasicParameters,
    advanced: WWMCustomAdvancedParameters,
    project: WWMProjectInputs,
    filterProducts: ({ products, schema, get }) => {
      return products;
    },
    productSubcategoryAndFilter: ({ product }) => {
      const category = 'Wet Waste Management';
      const subCategory = '';
      const filterValues = ['Composter'];
      return getFilterDetailsForProduct({ product, category, subCategory }, filterValues);
    },
  },
  csp: {
    schemaKey: 'csp',
    product: {
      selected: 'csp__pi_selectedProductIdentifier',
      default: 'csp_product',
    },
    basic: CSPCustomBasicParameters,
    advanced: CSPCustomAdvancedParameters,
    project: CSPProjectInputs,
    filterProducts: ({ products, schema, get }) => {
      return products;
    },
    productSubcategoryAndFilter: ({ product }) => {
      const category = 'Waste Management Companies';
      const subCategory = '';
      return getFilterDetailsForProduct({ product, category, subCategory });
    },
  },
  exteriorPaintsNonSunny: {
    schemaKey: 'exteriorPaintsNonSunny',
    product: {
      selected: 'exteriorPaintsNonSunny__pi_selectedProductIdentifier',
      default: 'exteriorPaintsNonSunny_product',
    },
    basic: EPNSCustomBasicParameters,
    advanced: EPNSCustomAdvancesParameters,
    project: EPNSProjectInputs,
    filterProducts: ({ products, schema, get }) => {
      return products;
    },
    productSubcategoryAndFilter: ({ product }) => {
      const category = 'Paints';
      const subCategory = 'Exterior Paint';
      const filterValues = ['exteriorPaintsNonSunny'];
      return getFilterDetailsForProduct({ product, category, subCategory }, filterValues);
    },
  },
  exteriorPaintsSunny: {
    schemaKey: 'exteriorPaintsSunny',
    product: {
      selected: 'exteriorPaintsSunny__pi_selectedProductIdentifier',
      default: 'exteriorPaintsSunny_product',
    },
    basic: EPSCustomBasicParameters,
    advanced: EPSCustomAdvancesParameters,
    project: EPSProjectInputs,
    filterProducts: ({ products, schema, get }) => {
      return products;
    },
    productSubcategoryAndFilter: ({ product }) => {
      const category = 'Paints';
      const subCategory = 'Exterior Paint';
      const filterValues = ['exteriorPaintsSunny'];
      return getFilterDetailsForProduct({ product, category, subCategory }, filterValues);
    },
  },
  rrwh: {
    schemaKey: 'rrwh',
    product: {
      selected: 'rrwh__pi_selectedProductIdentifier',
      default: 'rrwh_product',
    },
    basic: RRWHCustomBasicParameters,
    advanced: RRWHCustomAdvancesParameters,
    project: RRWHProjectInputs,
    filterProducts: ({ products, schema, get }) => {
      return products;
    },
    productSubcategoryAndFilter: ({ product }) => {
      const category = 'Rain Water Harvesting';
      const subCategory = 'Rainwater Filters';
      return getFilterDetailsForProduct({ product, category, subCategory });
    },
  },
  srwh: {
    schemaKey: 'srwh',
    product: {
      selected: null,
      default: null,
    },
    basic: SRWHCustomBasicParameters,
    advanced: SRWHCustomAdvancesParameters,
    project: SRWHProjectInputs,
    filterProducts: ({ products, schema, get }) => {
      return products;
    },
    productSubcategoryAndFilter: ({ product }) => {
      return getFilterDetailsForProduct({ product });
    },
  },
  structuralSteel: {
    schemaKey: 'structuralSteel',
    product: {
      selected: 'structuralSteel__pi_selectedProductIdentifier',
      default: 'structuralSteel_product',
    },
    basic: StructuralSteelCustomBasicParameters,
    advanced: StructuralSteelCustomAdvancedParameters,
    project: StructuralSteelProjectInputs,
    filterProducts: ({ products, schema, get }) => {
      return products;
    },
    productSubcategoryAndFilter: ({ product }) => {
      const category = 'Steel';
      return getFilterDetailsForProduct({ product, category });
    },
  },
  sc: {
    schemaKey: 'sc',
    product: {
      selected: 'sc__pi_selectedProductIdentifier',
      default: 'sc_product',
    },
    basic: StructuralConcreteCustomBasicParameters,
    advanced: StructuralConcreteCustomAdvancedParameters,
    project: StructuralConcreteProjectInputs,
    filterProducts: ({ products, schema, get }) => {
      return products;
    },
    productSubcategoryAndFilter: ({ product }) => {
      const category = 'Cement';
      return getFilterDetailsForProduct({ product, category });
    },
  },
  interiorPaints: {
    schemaKey: 'interiorPaints',
    product: {
      selected: 'interiorPaints__pi_selectedProductIdentifier',
      default: 'interiorPaints_product',
    },
    basic: InteriorPaintsCustomBasicParameters,
    advanced: InteriorPaintsCustomAdvancedParameters,
    project: InteriorPaintsProjectInputs,
    filterProducts: ({ products, schema, get }) => {
      return products;
    },
    productSubcategoryAndFilter: ({ product }) => {
      const category = 'Paints';
      const subCategory = 'Interior Paint';
      const filterValues = ['interiorPaints'];
      return getFilterDetailsForProduct({ product, category, subCategory }, filterValues);
    },
  },
  indoorFlooringLnD: {
    schemaKey: 'indoorFlooringLnD',
    product: {
      selected: 'indoorFlooringLnD__pi_selectedProductIdentifier',
      default: 'indoorFlooringLnD_product',
    },
    basic: IndoorFlooringLnDCustomBasicParameters,
    advanced: IndoorFlooringLnDCustomAdvancedParameters,
    project: IndoorFlooringLnDProjectInputs,
    filterProducts: ({ products, schema, get }) => {
      return products;
    },
    productSubcategoryAndFilter: ({ product }) => {
      const category = 'Flooring';
      return getFilterDetailsForProduct({ product, category });
    },
  },
  indoorFlooringBedroom: {
    schemaKey: 'indoorFlooringBedroom',
    product: {
      selected: 'indoorFlooringBedroom__pi_selectedProductIdentifier',
      default: 'indoorFlooringBedroom_product',
    },
    basic: IndoorFlooringBedroomCustomBasicParameters,
    advanced: IndoorFlooringBedroomCustomAdvancedParameters,
    project: IndoorFlooringBedroomProjectInputs,
    filterProducts: ({ products, schema, get }) => {
      return products;
    },
    productSubcategoryAndFilter: ({ product }) => {
      const category = 'Flooring';
      return getFilterDetailsForProduct({ product, category });
    },
  },
  indoorFlooringKitchen: {
    schemaKey: 'indoorFlooringKitchen',
    product: {
      selected: 'indoorFlooringKitchen__pi_selectedProductIdentifier',
      default: 'indoorFlooringKitchen_product',
    },
    basic: IndoorFlooringKitchenCustomBasicParameters,
    advanced: IndoorFlooringKitchenCustomAdvancedParameters,
    project: IndoorFlooringKitchenProjectInputs,
    filterProducts: ({ products, schema, get }) => {
      return products;
    },
    productSubcategoryAndFilter: ({ product }) => {
      const category = 'Flooring';
      return getFilterDetailsForProduct({ product, category });
    },
  },
  indoorFlooringBathroom: {
    schemaKey: 'indoorFlooringBathroom',
    product: {
      selected: 'indoorFlooringBathroom__pi_selectedProductIdentifier',
      default: 'indoorFlooringBathroom_product',
    },
    basic: IndoorFlooringBathroomCustomBasicParameters,
    advanced: IndoorFlooringBathroomCustomAdvancedParameters,
    project: IndoorFlooringBathroomProjectInputs,
    filterProducts: ({ products, schema, get }) => {
      return products;
    },
    productSubcategoryAndFilter: ({ product }) => {
      const category = 'Flooring';
      return getFilterDetailsForProduct({ product, category });
    },
  },
  indoorFlooringUnB: {
    schemaKey: 'indoorFlooringUnB',
    product: {
      selected: 'indoorFlooringUnB__pi_selectedProductIdentifier',
      default: 'indoorFlooringUnB_product',
    },
    basic: IndoorFlooringUnBCustomBasicParameters,
    advanced: IndoorFlooringUnBCustomAdvancedParameters,
    project: IndoorFlooringUnBProjectInputs,
    filterProducts: ({ products, schema, get }) => {
      return products;
    },
    productSubcategoryAndFilter: ({ product }) => {
      const category = 'Flooring';
      return getFilterDetailsForProduct({ product, category });
    },
  },
  default: {
    schemaKey: 'default',
    product: {
      selected: '',
      default: '',
    },
    basic: BasicInterventionDetails,
    advanced: AdvancedInterventionDetails,
    project: null,
    filterProducts: () => {},
    productSubcategoryAndFilter: () => {},
  },
};

export default KongInterventionsComponentsMapper;
