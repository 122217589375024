import React from 'react';
import { Typography, Grid } from '@material-ui/core';
import useStyles from './styles';
import { useInterventionDetailsContext } from '../context/InterventionDetails';

const IndoorFlooringKitchenCustomBasicParameters = (props) => {
  const classes = useStyles();
  const {
    schema,
    get: { getQuantityValue, getQuantityLabel, getQuantityUnit },
  } = useInterventionDetailsContext();

  return (
    <Grid container direction="column" alignItems="left" item xs={12}>
      <Grid xs={12} container alignItems="flex-start" direction="row" style={{ marginTop: '5%', display: 'flex', alignItems: 'center' }}>
        <Grid xs={3}>
          <Typography className={classes.textsubHead}>{getQuantityLabel(schema.indoorFlooringKitchen_brand)}</Typography>
          <Typography className={classes.textDescription}>{getQuantityValue(schema.indoorFlooringKitchen_brand)}</Typography>
        </Grid>
        <Grid xs={3}>
          <Typography className={classes.textsubHead}>{getQuantityLabel(schema.indoorFlooringKitchen_productName)}</Typography>
          <Typography className={classes.textDescription}>{getQuantityValue(schema.indoorFlooringKitchen_productName)}</Typography>
        </Grid>
        <Grid xs={3}>
          <Typography className={classes.textsubHead}>{getQuantityLabel(schema.indoorFlooringKitchen_productType)}</Typography>
          <Typography className={classes.textDescription}>{getQuantityValue(schema.indoorFlooringKitchen_productType)}</Typography>
        </Grid>
        <Grid xs={3}>
          <Typography className={classes.textsubHead}>{getQuantityLabel(schema.indoorFlooringKitchen_productMaterial)}</Typography>
          <Typography className={classes.textDescription}>{getQuantityValue(schema.indoorFlooringKitchen_productMaterial)}</Typography>
        </Grid>
      </Grid>
      <Grid xs={12} container alignItems="flex-start" direction="row" style={{ marginTop: '5%', display: 'flex', alignItems: 'center' }}>
        <Grid xs={3}>
          <Typography className={classes.textsubHead}>{getQuantityLabel(schema.indoorFlooringKitchen_productFinish)}</Typography>
          <Typography className={classes.textDescription}>{getQuantityValue(schema.indoorFlooringKitchen_productFinish)}</Typography>
        </Grid>
        <Grid xs={3}>
          <Typography className={classes.textsubHead}>{getQuantityLabel(schema.indoorFlooringKitchen_totalCarpetArea)}</Typography>
          <Typography className={classes.textDescription}>
            {getQuantityValue(schema.indoorFlooringKitchen_totalCarpetArea)} {getQuantityUnit(schema.indoorFlooringKitchen_totalCarpetArea)}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default IndoorFlooringKitchenCustomBasicParameters;
