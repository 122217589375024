import { iconList } from '../../../../../assets/reports';

export const interventionKeysGrouped = {
  hwms: ['hwmApartment', 'hwmVilla', 'hwmPenthouse', 'hwmClubhouse', 'hwmSwimmingPool'],
  spvs: ['spvApartment', 'spvVilla', 'spvPenthouse', 'spvClubhouse'],
  efs: ['efficientFixturesFaucet', 'efficientFixturesHealthFaucet', 'efficientFixturesKitchenSink', 'efficientFixturesShower'],
  eps: ['exteriorPaintsSunny', 'exteriorPaintsNonSunny'],
  flooring: ['indoorFlooringBedroom', 'indoorFlooringKitchen', 'indoorFlooringBathroom', 'indoorFlooringLnD', 'indoorFlooringUnB'],
  materials: ['sc'],
  waste: ['wwm', 'csp'],
};
export const intervNameFeatureKeyMap = {
  hwmApartment: 'HWM Apartment',
  hwmVilla: 'HWM Villa',
  hwmPenthouse: 'HWM Penthouse',
  hwmClubhouse: 'HWM Clubhouse',
  hwmSwimmingPool: 'HWM Swimming Pool',
  spvApartment: 'Solar PV Apartment',
  spvVilla: 'Solar PV Villa',
  spvPenthouse: 'Solar PV Penthouse',
  spvClubhouse: 'Solar PV Clubhouse',
  efficientFixturesDualFlush: 'Efficient Fixtures - Dual Flush',
  efficientFixturesFaucet: 'Efficient Fixtures - Faucet',
  efficientFixturesHealthFaucet: 'Efficient Fixtures - Health Faucet',
  efficientFixturesKitchenSink: 'Efficient Fixtures - Kitchen Sink',
  efficientFixturesShower: 'Efficient Fixtures - Shower',
  nstp: 'Natural STP',
  swm: 'Smart Water Meters',
  twr: 'Treated Water Reuse',
  exteriorPaintsSunny: 'Exterior Paints - Sunny',
  exteriorPaintsNonSunny: 'Exterior Paints - Non-Sunny',
  sc: 'Structural Concrete',
  structuralSteel: 'Structural Steel',
  csp: 'Collection & Segregation Point',
  wwm: 'Wet Waste Management',
  interiorPaints: 'Interior Paints',
  indoorFlooringBedroom: 'Bedroom Flooring',
  indoorFlooringKitchen: 'Kitchen Flooring',
  indoorFlooringBathroom: 'Bathroom Flooring',
  indoorFlooringLnD: 'Living and Dining Flooring',
  indoorFlooringUnB: 'Utility and Balcony Flooring',
};
export const intervContentFeatureKeyMap = {
  hwmApartment:
    'Hot water management apartment focuses on efficient and sustainable hot water production using solar and heat pump technology, reducing energy use.',
  hwmVilla: 'Hot water management villa focuses on efficient and sustainable hot water production using solar and heat pump technology, reducing energy use.',
  hwmPenthouse:
    'Hot water management penthouse focuses on efficient and sustainable hot water production using solar and heat pump technology, reducing energy use.',
  hwmClubhouse:
    'Hot water management clubhouse focuses on efficient and sustainable hot water production using solar and heat pump technology, reducing energy use.',
  hwmSwimmingPool:
    'Hot water management swimming pool focuses on efficient and sustainable hot water production using solar and heat pump technology, reducing energy use.',
  spvApartment: 'SPV system for the apartment converts sunlight into electricity which can be either fed back to the grid or used on site.',
  spvVilla: 'SPV system for the villa converts sunlight into electricity which can be either fed back to the grid or used on site.',
  spvPenthouse: 'SPV system for the penthouse converts sunlight into electricity which can be either fed back to the grid or used on site. ',
  spvClubhouse: 'SPV system for the clubhouse converts sunlight into electricity which can be either fed back to the grid or used on site.',
  efficientFixturesDualFlush:
    'Dual Flush Efficient Fixtures offer two flush options, conserving water and reducing utility bills. They support sustainable water management by using only the necessary amount of water per flush',
  efficientFixturesFaucet:
    'In efficient kitchen faucets, an add-on aerator is added, that adds air into the water flow for water efficiency gains without a drop in pressure.',
  efficientFixturesHealthFaucet:
    'In efficient kitchen health faucets, an add-on aerator is added, that adds air into the water flow for water efficiency gains without a drop in pressure.',
  efficientFixturesKitchenSink:
    'In efficient kitchen sinks, an add-on aerator is added, that adds air into the water dlow for water efficiency gains without a drop in pressure',
  efficientFixturesShower:
    'In efficient kitchen showers, an add-on aerator is added, that adds air into the water flow for water efficiency gains without a drop in pressure',
  nstp: 'Natural Sewage Treatment Plants (STPs) use biological methods like wetlands and soil filtration, reducing energy and chemical use. STPs promote clean water and conservation',
  swm: 'Smart Water Meters track water usage in real-time, promoting efficient water management. They help detect leaks, reduce waste, and provide accurate billing',
  twr: 'Treated Water Reuse involves recycling both grey water and black water for non-potable uses like irrigation and industrial processes, as well as for potable uses',
  exteriorPaintsSunny: 'Use paints with less to no VOC content and less embodied carbon',
  exteriorPaintsNonSunny: 'Use paints with less to no VOC content and less embodied carbon',
  sc: 'Compared to OPC, PSC and PCC use slag and other manufacturing process by-products reducing carbon footprint.',
  structuralSteel: 'Opting for DRI steel is more sustainable as it consumes less energy in the manufacturing process',
  csp: 'Make space provisions within the premises for collection and segregation of dry, medical, and e-waste',
  wwm: 'Wet waste is diverted from landfill and turned into Biogas with a bio-digester, which can subsequently be used to generate electricity',
  interiorPaints: 'Use paints with less to no VOC content and less embodied carbon',
  indoorFlooringBedroom: 'Use sustainable alternatives with lower embodied carbon than the project baseline',
  indoorFlooringKitchen: 'Use sustainable alternatives with lower embodied carbon than the project baseline',
  indoorFlooringBathroom: 'Use sustainable alternatives with lower embodied carbon than the project baseline',
  indoorFlooringLnD: 'Use sustainable alternatives with lower embodied carbon than the project baseline',
  indoorFlooringUnB: 'Use sustainable alternatives with lower embodied carbon than the project baseline',
};
export function convertToLakhs(number) {
  if (typeof number !== 'number') {
    return '-';
  }
  return (number / 100000)?.toFixed(2) || '-';
}
export function getCapacityUnitDynamically(featureKey) {
  if (interventionKeysGrouped.hwms.includes(featureKey)) return 'LPD';
  if (interventionKeysGrouped.spvs.includes(featureKey)) return 'kWp';
  if (interventionKeysGrouped.efs.includes(featureKey)) return 'LPM';
  if (interventionKeysGrouped.flooring.includes(featureKey)) return 'sqm';
  if (interventionKeysGrouped.eps.includes(featureKey) || featureKey === 'interiorPaints') return 'L';
  if (featureKey === 'efficientFixturesDualFlush') return 'LPF';
  if (featureKey === 'swm') return '';
  if (featureKey === 'twr' || featureKey === 'nstp') return 'KLD';
  if (interventionKeysGrouped.waste.includes(featureKey) || interventionKeysGrouped.materials.includes(featureKey)) return 'Kg';
  if (featureKey === 'structuralSteel') return 'tons';

  return '';
}

export function areaReqdDisplayed(featureKey, option = '') {
  if (interventionKeysGrouped.hwms.includes(featureKey)) return true;
  if (interventionKeysGrouped.spvs.includes(featureKey)) return true;
  if (interventionKeysGrouped.waste.includes(featureKey)) return true;
  return false;
}

export const vocLevelDisplayed = (featureKey) => {
  if (interventionKeysGrouped.eps.includes(featureKey) || featureKey === 'interiorPaints') return true;
};

export function getCapacityOrFlowText(featureKey) {
  if (interventionKeysGrouped.hwms.includes(featureKey) || interventionKeysGrouped.spvs.includes(featureKey)) return 'Capacity:';
  if (
    interventionKeysGrouped.eps.includes(featureKey) ||
    interventionKeysGrouped.materials.includes(featureKey) ||
    interventionKeysGrouped.flooring.includes(featureKey) ||
    featureKey === 'structuralSteel' ||
    featureKey === 'interiorPaints'
  )
    return 'Quantity:';
  if (interventionKeysGrouped.efs.includes(featureKey)) return 'Fixture Flow Rate:';
  if (featureKey === 'efficientFixturesDualFlush') return 'Flush Rate:';
  if (featureKey === 'swm') return 'No. Of Sensors:';
  return 'Capacity:';
}
export const getResourceTextDynamically = (category) => {
  if (category === 'Energy') return 'Annual Energy Savings';
  if (category === 'Water') return 'Annual Water Savings';
  if (category === 'Waste') return 'Annual Waste Savings';
  if (category === 'Materials') return 'Embodied Carbon Reduced';
  return 'Annual Resource Savings';
};

export const barChartColorMap = {
  exteriorPaintsSunny: '#A6A0A0',
  exteriorPaintsNonSunny: '#C0C0C0',
  sc: '#99FFFF',
  indoorFlooringBathroom: '#CC99FF',
  indoorFlooringKitchen: '#FF99FF',
  indoorFlooringUnB: '#F7F79B',
  structuralSteel: '#66CCFF',
  interiorPaints: '#9AE39A',
  indoorFlooringLnD: '#FFCC99',
  indoorFlooringBedroom: '#FF99CC',
  otherMaterials: '#99FFD9',
};
export function getCurrentDate() {
  const now = new Date();
  const day = String(now.getDate()).padStart(2, '0');
  const month = String(now.getMonth() + 1).padStart(2, '0');
  const year = now.getFullYear();
  return `${day}/${month}/${year}`;
}
export function getCategoryWiseEnabledAndActivatedInterventionsData(data, categoryKey) {
  const category = data?.filter((val) => val?.id === categoryKey)[0]?.value;
  return Object.keys((category && category) || {})
    ?.map((featureKey) => {
      if (category[featureKey]?.enabled && category[featureKey]?.activated) {
        return {
          interventionKey: featureKey,
          ...category[featureKey],
        };
      }
    })
    ?.filter((interv) => interv);
}
export const getDataById = (id, flowDiagramData) => {
  const item = flowDiagramData?.find((item) => item.id === id);
  return item ? Math.round(item.value) : '-';
};
export const getAnnualBaselineConsumption = (data, category) => {
  return data?.categorized?.filter((item) => item.name === category)[0]?.quantities?.resource?.annualBaselineConsumption;
};
export const getIcon = (featureKey) => {
  if (interventionKeysGrouped.hwms.includes(featureKey)) return iconList()['solarWaterHeater'];
  if (interventionKeysGrouped.spvs.includes(featureKey)) return iconList()['solarPV'];
  if (interventionKeysGrouped.efs.includes(featureKey)) return iconList()['efficientFixtures'];
  if (interventionKeysGrouped.eps.includes(featureKey)) return iconList()['paints'];
  if (interventionKeysGrouped.flooring.includes(featureKey)) return iconList()['flooringIcon'];

  if (featureKey === 'efficientFixturesDualFlush') return iconList()['dualFlush'];
  if (featureKey === 'swm') return iconList()['smartWaterMeter'];
  if (featureKey === 'twr') return iconList()['twr'];
  if (featureKey === 'nstp') return iconList()['nstp'];
  if (featureKey === 'sc') return iconList()['cement'];
  if (featureKey === 'csp') return iconList()['csp'];
  if (featureKey === 'wwm') return iconList()['wwm'];
  if (featureKey === 'structuralSteel') return iconList()['steel'];
  if (featureKey === 'interiorPaints') return iconList()['paints'];

  return iconList()['solarPV'];
};

export function percentChange(post, pre) {
  const percentage = Math.abs((post - pre) * 100) / pre;
  return isNaN(percentage) ? `${0} %` : `${Math.round(percentage !== Infinity ? percentage : 0)}`;
}

export const capitalizeFirstLetterOfEachWord = (input) => {
  return input
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

export const displayEmbodiedCarbon = (featureKey) => {
  if (
    interventionKeysGrouped.eps.includes(featureKey) ||
    interventionKeysGrouped.materials.includes(featureKey) ||
    featureKey === 'structuralSteel' ||
    featureKey === 'interiorPaints'
  )
    return true;
  else return false;
};
