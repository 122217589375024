import ReactGA from 'react-ga4';

const useAppAnalytics = () => {
  const sendEvent = (fieldObject) => {
    return ReactGA.send({
      hitType: 'event',
      ...fieldObject,
    });
  };
  return {
    sendEvent,
  };
};

export { useAppAnalytics };
