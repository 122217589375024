import React from 'react';
import { Text, View, Image } from '@react-pdf/renderer';
import surfaceRunoff from '../../../../../assets/new-pdf/dummy.png';

const IntervDataCard = (props) => {
  return (
    <View>
      {/* common card */}
      <View
        style={{
          border: props.border,
          // height: `${props.intervData && props.intervData.data.length * 41.2}px`,
          marginTop: '10px',
          borderRadius: '10px',
        }}
        break
      >
        <View style={{ flexDirection: 'row', display: 'flex' }}>
          <View style={{ width: '15px' }} />
          <View style={{ paddingTop: '13px', width: '8%' }}>
            <Image style={{ height: '25px', width: '25px' }} src={props.icon} />
          </View>
          <View style={{ paddingTop: '16px', width: '38%' }}>
            <Text style={{ fontFamily: 'Lato', fontSize: '9px', color: '#2E2E2E', fontWeight: 700 }}>{props.intervData.intervention}</Text>
          </View>
          <View style={{ paddingTop: '16px', width: '20%' }}>
            <Text style={{ fontFamily: 'Lato', fontSize: '9px', color: '#2E2E2E', fontWeight: 700 }}>Before</Text>
          </View>
          <View style={{ paddingTop: '16px', width: '20%' }}>
            <Text style={{ fontFamily: 'Lato', fontSize: '9px', color: '#2E2E2E', fontWeight: 700 }}>After</Text>
          </View>
          <View style={{ paddingTop: '16px', width: '20%' }}>
            <Text style={{ fontFamily: 'Lato', fontSize: '9px', color: '#2E2E2E', fontWeight: 700 }}>Change</Text>
          </View>
        </View>
        {props.intervData.data.map((insideData) => {
          return (
            <View style={{ flexDirection: 'row', display: 'flex' }}>
              <View style={{ width: '15px' }} />
              <View style={{ paddingBottom: '15px', width: '8%' }}></View>
              <View style={{ paddingBottom: '13px', width: '38%' }}>
                <Text style={{ fontFamily: 'Lato', fontSize: '9px', color: '#666666', fontWeight: 400 }}>{insideData.name}</Text>
              </View>
              <View style={{ paddingBottom: '15px', width: '20%' }}>
                <Text style={{ fontFamily: 'Lato', fontSize: '9px', color: '#2E2E2E', fontWeight: 400 }}>{`${insideData.before} ${
                  insideData.unit !== '-' && insideData.before !== '-' ? insideData.unit : ''
                }`}</Text>
              </View>
              <View style={{ paddingBottom: '15px', width: '20%' }}>
                {typeof insideData.after !== 'object' ? (
                  <Text style={{ fontFamily: 'Lato', fontSize: '9px', color: props.color, fontWeight: 400 }}>{`${insideData.after} ${
                    insideData.unit !== '-' && insideData.after !== '-' ? insideData.unit : ''
                  }`}</Text>
                ) : (
                  <View style={{ display: 'flex', flexDirection: 'column' }}>
                    <Text style={{ fontFamily: 'Lato', fontSize: '9px', color: props.color, fontWeight: 400 }}>
                      {Math.round(insideData?.after?.hp)} LPD (HP)
                    </Text>
                    <Text style={{ fontFamily: 'Lato', fontSize: '9px', color: props.color, fontWeight: 400 }}>
                      {Math.round(insideData?.after?.swh)} LPD (SWH)
                    </Text>
                  </View>
                )}
              </View>
              <View style={{ paddingBottom: '15px', width: '20%' }}>
                {typeof insideData.after !== 'object' ? (
                  <Text style={{ fontFamily: 'Lato', fontSize: '9px', color: props.color, fontWeight: 400 }}>
                    {insideData.Improvement}
                    {insideData.Improvement !== '-' && insideData.Improvement !== '' ? '%' : ''}
                  </Text>
                ) : (
                  <View style={{ display: 'flex', flexDirection: 'column' }}>
                    {' '}
                    <Text style={{ fontFamily: 'Lato', fontSize: '9px', color: props.color, fontWeight: 400 }}>
                      {Math.round(insideData?.Improvement?.hp)}
                      {insideData?.Improvement?.hp !== '-' && insideData?.Improvement?.hp !== '' ? '%' : ''}
                    </Text>
                    <Text style={{ fontFamily: 'Lato', fontSize: '9px', color: props.color, fontWeight: 400 }}>
                      {Math.round(insideData?.Improvement?.swh)}
                      {insideData?.Improvement?.swh !== '-' && insideData?.Improvement?.hp !== '' ? '%' : ''}
                    </Text>
                  </View>
                )}
              </View>
            </View>
          );
        })}
      </View>
    </View>
  );
};

export default IntervDataCard;
