import React, { useState } from 'react';
import { Box, Grid, Typography, makeStyles } from '@material-ui/core';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import CommonHeadingWithIcon from '../../common/header/commonHeadings';
import PrimaryButton from '../../common/button/Button';
import SDLogoNew from '../../../assets/header/SDLogoNew.svg';
import ProjectSustainabilityIcon from '../../../assets/ProjectSustainabilityIcon.svg';
import ExecutiveReportIcon from '../../../assets/ExecutiveReportIcon.svg';
import SdImpactReportIcon from '../../../assets/SdImpactReportIcon.svg';
import EnergyUseReportIcon from '../../../assets/EnergyUseReportIcon.svg';
import WaterUseReportIcon from '../../../assets/WaterUseReportIcon.svg';
import MaterialSelectionReportIcon from '../../../assets/MaterialSelectionReportIcon.svg';
import WasteManagementReportIcon from '../../../assets/WasteManagementReportIcon.svg';

const useStyles = makeStyles((theme) => ({
  main: { paddingLeft: '4%', paddingRight: '4%', paddingTop: '9%' },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  subHeading: {
    fontFamily: 'Lato',
    fontSize: '24px',
    fontWeight: 700,
    lineHeight: '28.8px',
    letterSpacing: '0.8571428656578064px',
  },
  reportHeading: {
    fontFamily: 'Lato',
    fontSize: '13.44px',
    fontWeight: 700,
    lineHeight: '16.13px',
    letterSpacing: '0.4801493287086487px',
    padding: '0px',
  },
  card: {
    width: '200px',
    height: '284px',
    border: '0.5px solid #E5E5E5',
    padding: '12px',
    boxShadow: '2px 2px 8px rgba(24, 50, 119, 0.1)',
    backgroundColor: '#FFFFFF',
    display: 'flex',
    justifyContent: 'space-between',
  },
  hoverCard: {
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
    width: '186px',
    height: '250px',
    margin: '6px 3px',
    padding: '10px',
  },
  hoveredHeading: {
    fontFamily: 'Lato',
    fontSize: '12px',
    fontWeight: 700,
    lineHeight: '14.4px',
    letterSpacing: '0.24007466435432434px',
    color: '#13C6BD',
  },
  explanationNote: {
    fontFamily: 'Lato',
    fontSize: '12px',
    lineHeight: '14.4px',
    height: '40px',
    letterSpacing: '0.24007466435432434px',
    marginTop: '20px',
  },
}));

const Reports = () => {
  const classes = useStyles();
  const history = useHistory();
  const params = useParams();
  const [cardHover, setCardHover] = useState(false);
  const [hoveredCard, setHoveredCard] = useState(false);

  const projectImpactReports = [
    {
      title: 'Project Sustainability',
      icon: ProjectSustainabilityIcon,
      backgroundColor: '#49DDB133',
      description: 'Complete report',
      explanationNote: 'Comprehensive report giving an overall summary of environmental, health and financial impact',
      url: 'project-sustainability-report',
    },
    {
      title: 'Executive',
      icon: ExecutiveReportIcon,
      backgroundColor: '#49DDB133',
      description: 'High level impact report',
      explanationNote: 'Environmental and financial  Impact summary against project sustainability goals',
      url: 'executive-report',
    },
    {
      title: 'SD+ Impact',
      icon: SdImpactReportIcon,
      backgroundColor: '#DDEDF6',
      description: 'Pre - Post assessment report',
      explanationNote: 'A detailed report of all interventions comparing pre and post SD+ outcomes',
      url: 'sdplus-impact-report',
    },
  ];

  const categoryReports = [
    {
      title: 'Energy Use',
      icon: EnergyUseReportIcon,
      textColor: '#F28D14',
      backgroundColor: '#FFF4D5',
      description: 'Energy interventions report',
      explanationNote: 'A detailed environmental impacts report focusing on sustainable energy use',
      url: 'energy-report',
    },
    {
      title: 'Water Use',
      icon: WaterUseReportIcon,
      textColor: '#3BC7EE',
      backgroundColor: '#C9F5FF',
      description: 'Water interventions report',
      explanationNote: 'A detailed environmental impacts report focusing on water use reduction',
      url: 'water-report',
    },
    {
      title: 'Waste Management',
      icon: WasteManagementReportIcon,
      textColor: '#BC8A5F',
      backgroundColor: '#EEE1D7',
      description: 'Waste interventions report',
      explanationNote: 'A detailed environmental impacts report focusing on sustainable waste management',
      url: 'waste-report',
    },
    {
      title: 'Material Selection',
      icon: MaterialSelectionReportIcon,
      textColor: '#916AAC',
      backgroundColor: '#EDCDFF',
      description: 'Material interventions report',
      explanationNote: 'A detailed environmental impacts report focusing on reducing carbon footprint with material selection',
      url: 'materials-report',
    },
  ];
  const handleDownload = (url) => {
    window.open(`/project/${params?.id}/reports/${url}`, '_blank');
  };

  return (
    <Grid>
      <Grid className={classes.main} container justifyContent="left" alignItems="center" xs={12}>
        <Grid xs={12} style={{ paddingBottom: '3%', marginLeft: '-3%' }}>
          <CommonHeadingWithIcon heading="Reports" variant="h3" handleClick={() => history.goback()} />
          <Box style={{ display: 'flex', flexDirection: 'column' }}>
            <Typography className={classes.subHeading} style={{ paddingTop: '40px', paddingLeft: '20px' }}>
              {!cardHover ? 'Project Impact' : 'Overall Reports and Summaries'}
            </Typography>
            <Box style={{ display: 'flex', paddingLeft: '20px', paddingTop: '40px', columnGap: '120px' }}>
              {projectImpactReports.map((projectImapct) => {
                return (
                  <Box
                    onMouseEnter={(e) => {
                      setCardHover(true);
                      setHoveredCard(projectImapct.title);
                    }}
                    onMouseLeave={(e) => {
                      setCardHover(false);
                      setHoveredCard('');
                    }}
                    className={classes.card}
                  >
                    {hoveredCard === projectImapct.title ? (
                      <Box className={classes.hoverCard}>
                        <Typography className={classes.hoveredHeading}>{projectImapct.description}</Typography>
                        <Typography className={classes.explanationNote}>{projectImapct.explanationNote}</Typography>
                        <PrimaryButton
                          children="Download"
                          style={{ width: '90%', marginTop: '100px', marginLeft: '5px' }}
                          onClick={() => {
                            handleDownload(projectImapct.url);
                          }}
                        />
                      </Box>
                    ) : (
                      <Box>
                        <img src={SDLogoNew} alt="..." height="36vh" width="auto" />
                        <Typography style={{ color: '#13C6BD' }} className={classes.reportHeading}>
                          {projectImapct.title}
                        </Typography>
                        <Typography className={classes.reportHeading}>Report</Typography>
                        <img
                          style={{ padding: '10px', marginTop: '20px', backgroundColor: projectImapct.backgroundColor }}
                          src={projectImapct.icon}
                          alt="..."
                        />
                      </Box>
                    )}
                  </Box>
                );
              })}
            </Box>
          </Box>
          <Box>
            <Typography className={classes.subHeading} style={{ paddingTop: '40px', paddingLeft: '20px' }}>
              {!cardHover ? 'Categories' : 'By Resource Category'}
            </Typography>
            <Box style={{ display: 'flex', paddingLeft: '20px', paddingTop: '40px', columnGap: '120px' }}>
              {categoryReports.map((category) => {
                return (
                  <Box
                    className={classes.card}
                    onMouseEnter={(e) => {
                      setCardHover(true);
                      setHoveredCard(category.title);
                    }}
                    onMouseLeave={(e) => {
                      setCardHover(false);
                      setHoveredCard('');
                    }}
                  >
                    {hoveredCard === category.title ? (
                      <Box className={classes.hoverCard}>
                        <Typography className={classes.hoveredHeading}>{category.description}</Typography>
                        <Typography className={classes.explanationNote}>{category.explanationNote}</Typography>
                        <PrimaryButton
                          children="Download"
                          style={{ width: '90%', marginTop: '100px', marginLeft: '5px' }}
                          onClick={() => {
                            handleDownload(category.url);
                          }}
                        />
                      </Box>
                    ) : (
                      <Box>
                        <img src={SDLogoNew} alt="..." height="36vh" width="auto" />
                        <Typography style={{ color: category.textColor }} className={classes.reportHeading}>
                          {category.title}
                        </Typography>
                        <Typography className={classes.reportHeading}>Impact Report</Typography>
                        <img style={{ padding: '10px', marginTop: '20px', backgroundColor: category.backgroundColor }} src={category.icon} alt="..." />
                      </Box>
                    )}
                  </Box>
                );
              })}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Reports;
