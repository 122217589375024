import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { StyledButton } from '../common/StyledButton';

export default function IGBCCategoriesTogglers({ igbcCategoryContext, categories, handleIGBCCategory }) {
  return (
    <>
      <Grid container alignItems="center" style={{ backgroundColor: 'rgba(217, 217, 217, 0.2)', padding: '1.5% 2% 1.5% 2%' }}>
        {categories &&
          categories.map((data, index) => {
            return (
              <Grid item xs key={index}>
                <StyledButton value={data} selected={igbcCategoryContext === categories[index]} onChange={() => handleIGBCCategory(data)}>
                  <Typography variant="h6" style={{ fontSize: '13px', lineHeight: '18px' }}>
                    {data}
                  </Typography>
                </StyledButton>
              </Grid>
            );
          })}
      </Grid>
    </>
  );
}
