import React, { useEffect, useState, useContext } from 'react';
import { Document, Page, StyleSheet, PDFViewer, Font, View, Text, Image } from '@react-pdf/renderer';
import LatoRegular from '../../../../fonts/Lato-Regular.ttf';
import LatoBold from '../../../../fonts/Lato-Bold.ttf';
import LatoItalic from '../../../../fonts/Lato-Italic.ttf';
import { useParams } from 'react-router';
import { UserContext } from '../../../../../routing/IndexRouting';
import WasteRounded from '../../../../../assets/new-pdf/WasteRounded.png';

import HeadingPage from './HeadingPage';

import WasteReportIcon from '../../../../../assets/new-pdf/WasteReportIcon.png';

import WaterIcon from '../../../../../assets/new-pdf/WaterIcon.png';

import { useAppApiRequest } from '../../../../../hooks/useAppApiRequest';
import { MetafireFeatures } from '../../../../../metafire/utils/types';
import CategoryWiseSummaryPage from './CategoryWiseSummaryPage';
import CategoryReportBarGraphPage from './CategoryReportBarGraphPage';
import ImplementInterventionsDetailsPage from './ImplementedInterventionsDetailsPage';
import Footer from './Footer';
import Header from './Header';
import numberWithCommas from '../../../../../utils/numberToComma';
import WasteFlowDiagramSvg from './WasteFlowDiagramSvg';
import { getCategoryWiseEnabledAndActivatedInterventionsData, getCurrentDate, getDataById } from './ReportUtils';

// Create styles
const styles = StyleSheet.create({
  footerMargin: {
    top: 30,
    marginTop: '25%',
  },
  viewPage2: {
    margin: '10px 20px 20px 20px',
    padding: 10,
    flexGrow: 1,
  },
  page: {
    flexDirection: 'column',
    backgroundColor: '#FFFFFF',
  },
  viewer: {
    width: window.innerWidth - 8,
    height: window.innerHeight - 8,
  },
});

Font.register({
  family: 'Lato',
  fonts: [
    {
      src: LatoRegular,
    },
    {
      src: LatoBold,
    },
  ],
});
Font.register({
  family: 'Lato-Italic',
  fonts: [
    {
      src: LatoItalic,
    },
  ],
});

const WasteReport = () => {
  const { userDetails } = useContext(UserContext);
  const params = useParams();
  const [asset, setAsset] = useState('');
  const [intervention, setIntervention] = useState(null);
  const [reportData, setReportData] = useState([]);
  const [projectData, setProjectData] = React.useState('');
  const [flowDiagramData, setFlowDiagramData] = useState([]);

  const { data: insightsResponse } = useAppApiRequest(`/metafire?projectId=${params?.id}&feature=${MetafireFeatures.INSIGHTS}&numberOfYears=1`, {
    method: 'GET',
  });
  const { data: projectDataResponse } = useAppApiRequest(`/projectv2/${params?.id}/basics?NumberOfYears=1`, { method: 'GET' });
  const { data: reportResponse } = useAppApiRequest(`/metafire?projectId=${params?.id}&feature=${MetafireFeatures.REPORT}`, { method: 'GET' });
  const { data: flowDiagram } = useAppApiRequest(`/metafire?projectId=${params?.id}&feature=${MetafireFeatures.FLOWDIAGRAMS}`, { method: 'GET' });

  useEffect(() => {
    if (flowDiagram) {
      setFlowDiagramData(flowDiagram?.data?.data?.data);
    }
  }, [flowDiagram]);
  useEffect(() => {
    if (insightsResponse) {
      setIntervention(insightsResponse?.data?.data?.data?.interventions);
    }
  }, [insightsResponse]);
  useEffect(() => {
    if (reportResponse) {
      setReportData(reportResponse?.data?.data?.data);
    }
  }, [reportResponse]);
  useEffect(() => {
    if (projectDataResponse) {
      setProjectData(projectDataResponse?.data?.data?.data);
    }
  }, [projectDataResponse]);

  const { data: assetReponse } = useAppApiRequest(`/sign-s3/${userDetails?.organisation?.payloadId}`, { method: 'GET' });
  useEffect(() => {
    if (assetReponse?.data?.data) {
      setAsset(assetReponse?.data?.data?.data);
    }
  }, [assetReponse]);

  return (
    <PDFViewer style={styles.viewer}>
      <Document title={`Waste Management Report ${getCurrentDate()}`}>
        <Page size="A4" style={styles.page}>
          <HeadingPage
            category={'Waste'}
            pageIcon={WasteReportIcon}
            asset={asset}
            reportName={'Waste Management'}
            projectname={projectData && projectData.name}
            date={getCurrentDate()}
            reportHeadingColor="#BC8A5F"
          />
        </Page>
        <Page>
          <CategoryReportBarGraphPage
            heading="Waste"
            category="Waste"
            icon={WasteRounded}
            companyname={projectData && projectData.name}
            capex={intervention?.categorized?.filter((item) => item.name === 'Waste')[0]?.quantities?.finance?.capex?.value}
            opex={intervention?.categorized?.filter((item) => item.name === 'Waste')[0]?.quantities?.finance?.opexSavings?.value}
            totalResourceSavings={intervention?.categorized?.filter((item) => item.name === 'Waste')[0]?.quantities?.resource?.category?.value}
            unit="Kg"
            baselineConsumption={intervention?.categorized?.filter((item) => item.name === 'Waste')[0]?.quantities?.resource?.annualBaselineConsumption?.value}
            year={getCurrentDate().split('/')[2]}
            title1={'Base Case Waste Generated'}
            title2={'Sustainable Case Waste Generated'}
            percentageResourceSaving={intervention?.categorized?.filter((item) => item.name === 'Waste')[0]?.quantities?.resource?.categorySavingPercentage}
          />
        </Page>
        <Page size="A4" style={styles.page}>
          <CategoryWiseSummaryPage
            companyname={projectData && projectData.name}
            totalResourceSavings={{
              value: intervention?.categorized?.filter((item) => item.name === 'Waste')[0]?.quantities?.resource?.category?.value,
              unit: 'kL',
            }}
            interventionData={intervention}
            resourceSavingPercentage={
              intervention?.categorized?.filter((item) => item.name === 'Waste')[0]?.quantities?.resource?.categorySavingPercentage?.value
            }
            totalLPCDPreSdplus={intervention?.categorized?.filter((item) => item.name === 'Waste')[0]?.quantities?.resource?.totalLPCDPreSdplus?.value}
            totalLPCDPostSdplus={intervention?.categorized?.filter((item) => item.name === 'Waste')[0]?.quantities?.resource?.totalLPCDPostSdplus?.value}
            category="Waste"
            border="1px solid #9E9E9E"
            color="#BC8A5F"
            categorisedReportData={getCategoryWiseEnabledAndActivatedInterventionsData(reportData && reportData, 'reportImpactSummary_waste')}
            headingIcon={WasteRounded}
            dummyicon={WaterIcon}
            year={getCurrentDate().split('/')[2]}
          />
        </Page>
        <Page size="A4" style={styles.page}>
          <ImplementInterventionsDetailsPage
            companyname={projectData && projectData.name}
            ProjectinterventionMappingData={{}}
            allVendorMappings={{}}
            allvendors={{}}
            EnabledGrouped={{}}
            border="1px solid #9E9E9E"
            color="#BC8A5F"
            year={getCurrentDate().split('/')[2]}
            category="Waste"
            categorisedReportData={getCategoryWiseEnabledAndActivatedInterventionsData(reportData && reportData, 'reportImpactSummary_waste')}
            numnber={2}
            resourceSavingUnit={'Kg'}
          />
        </Page>
        <Page size="A4" style={styles.page}>
          <View style={styles.viewPage2}>
            <Header companyname={projectData && projectData.name} />
            <Text style={{ fontFamily: 'Lato', fontWeight: 700, fontSize: '14px', textTransform: 'uppercase', marginTop: '20px' }}>{`WASTE FLOW DIAGRAM`}</Text>
            <View>
              <WasteFlowDiagramSvg
                rejectWaste={`${numberWithCommas(getDataById('wasteFlowDiagram_rejectWasteToLandfill', flowDiagramData))}`}
                dryWaste={`${numberWithCommas(getDataById('wasteFlowDiagram_dryWasteToRecycler', flowDiagramData))}`}
                eWaste={`${numberWithCommas(getDataById('wasteFlowDiagram_eWasteToRecycler', flowDiagramData))}`}
                medicalWaste={`${numberWithCommas(getDataById('wasteFlowDiagram_medicalWasteToOffSiteIncinerator', flowDiagramData))}`}
                landfill={`${numberWithCommas(getDataById('wasteFlowDiagram_totalLandfill', flowDiagramData))}`}
                offSiteIncinerator={`${numberWithCommas(getDataById('wasteFlowDiagram_totalOffSiteIncinerator', flowDiagramData))}`}
                recycler={`${numberWithCommas(getDataById('wasteFlowDiagram_totalRecycler', flowDiagramData))}`}
                organicWaste={`${numberWithCommas(getDataById('wasteFlowDiagram_totalOrganicWaste', flowDiagramData))}`}
                biogas={`${numberWithCommas(getDataById('wasteFlowDiagram_organicWasteToBiogas', flowDiagramData))}`}
                naturalComposting={`${numberWithCommas(getDataById('wasteFlowDiagram_organicWasteToNaturalComposting', flowDiagramData))}`}
              />
            </View>
          </View>
          <View style={styles.footerMargin}>
            <Footer year={getCurrentDate().split('/')[2]} />
          </View>
        </Page>
      </Document>
    </PDFViewer>
  );
};
export default WasteReport;
