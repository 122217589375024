import React, { useEffect, useState, useContext, useMemo } from 'react';
import { Checkbox, Grid, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';
import swal from 'sweetalert';
import axios from '../../../../config/Axios';
import PrimaryButton from '../../../common/button/Button';
import HeadAndBackIcon from '../../../common/header/HeadAndBackIcon';
import SimplePIPS from '../common/components/SimplePIPS';
import { SomethingChanged } from '../EditProject';
import CustomDwellingPIP from '../common/components/CustomDwellingPIP';
import useAppProjectPIPMapping from '../hooks/useAppProjectPIPMapping';
import useAppPIPScheme from '../hooks/useAppPIPScheme';
import useAppProjectDetails from '../hooks/useAppProjectDetails';
import ApiError from '../../../common/Error/ApiError';

const EditProjectPageDwellingPIP = (props) => {
  let history = useHistory();
  let { id } = useParams();
  let { type, next, name } = props;
  const { somethingChanged, handlSetSomethingChanged } = useContext(SomethingChanged);

  const { data: projectData } = useAppProjectDetails(id);
  const {
    get: { form, projectPIP, isError, isFetching },
    set: { handlePIPValueUpdate },
  } = useAppProjectPIPMapping(id);

  const { data } = useAppPIPScheme(type);
  const basicPIPs = ['project__pi_numberOfFloors', 'project__pi_wwr', 'project__pi_perimeter', 'project__pi_floorToFloorHeight'];
  const penthhousePIP = ['project__pi_penthouseEnabled'];
  const simplePIPs = data && data?.filter((field) => basicPIPs.includes(field.name));
  const pentHousePIP = data && data?.find((field) => penthhousePIP.includes(field.name));

  // const pentHouseEnabled = projectPIP?.find((d) => d?.PIPScheme?.name === 'project__pi_penthouseEnabled')?.value || false;
  const pentHouseEnabled = form?.pipvalues[pentHousePIP?._id];

  //useEffects
  // useEffect(() => {
  //   if (projectPIP.length !== 0 && myArray.length !== 0) {
  //     myArray.forEach((ele) => {
  //       let valueFromProjectPIP = projectPIP.find((e) => e.PIPScheme._id === ele.PIPScheme) && projectPIP.find((e) => e.PIPScheme._id === ele.PIPScheme).value;
  //       if (ele.value !== valueFromProjectPIP) handlSetSomethingChanged(true);
  //     });
  //   }
  // }, [projectPIP, myArray]);

  useEffect(() => {
    (async () => {
      if (props.saveAfterSomethingChanged === true && next) {
        await handleSave(next && next);
        props.toogleShowModal(false);
        props.setFalseAfterSomethingChanged();
        props.SETNEXT('');
      }
    })();
  }, [props.saveAfterSomethingChanged, next]);

  const {
    studio,
    single,
    double,
    three,
    four,
    studioCarpet,
    singleCarpet,
    doublecarpet,
    threeecarpet,
    fourcarpet,
    villa_studio,
    villa_single,
    villa_double,
    villa_three,
    villa_four,
    villa_studioCarpet,
    villa_singleCarpet,
    villa_doublecarpet,
    villa_threeecarpet,
    villa_fourcarpet,
    penthouseAvailableRooftop,
    penthouse_single,
    penthouse_double,
    penthouse_three,
    penthouse_four,
    // penthouse_studio,
    penthouse_four_carpet,
    penthouse_three_carpet,
    penthouse_double_carpet,
    penthouse_single_carpet,
  } = useMemo(() => {
    if (data) {
      const studio = data.find((d) => d.name === 'project__pi_studio');
      const single = data.find((d) => d.name === 'project__pi_oneBHK');
      const double = data.find((d) => d.name === 'project__pi_twoBHK');
      const three = data.find((d) => d.name === 'project__pi_threeBHK');
      const four = data.find((d) => d.name === 'project__pi_fourBHK');
      const studioCarpet = data.find((d) => d.name === 'project__pi_studioCarpetArea');
      const singleCarpet = data.find((d) => d.name === 'project__pi_oneBhkCarpetArea');
      const doublecarpet = data.find((d) => d.name === 'project__pi_twoBhkCarpetArea');
      const threeecarpet = data.find((d) => d.name === 'project__pi_threeBhkCarpetArea');
      const fourcarpet = data.find((d) => d.name === 'project__pi_fourBhkCarpetArea');
      const villa_studio = data.find((d) => d.name === 'project__pi_villaStudio');
      const villa_single = data.find((d) => d.name === 'project__pi_villaOneBHK');
      const villa_double = data.find((d) => d.name === 'project__pi_villaTwoBHK');
      const villa_three = data.find((d) => d.name === 'project__pi_villaThreeBHK');
      const villa_four = data.find((d) => d.name === 'project__pi_villaFourBHK');
      const villa_studioCarpet = data.find((d) => d.name === 'project__pi_villaStudioCarpetArea');
      const villa_singleCarpet = data.find((d) => d.name === 'project__pi_villaOneBHKCarpetArea');
      const villa_doublecarpet = data.find((d) => d.name === 'project__pi_villaTwoBHKCarpetArea');
      const villa_threeecarpet = data.find((d) => d.name === 'project__pi_villaThreeBHKCarpetArea');
      const villa_fourcarpet = data.find((d) => d.name === 'project__pi_villaFourBHKCarpetArea');
      const penthouseAvailableRooftop = data.find((d) => d.name === 'project__pi_availablePenthouseRooftopArea');
      const penthouse_single = data.find((d) => d.name === 'project__pi_penthouseOneBHK');
      const penthouse_double = data.find((d) => d.name === 'project__pi_penthouseTwoBHK');
      const penthouse_three = data.find((d) => d.name === 'project__pi_penthouseThreeBHK');
      const penthouse_four = data.find((d) => d.name === 'project__pi_penthouseFourBHK');
      const penthouse_studio = data.find((d) => d.name === 'project__pi_penthouseStudio');
      const penthouse_single_carpet = data.find((d) => d.name === 'project__pi_penthouseOneBhkCarpetArea');
      const penthouse_double_carpet = data.find((d) => d.name === 'project__pi_penthouseTwoBhkCarpetArea');
      const penthouse_three_carpet = data.find((d) => d.name === 'project__pi_penthouseThreeBhkCarpetArea');
      const penthouse_four_carpet = data.find((d) => d.name === 'project__pi_penthouseFourBhkCarpetArea');
      return {
        studio,
        single,
        double,
        three,
        four,
        studioCarpet,
        singleCarpet,
        doublecarpet,
        threeecarpet,
        fourcarpet,
        villa_studio,
        villa_single,
        villa_double,
        villa_three,
        villa_four,
        villa_studioCarpet,
        villa_singleCarpet,
        villa_doublecarpet,
        villa_threeecarpet,
        villa_fourcarpet,
        penthouseAvailableRooftop,
        penthouse_single,
        penthouse_double,
        penthouse_three,
        penthouse_four,
        penthouse_studio,
        penthouse_single_carpet,
        penthouse_double_carpet,
        penthouse_three_carpet,
        penthouse_four_carpet,
      };
    }
    return {};
  }, [data]);

  const compare = (a, b) => {
    if (a.createdAt < b.createdAt) return -1;
    if (a.createdAt > b.createdAt) return 1;
    else return 0;
  };

  const handleNext = (next) => {
    if (next && next === 'goals') history.push(`/projects/${id}/goals`);
    else {
      if (somethingChanged) {
        props.toogleShowModal(true);
        props.SETNEXT(next);
      } else {
        if (next && next === 'insights') history.push(`/projects/${id}/insights`);
        if (next && next === 'projects') history.push(`/projects`);
        else history.push(`/projects/${id}/edit?type=${next}`);
      }
    }
  };

  const handleGoBack = (type) => {
    if (type === 'basic') history.push(`/projects/${id}/insights`);
    else history.push(`/projects/${id}/edit?type=basic`);
  };

  const getPIPValues = (pipvalues) => {
    const body = Object.assign({}, pipvalues);
    if (!pentHouseEnabled) {
      const resetFields = [
        'project__pi_availablePenthouseRooftopArea',
        'project__pi_penthouseOneBHK',
        'project__pi_penthouseTwoBHK',
        'project__pi_penthouseThreeBHK',
        'project__pi_penthouseFourBHK',
        'project__pi_penthouseStudio',
      ];
      resetFields.map((key) => {
        const field = data.find((d) => d.name === key);
        if (field) {
          body[field._id] = 0;
        }
      });
    }
    return Object.entries(body).map(([key, value]) => ({
      PIPScheme: key,
      value,
    }));
  };

  const handleSave = async (next) => {
    axios
      .put(`/ProjectPIPMapping`, {
        projectId: id,
        values: getPIPValues(form?.pipvalues),
      })
      .then((response) => {
        props.toogleShowModal(false);
        props.setFalseAfterSomethingChanged();
        props.SETNEXT('');
        swal({ icon: 'success', title: 'Data Saved' });
        if (next && next === 'insights') history.push(`/projects/${id}/insights`);
        if (next && next === 'projects') history.push(`/projects`);
        else history.push(`/projects/${id}/edit?type=${next}`);
      });
  };
  if (isError)
    return (
      <div style={{ display: 'flex', alignItems: 'center', paddingTop: 24, width: '100%' }}>
        <ApiError />
      </div>
    );
  return (
    <>
      <Grid item container justifyContent="left" direction="column" xs={12} style={{ marginTop: '7%', paddingLeft: '2%' }}>
        <HeadAndBackIcon
          heading={`${name && name} ${projectData?.name ? projectData?.name : ''}`}
          handleClick={() => handleGoBack(type && type)}
          visitGoals={false}
          headingVariant="h5"
          headingStyle={{ fontSize: '20px', fontWeight: 'bold' }}
          showArrow={false}
        />
      </Grid>

      {/* Dwelling - will be cleaned up later*/}
      {type && type === 'dwelling' && simplePIPs && projectPIP && projectPIP?.length !== 0 && (
        <>
          <Grid container xs={12} style={{ marginLeft: '2%', marginBottom: '3%', marginTop: '3%' }}>
            {/* studio */}
            <Grid container item xs={12}>
              <Grid item xs={6}>
                <Typography variant="h5">Studio</Typography>
                <CustomDwellingPIP
                  index={0}
                  unit={form?.unitPIPValue}
                  field={projectData?.subcategory != 'villa' ? studio && studio : villa_studio && villa_studio}
                  projectPIP={projectPIP && projectPIP}
                  appendChanged={(fieldId, value) => handlePIPValueUpdate(fieldId, value)}
                  type={type}
                  PIPs={simplePIPs && simplePIPs}
                />
              </Grid>
              <Grid item xs={6}>
                <Typography variant="h5">Carpet Area</Typography>
                <CustomDwellingPIP
                  index={1}
                  unit={form?.unitPIPValue}
                  field={projectData?.subcategory != 'villa' ? studioCarpet && studioCarpet : villa_studioCarpet && villa_studioCarpet}
                  projectPIP={projectPIP && projectPIP}
                  appendChanged={(fieldId, value) => handlePIPValueUpdate(fieldId, value)}
                  type={type}
                  PIPs={simplePIPs && simplePIPs}
                />
              </Grid>
            </Grid>
            {/* onebhk */}
            <Grid container item xs={12}>
              <Grid xs={12}>
                <Typography variant="h5" style={{ marginTop: '2%' }}>
                  1 BHK
                </Typography>
              </Grid>
              <Grid container xs={12} alignItems="center" justifyContent="flex-start">
                <Grid item xs={6}>
                  <Grid alignItems="center" item xs={12} style={{ marginBottom: '1%' }}>
                    <Typography variant="h6" style={{ letterSpacing: '0.571429px', color: 'rgba(0, 0, 0, 0.38)' }}>
                      {single?.description}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={6} container alignItems="center" justifyContent="center">
                  <Grid container alignItems="center" item xs={12}>
                    <Typography variant="h5">Carpet Area</Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container xs={12} alignItems="center" justifyContent="flex-start">
                <Grid item xs={6}>
                  <CustomDwellingPIP
                    index={0}
                    unit={form?.unitPIPValue}
                    field={projectData?.subcategory != 'villa' ? single && single : villa_single && villa_single}
                    projectPIP={projectPIP && projectPIP}
                    appendChanged={(fieldId, value) => handlePIPValueUpdate(fieldId, value)}
                    type={type}
                    PIPs={simplePIPs && simplePIPs}
                  />
                </Grid>
                <Grid item xs={6} container alignItems="center" justifyContent="center">
                  <Grid container alignItems="center" item xs={12}>
                    <CustomDwellingPIP
                      index={0}
                      unit={form?.unitPIPValue}
                      field={projectData?.subcategory != 'villa' ? singleCarpet && singleCarpet : villa_singleCarpet && villa_singleCarpet}
                      projectPIP={projectPIP && projectPIP}
                      appendChanged={(fieldId, value) => handlePIPValueUpdate(fieldId, value)}
                      type={type}
                      PIPs={simplePIPs && simplePIPs}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {/* two bhk */}
            <Grid container item xs={12}>
              <Grid xs={12}>
                <Typography variant="h5" style={{ marginTop: '2%' }}>
                  2 BHK
                </Typography>
              </Grid>
              <Grid container xs={12} alignItems="center" justifyContent="flex-start">
                <Grid item xs={6}>
                  <Grid alignItems="center" item xs={12} style={{ marginBottom: '1%' }}>
                    <Typography variant="h6" style={{ letterSpacing: '0.571429px', color: 'rgba(0, 0, 0, 0.38)' }}>
                      {double?.description}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={6} container alignItems="center" justifyContent="center">
                  <Grid container alignItems="center" item xs={12}>
                    <Typography variant="h5">Carpet Area</Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container xs={12} alignItems="center" justifyContent="flex-start">
                <Grid item xs={6}>
                  <CustomDwellingPIP
                    index={0}
                    unit={form?.unitPIPValue}
                    field={projectData?.subcategory != 'villa' ? double && double : villa_double && villa_double}
                    projectPIP={projectPIP && projectPIP}
                    appendChanged={(fieldId, value) => handlePIPValueUpdate(fieldId, value)}
                    type={type}
                    PIPs={simplePIPs && simplePIPs}
                  />
                </Grid>
                <Grid item xs={6} container alignItems="center" justifyContent="center">
                  <Grid container alignItems="center" item xs={12}>
                    <CustomDwellingPIP
                      index={0}
                      unit={form?.unitPIPValue}
                      field={projectData?.subcategory != 'villa' ? doublecarpet && doublecarpet : villa_doublecarpet && villa_doublecarpet}
                      projectPIP={projectPIP && projectPIP}
                      appendChanged={(fieldId, value) => handlePIPValueUpdate(fieldId, value)}
                      type={type}
                      PIPs={simplePIPs && simplePIPs}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {/* three bhk */}
            <Grid container item xs={12} style={{ marginTop: '3%' }}>
              <Grid item xs={6}>
                <Typography variant="h5">3 BHK</Typography>
                <CustomDwellingPIP
                  index={0}
                  unit={form?.unitPIPValue}
                  field={projectData?.subcategory != 'villa' ? three && three : villa_three && villa_three}
                  projectPIP={projectPIP && projectPIP}
                  appendChanged={(fieldId, value) => handlePIPValueUpdate(fieldId, value)}
                  type={type}
                  PIPs={simplePIPs && simplePIPs}
                />
              </Grid>
              <Grid item xs={6}>
                <Typography variant="h5">Carpet Area</Typography>
                <CustomDwellingPIP
                  index={0}
                  unit={form?.unitPIPValue}
                  field={projectData?.subcategory != 'villa' ? threeecarpet && threeecarpet : villa_threeecarpet && villa_threeecarpet}
                  projectPIP={projectPIP && projectPIP}
                  appendChanged={(fieldId, value) => handlePIPValueUpdate(fieldId, value)}
                  type={type}
                  PIPs={simplePIPs && simplePIPs}
                />
              </Grid>
            </Grid>
            {/* fourbhk */}
            <Grid container item xs={12} style={{ marginTop: '3%' }}>
              <Grid item xs={6}>
                <Typography variant="h5">4 BHK</Typography>
                <CustomDwellingPIP
                  index={0}
                  unit={form?.unitPIPValue}
                  field={projectData?.subcategory != 'villa' ? four && four : villa_four && villa_four}
                  projectPIP={projectPIP && projectPIP}
                  appendChanged={(fieldId, value) => handlePIPValueUpdate(fieldId, value)}
                  type={type}
                  PIPs={simplePIPs && simplePIPs}
                />
              </Grid>
              <Grid item xs={6}>
                <Typography variant="h5">Carpet Area</Typography>
                <CustomDwellingPIP
                  index={0}
                  unit={form?.unitPIPValue}
                  field={projectData?.subcategory != 'villa' ? fourcarpet && fourcarpet : villa_fourcarpet && villa_fourcarpet}
                  projectPIP={projectPIP && projectPIP}
                  appendChanged={(fieldId, value) => handlePIPValueUpdate(fieldId, value)}
                  type={type}
                  PIPs={simplePIPs && simplePIPs}
                />
              </Grid>
            </Grid>
          </Grid>
        </>
      )}

      {/*Working*/}
      {type && type === 'dwelling' && simplePIPs && projectPIP && projectPIP.length !== 0
        ? simplePIPs.map((field, index) => {
            return (
              <SimplePIPS
                index={index}
                unit={form?.unitPIPValue}
                field={field && field}
                projectPIP={projectPIP && projectPIP}
                appendChanged={(fieldId, value) => handlePIPValueUpdate(fieldId, value)}
                type={type}
              />
            );
          })
        : null}

      {/* Penthouse details  enable and disable*/}
      {projectData && projectData?.subcategory === 'apartment' && (
        <Grid item container justifyContent="left" direction="row" xs={12} style={{ paddingLeft: '2%' }}>
          <HeadAndBackIcon
            heading={`Penthouse Details`}
            visitGoals={false}
            headingVariant="h5"
            headingStyle={{ fontSize: '20px', fontWeight: 'bold' }}
            showArrow={false}
          />
          <Grid xs={12}>
            <Typography variant="h6" style={{ lineHeight: '22px', letterSpacing: '0.571429px', color: 'rgba(0, 0, 0, 0.38)' }}>
              {pentHousePIP?.description}
            </Typography>
          </Grid>
          <Grid xs={12} container justifyContent="flex-start" alignItems="center">
            <Grid xs={1} container justifyContent="flex-start" alignItems="flex-start">
              <Checkbox
                checked={pentHouseEnabled || false}
                style={{ color: '#66E3BE' }}
                onChange={(e) => handlePIPValueUpdate(pentHousePIP?._id, e.target.checked)}
              />
            </Grid>
            <Grid xs={10} container justifyContent="flex-start" alignItems="center" style={{ marginLeft: '-5%' }}>
              <Typography variant="h6">{pentHousePIP?.displayName}</Typography>
            </Grid>
          </Grid>
        </Grid>
      )}

      {/* for penthouse pip -  will be cleaned up later*/}
      {pentHouseEnabled && pentHouseEnabled === true && type && type === 'dwelling' && projectData && projectData?.subcategory === 'apartment' ? (
        <>
          <Grid container xs={12} style={{ height: 'Auto', marginBottom: '3%', marginLeft: '2%' }}>
            <Typography variant="h5">Available Penthouse Rooftop Area</Typography>
            <Grid container item xs={12}>
              <Grid item xs={6}>
                <CustomDwellingPIP
                  index={0}
                  unit={form?.unitPIPValue}
                  field={penthouseAvailableRooftop && penthouseAvailableRooftop}
                  projectPIP={projectPIP && projectPIP}
                  appendChanged={(fieldId, value) => handlePIPValueUpdate(fieldId, value)}
                  type={type}
                  PIPs={simplePIPs && simplePIPs}
                />
              </Grid>
            </Grid>
          </Grid>
          {/* <Grid container xs={12} style={{ height: 'Auto', marginBottom: '3%', marginLeft: '2%' }}>
            <Typography variant="h5">Studio</Typography>
            <Grid container item xs={12}>
              <Grid item xs={6}>
                <CustomDwellingPIP
                  index={0}
                  unit={form?.unitPIPValue}
                  field={penthouse_studio && penthouse_studio}
                  projectPIP={projectPIP && projectPIP}
                  appendChanged={(fieldId, value) => handlePIPValueUpdate(fieldId, value)}
                  type={type}
                  PIPs={simplePIPs && simplePIPs}
                />
              </Grid>
              <Grid item xs={6}>
                <CustomDwellingPIP
                  index={0}
                  unit={form?.unitPIPValue}
                  field={penthouse_single_carpet && penthouse_single_carpet}
                  projectPIP={projectPIP && projectPIP}
                  appendChanged={(fieldId, value) => handlePIPValueUpdate(fieldId, value)}
                  type={type}
                  PIPs={simplePIPs && simplePIPs}
                />
              </Grid>
            </Grid>
          </Grid> */}
          <Grid container xs={12} style={{ height: 'Auto', marginBottom: '3%', marginLeft: '2%' }}>
            <Typography variant="h5">1 BHK</Typography>
            <Grid container item xs={12}>
              <Grid item xs={6}>
                <CustomDwellingPIP
                  index={0}
                  unit={form?.unitPIPValue}
                  field={penthouse_single && penthouse_single}
                  projectPIP={projectPIP && projectPIP}
                  appendChanged={(fieldId, value) => handlePIPValueUpdate(fieldId, value)}
                  type={type}
                  PIPs={simplePIPs && simplePIPs}
                />
              </Grid>
              <Grid item xs={6}>
                <Typography variant="h5">Carpet Area</Typography>
                <CustomDwellingPIP
                  index={0}
                  unit={form?.unitPIPValue}
                  field={penthouse_single_carpet && penthouse_single_carpet}
                  projectPIP={projectPIP && projectPIP}
                  appendChanged={(fieldId, value) => handlePIPValueUpdate(fieldId, value)}
                  type={type}
                  PIPs={simplePIPs && simplePIPs}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container xs={12} style={{ height: 'Auto', marginBottom: '3%', marginLeft: '2%' }}>
            <Typography variant="h5">2 BHK</Typography>
            <Grid container item xs={12}>
              <Grid item xs={6}>
                <CustomDwellingPIP
                  index={0}
                  unit={form?.unitPIPValue}
                  field={penthouse_double && penthouse_double}
                  projectPIP={projectPIP && projectPIP}
                  appendChanged={(fieldId, value) => handlePIPValueUpdate(fieldId, value)}
                  type={type}
                  PIPs={simplePIPs && simplePIPs}
                />
              </Grid>
              <Grid item xs={6}>
                <Typography variant="h5">Carpet Area</Typography>
                <CustomDwellingPIP
                  index={0}
                  unit={form?.unitPIPValue}
                  field={penthouse_double_carpet && penthouse_double_carpet}
                  projectPIP={projectPIP && projectPIP}
                  appendChanged={(fieldId, value) => handlePIPValueUpdate(fieldId, value)}
                  type={type}
                  PIPs={simplePIPs && simplePIPs}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container xs={12} style={{ height: 'Auto', marginBottom: '3%', marginLeft: '2%' }}>
            <Typography variant="h5">3 BHK</Typography>
            <Grid container item xs={12}>
              <Grid item xs={6}>
                <CustomDwellingPIP
                  index={0}
                  unit={form?.unitPIPValue}
                  field={penthouse_three && penthouse_three}
                  projectPIP={projectPIP && projectPIP}
                  appendChanged={(fieldId, value) => handlePIPValueUpdate(fieldId, value)}
                  type={type}
                  PIPs={simplePIPs && simplePIPs}
                />
              </Grid>
              <Grid item xs={6}>
                <Typography variant="h5">Carpet Area</Typography>
                <CustomDwellingPIP
                  index={0}
                  unit={form?.unitPIPValue}
                  field={penthouse_three_carpet && penthouse_three_carpet}
                  projectPIP={projectPIP && projectPIP}
                  appendChanged={(fieldId, value) => handlePIPValueUpdate(fieldId, value)}
                  type={type}
                  PIPs={simplePIPs && simplePIPs}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container xs={12} style={{ height: 'Auto', marginBottom: '3%', marginLeft: '2%' }}>
            <Typography variant="h5">4 BHK</Typography>
            <Grid container item xs={12}>
              <Grid item xs={6}>
                <CustomDwellingPIP
                  index={0}
                  unit={form?.unitPIPValue}
                  field={penthouse_four && penthouse_four}
                  projectPIP={projectPIP && projectPIP}
                  appendChanged={(fieldId, value) => handlePIPValueUpdate(fieldId, value)}
                  type={type}
                  PIPs={simplePIPs && simplePIPs}
                />
              </Grid>
              <Grid item xs={6}>
                <Typography variant="h5">Carpet Area</Typography>
                <CustomDwellingPIP
                  index={0}
                  unit={form?.unitPIPValue}
                  field={penthouse_four_carpet && penthouse_four_carpet}
                  projectPIP={projectPIP && projectPIP}
                  appendChanged={(fieldId, value) => handlePIPValueUpdate(fieldId, value)}
                  type={type}
                  PIPs={simplePIPs && simplePIPs}
                />
              </Grid>
            </Grid>
          </Grid>
        </>
      ) : (
        ''
      )}

      <Grid container direction="row" alignItems="end" justifyContent="flex-end" item xs={12} style={{ marginBottom: '7%' }}>
        <Grid item xs={2}>
          <PrimaryButton onClick={() => handleSave(next && next)} children="Save" />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={2}>
          <PrimaryButton onClick={() => handleNext(next && next)} children="next" />
        </Grid>
      </Grid>
    </>
  );
};
export default EditProjectPageDwellingPIP;
