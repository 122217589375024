import React, { useRef, useState, useContext, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import useApiRequest from '../../hooks/useApiRequest';
import { FETCHING, SUCCESS, ERROR } from '../../hooks/useApiRequest/actionTypes';
import cookie from 'react-cookies';
import axios from '../../../config/Axios';
import { UserContext } from '../../../routing/IndexRouting';
import Loader from '../../common/progressbar/Loader';
import OutlinedTextField from '../../common/textfield/OutlinedTextField';
import CustomButton from '../../common/button/CustomButton';
import CustomIconButton from '../../common/button/CustomIconButton';
import CustomOutlinedInput from '../../common/textfield/CustomOutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { Redirect } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useAppAnalytics } from '../../../hooks/useAppAnalytics';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(0),
    marginTop: theme.spacing(1),
  },
  response: {
    padding: '3% 3%',
    color: theme.palette.warning.main,
    backgroundColor: '#FFF5E2',
    border: '1px solid rgba(255, 167, 86, 0.5)',
    boxSizing: 'border-box',
    borderRadius: '10px',
  },
}));

export default function Login() {
  const { sendEvent } = useAppAnalytics();
  const pswdRef = useRef(null);
  const classes = useStyles();
  const { handleLoginToken, userDetails, setUserDetails } = useContext(UserContext);
  const [selected, setSelected] = useState(false);
  const [pswd, setPswd] = useState('');
  const [email, setEmail] = useState('');
  const [alert, setalert] = useState(false);
  const [fieldError, setFieldError] = useState(false);

  const handleEmail = (event) => {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(event.target.value)) {
      setalert(false);
    } else {
      setalert(true);
    }
    setFieldError(false);
    // alert('You have entered an invalid email address!');
    // return false;
    setEmail(event.target.value);
  };
  const handlePassword = (event) => {
    setFieldError(false);
    setPswd(event.target.value);
  };
  const [{ status, response }, makeRequest] = useApiRequest('/authv2/login', {
    verb: 'post',
    params: { email: email, password: pswd },
  });

  const handleLogin = async () => {
    await makeRequest();
    sendEvent({
      eventAction: 'user_logged_in',
    });
  };

  useEffect(() => {
    if (status === SUCCESS && response) {
      const expires = new Date();
      expires.setDate(Date.now() + 1000 * 60 * 60 * 24 * 30);
      cookie.save('SDPLUS_TOKEN', response.data.token, { path: '/', expires, maxAge: 2592000 });
      axios.defaults.headers.common = { Authorization: `Bearer ${response.data.token}` };
      setUserDetails({
        ...userDetails,
        _id: response.data._id,
        role: response.data.role,
        name: response.data.name,
        email: response.data.email,
        organisation: response.data.organisation,
      });
      setFieldError(false);
      handleLoginToken(response.data.token);
    } else if (status === ERROR && response && !response.toString().includes('Network Error') && response && !response.toString().includes('404')) {
      setFieldError(true);
    }
  }, [status, handleLoginToken, response, userDetails, setUserDetails]);
  return (
    <>
      <form className={classes.form} noValidate>
        <OutlinedTextField
          variant="outlined"
          margin="normal"
          required
          value={email}
          onChange={handleEmail}
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
          autoFocus
        />
        {alert ? (
          <>
            <Typography style={{ color: '#FFD48D', fontSize: '12px' }}>You have entered an invalid email address!</Typography>
          </>
        ) : (
          ''
        )}
        <FormControl margin="normal" fullWidth variant="outlined" required>
          <InputLabel ref={pswdRef} htmlFor="outlined-adornment-password">
            Password
          </InputLabel>
          {fieldError ? (
            <CustomOutlinedInput
              id="outlined-adornment-password"
              value={pswd}
              error
              onChange={handlePassword}
              name="password"
              type={selected ? 'text' : 'password'}
              autoComplete="current-password"
              endAdornment={
                <InputAdornment position="end">
                  <CustomIconButton
                    onClick={() => {
                      setSelected(!selected);
                      pswdRef.current.focus();
                    }}
                    edge="end"
                  >
                    <Typography variant="h6" color={selected ? 'textSecondary' : 'primary'}>
                      Show
                    </Typography>
                  </CustomIconButton>
                </InputAdornment>
              }
              labelWidth={70}
            />
          ) : (
            <CustomOutlinedInput
              id="outlined-adornment-password"
              value={pswd}
              onChange={handlePassword}
              name="password"
              type={selected ? 'text' : 'password'}
              autoComplete="current-password"
              endAdornment={
                <InputAdornment position="end">
                  <CustomIconButton
                    onClick={() => {
                      setSelected(!selected);
                      pswdRef.current.focus();
                    }}
                    edge="end"
                  >
                    <Typography variant="h6" color={selected ? 'textSecondary' : 'primary'}>
                      Show
                    </Typography>
                  </CustomIconButton>
                </InputAdornment>
              }
              labelWidth={70}
            />
          )}
        </FormControl>

        {status === SUCCESS && <Redirect to="/projects" />}
        {status === ERROR && (
          <Grid container item xs={12}>
            {/* <Grid item xs={12}> */}
            {/* <Paper className={classes.response}> */}
            <Typography style={{ color: 'red' }} gutterBottom>
              {response && response.toString().includes('Network Error') ? (
                `Sorry, can't do that right now`
              ) : response && response.toString().includes('404') ? (
                `Sorry, can't do that right now`
              ) : (
                <>
                  Oops! Invalid login credentials{' '}
                  {/* <CustomLink
                        to="/forgotpassword"
                        label="Get help with logging in"
                        activeOnlyWhenExact={true}
                        style={{
                          textDecoration: 'underline',
                          color: 'var(--theme-palette-warning-main--color)',
                        }}
                      /> */}
                </>
              )}
            </Typography>
            {/* </Paper> */}
          </Grid>
          // </Grid>
        )}
        {/* <FormControlLabel control={<Checkbox value="remember" color="textPrimary" />} label="Remember me" /> */}
        <Grid container item xs={12}>
          <Typography variant="body1" gutterBottom className={classes.typo}>
            <Link to="/forgotpassword" style={{ color: '#2EE1AC', paddingBottom: '10px', textDecoration: 'none' }}>
              Forgot Password?
            </Link>
          </Typography>
        </Grid>

        {status !== FETCHING && (
          <CustomButton type="submit" onClick={handleLogin} fullWidth variant="contained" className={classes.submit}>
            Login
          </CustomButton>
        )}
        {status === FETCHING && (
          <Grid container direction="column" alignItems="center" item xs={12}>
            <Grid item xs={12}>
              <Loader />
            </Grid>
          </Grid>
        )}
      </form>
    </>
  );
}
