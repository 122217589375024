import React, { useEffect, useState, useContext } from 'react';
import { Document, Page, StyleSheet, PDFViewer, Font, View, Text } from '@react-pdf/renderer';
import LatoRegular from '../../../../fonts/Lato-Regular.ttf';
import LatoBold from '../../../../fonts/Lato-Bold.ttf';
import LatoItalic from '../../../../fonts/Lato-Italic.ttf';
import { useParams } from 'react-router';
import { UserContext } from '../../../../../routing/IndexRouting';
import EnergyRounded from '../../../../../assets/new-pdf/EnergyRounded.png';
import energyIcon from '../../../../../assets/new-pdf/dummyenrgylogo.png';

import HeadingPage from './HeadingPage';

import EnergyUseReport from '../../../../../assets/EnergyUseReport.png';

import { useAppApiRequest } from '../../../../../hooks/useAppApiRequest';
import { MetafireFeatures } from '../../../../../metafire/utils/types';
import CategoryWiseSummaryPage from './CategoryWiseSummaryPage';
import CategoryReportBarGraphPage from './CategoryReportBarGraphPage';
import ImplementInterventionsDetailsPage from './ImplementedInterventionsDetailsPage';
import Footer from './Footer';
import Header from './Header';
import numberWithCommas from '../../../../../utils/numberToComma';
import EnergyFlowDiagramSvg from './EnergyFlowDiagramSvg';
import { getCategoryWiseEnabledAndActivatedInterventionsData, getCurrentDate, getDataById } from './ReportUtils';

const styles = StyleSheet.create({
  footerMargin: {
    top: 30,
    marginTop: '25%',
  },
  viewPage2: {
    margin: '10px 20px 20px 20px',
    padding: 10,
    flexGrow: 1,
  },
  page: {
    flexDirection: 'column',
    backgroundColor: '#FFFFFF',
  },
  viewer: {
    width: window.innerWidth - 8,
    height: window.innerHeight - 8,
  },
});

Font.register({
  family: 'Lato',
  fonts: [
    {
      src: LatoRegular,
    },
    {
      src: LatoBold,
    },
  ],
});

Font.register({
  family: 'Lato-Italic',
  fonts: [
    {
      src: LatoItalic,
    },
  ],
});

const EnergyReport = () => {
  const { userDetails } = useContext(UserContext);
  const params = useParams();
  const [asset, setAsset] = useState('');
  const [intervention, setIntervention] = useState(null);
  const [reportData, setReportData] = useState([]);
  const [projectData, setProjectData] = useState('');
  const [flowDiagramData, setFlowDiagramData] = useState([]);
  const { data: insightsResponse } = useAppApiRequest(`/metafire?projectId=${params?.id}&feature=${MetafireFeatures.INSIGHTS}&numberOfYears=1`, {
    method: 'GET',
  });
  const { data: projectDataResponse } = useAppApiRequest(`/projectv2/${params?.id}/basics?NumberOfYears=1`, { method: 'GET' });
  const { data: reportResponse } = useAppApiRequest(`/metafire?projectId=${params?.id}&feature=${MetafireFeatures.REPORT}`, { method: 'GET' });
  const { data: flowDiagram } = useAppApiRequest(`/metafire?projectId=${params?.id}&feature=${MetafireFeatures.FLOWDIAGRAMS}`, { method: 'GET' });

  useEffect(() => {
    if (flowDiagram) {
      setFlowDiagramData(flowDiagram?.data?.data?.data);
    }
  }, [flowDiagram]);
  useEffect(() => {
    if (insightsResponse) {
      setIntervention(insightsResponse?.data?.data?.data?.interventions);
    }
  }, [insightsResponse]);
  useEffect(() => {
    if (reportResponse) {
      setReportData(reportResponse?.data?.data?.data);
    }
  }, [reportResponse]);
  useEffect(() => {
    if (projectDataResponse) {
      setProjectData(projectDataResponse?.data?.data?.data);
    }
  }, [projectDataResponse]);

  const { data: assetReponse } = useAppApiRequest(`/sign-s3/${userDetails?.organisation?.payloadId}`, { method: 'GET' });
  useEffect(() => {
    if (assetReponse?.data?.data) {
      setAsset(assetReponse?.data?.data?.data);
    }
  }, [assetReponse]);

  return (
    <PDFViewer style={styles.viewer}>
      <Document title={`Energy Use Report ${getCurrentDate()}`}>
        <Page size="A4" style={styles.page}>
          <HeadingPage
            category={'Energy'}
            pageIcon={EnergyUseReport}
            asset={asset}
            reportName={'Energy Use'}
            projectname={projectData && projectData.name}
            date={getCurrentDate()}
            reportHeadingColor="#F28D14"
          />
        </Page>
        <Page>
          <CategoryReportBarGraphPage
            heading="Energy"
            category="Energy"
            icon={EnergyRounded}
            companyname={projectData && projectData.name}
            capex={intervention?.categorized?.filter((item) => item.name === 'Energy')[0]?.quantities?.finance?.capex?.value}
            opex={intervention?.categorized?.filter((item) => item.name === 'Energy')[0]?.quantities?.finance?.opexSavings?.value}
            totalResourceSavings={intervention?.categorized?.filter((item) => item.name === 'Energy')[0]?.quantities?.resource?.category?.value}
            unit="kWh"
            baselineConsumption={intervention?.categorized?.filter((item) => item.name === 'Energy')[0]?.quantities?.resource?.annualBaselineConsumption?.value}
            year={getCurrentDate().split('/')[2]}
            title1={'Base Case Energy Consumption'}
            title2={'Sustainable Case Energy Consumption'}
            percentageResourceSaving={intervention?.categorized?.filter((item) => item.name === 'Energy')[0]?.quantities?.resource?.categorySavingPercentage}
          />
        </Page>
        <Page size="A4" style={styles.page}>
          <CategoryWiseSummaryPage
            companyname={projectData && projectData.name}
            totalResourceSavings={{
              value: intervention?.categorized?.filter((item) => item.name === 'Energy')[0]?.quantities?.resource?.category?.value,
              unit: 'kWh',
            }}
            interventionData={intervention}
            resourceSavingPercentage={
              intervention?.categorized?.filter((item) => item.name === 'Energy')[0]?.quantities?.resource?.categorySavingPercentage?.value
            }
            category="Energy"
            border="1px solid #9E9E9E"
            color="#F28D14"
            categorisedReportData={getCategoryWiseEnabledAndActivatedInterventionsData(reportData && reportData, 'reportImpactSummary_energy')}
            headingIcon={EnergyRounded}
            dummyicon={energyIcon}
            year={getCurrentDate().split('/')[2]}
          />
        </Page>
        <Page size="A4" style={styles.page}>
          <ImplementInterventionsDetailsPage
            companyname={projectData && projectData.name}
            ProjectinterventionMappingData={{}}
            allVendorMappings={{}}
            allvendors={{}}
            EnabledGrouped={{}}
            border="1px solid #9E9E9E"
            color="#F28D14"
            year={getCurrentDate().split('/')[2]}
            category="Energy"
            categorisedReportData={getCategoryWiseEnabledAndActivatedInterventionsData(reportData && reportData, 'reportImpactSummary_energy')}
            numnber={2}
            resourceSavingUnit={'kWh'}
          />
        </Page>
        <Page size="A4" style={styles.page}>
          <View style={styles.viewPage2}>
            <Header companyname={projectData && projectData.name} />
            <Text
              style={{ fontFamily: 'Lato', fontWeight: 700, fontSize: '14px', textTransform: 'uppercase', marginTop: '20px' }}
            >{`ENERGY FLOW DIAGRAM`}</Text>
            <View>
              <EnergyFlowDiagramSvg
                mainGrid={`${numberWithCommas(getDataById('energyFlowDiagram_totalMainGrid', flowDiagramData))}`}
                residentiallighting={`${numberWithCommas(getDataById('energyFlowDiagram_totalResidentialLighting', flowDiagramData))}`}
                residentialhvac={`${numberWithCommas(getDataById('energyFlowDiagram_totalResidenitalHVAC', flowDiagramData))}`}
                residentialfan={`${numberWithCommas(getDataById('energyFlowDiagram_totalResidentialFan', flowDiagramData))}`}
                residentialhotwater={`${numberWithCommas(getDataById('energyFlowDiagram_totalResidentialHotWater', flowDiagramData))}`}
                homeappliances={`${numberWithCommas(getDataById('energyFlowDiagram_totalHomeAppliances', flowDiagramData))}`}
                commonarea={`${numberWithCommas(getDataById('energyFlowDiagram_totalCommonArea', flowDiagramData))}`}
                dieselgenerator={`${numberWithCommas(getDataById('energyFlowDiagram_totalDieselGenerator', flowDiagramData))}`}
                solarweaterheater={`${numberWithCommas(getDataById('energyFlowDiagram_totalHotWaterManagement', flowDiagramData))}`}
                solarpv={`${numberWithCommas(getDataById('energyFlowDiagram_totalSolarPV', flowDiagramData))}`}
                biogas={`${numberWithCommas(getDataById('energyFlowDiagram_biogasToCommonArea', flowDiagramData))}`}
              />
            </View>
          </View>
          <View style={styles.footerMargin}>
            <Footer year={getCurrentDate().split('/')[2]} />
          </View>
        </Page>
      </Document>
    </PDFViewer>
  );
};
export default EnergyReport;
