import React from 'react';
import { Typography, Grid } from '@material-ui/core';
import { CheckBox, CheckBoxOutlineBlankOutlined } from '@material-ui/icons';
import CustomDropdown from '../common/CustomDropdown';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import ResetFields from '../common/textFiledwithReset';
import { useInterventionDetailsContext } from '../context/InterventionDetails';
import useStyles from './styles';

const RRWHCustomBasicParameters = (props) => {
  const classes = useStyles();
  const {
    schema,
    get: { getQuantityValue, getQuantityDefaultValue, getQuantityLabel, getQuantityUnit },
    set: { updateQuantityValue },
  } = useInterventionDetailsContext();
  const demandOption = getQuantityValue(schema.rrwh__pi_demandOption);
  const maximiseOption = getQuantityValue(schema.rrwh__pi_maximiseOption);
  const rechargeStructureOptions = getQuantityValue(schema.rrwh__pi_rechargeStructureOptions);

  const RechargeStructureOptions = [
    { option: 'Recharge Pits', label: 'Recharge Pits', enabled: true },
    { option: 'Recharge Trenches', label: 'Recharge Trenches', enabled: true },
    { option: 'Recharge Wells', label: 'Recharge Wells', enabled: true },
    { option: 'Recharge Shafts with Borewells', label: 'Recharge Shafts with Borewells', enabled: true },
    { option: 'Recharge Trenches with Borewells', label: 'Recharge Trenches with Borewells', enabled: true },
    { option: 'Recharge Existing Bore Wells', label: 'Recharge Existing Bore Wells', enabled: true },
  ];

  const handleDemandOption = (option) => {
    const currentValue = getQuantityValue(schema.rrwh__pi_demandOption);

    let newValue;
    if (currentValue.includes(option)) {
      newValue = currentValue.filter((item) => item !== option);
    } else {
      newValue = [...currentValue, option];
    }

    updateQuantityValue(schema.rrwh__pi_demandOption, newValue);
  };

  const handleMaximiseOption = (option) => {
    updateQuantityValue(schema.rrwh__pi_maximiseOption, option);
  };

  // TODO: add validation for save
  const handleRechargeStructureOptions = (options) => {
    if (!options.includes('Recharge Pits')) updateQuantityValue(schema.rrwh__pi_rpRechargePercentage, 0);
    if (!options.includes('Recharge Trenches')) updateQuantityValue(schema.rrwh__pi_rtRechargePercentage, 0);
    if (!options.includes('Recharge Wells')) updateQuantityValue(schema.rrwh__pi_rwRechargePercentage, 0);
    if (!options.includes('Recharge Shafts with Borewells')) updateQuantityValue(schema.rrwh__pi_rsbwRechargePercentage, 0);
    if (!options.includes('Recharge Trenches with Borewells')) updateQuantityValue(schema.rrwh__pi_rtbwRechargePercentage, 0);
    if (!options.includes('Recharge Existing Bore Wells')) updateQuantityValue(schema.rrwh__pi_rbwRechargePercentage, 0);

    updateQuantityValue(schema.rrwh__pi_rechargeStructureOptions, options);
  };

  return (
    <Grid container direction="column" alignItems="left" item xs={12}>
      <Grid container item xs={12}>
        <Grid item xs={4} direction="column">
          <Grid container item xs={12} style={{ paddingBottom: '10px' }}>
            <Typography className={classes.textHeader5}>{getQuantityLabel(schema.rrwh__pi_tankSize)}</Typography>
          </Grid>
          <Grid xs={12} container alignItems="flex-start" direction="row" style={{ display: 'flex', alignItems: 'center' }}>
            <Grid xs={7}>
              <ResetFields
                id="outlined-basic"
                variant="outlined"
                value={getQuantityValue(schema.rrwh__pi_tankSize)}
                placeholder=""
                onChange={(e) => {
                  e.preventDefault();
                  updateQuantityValue(schema.rrwh__pi_tankSize, e.target.value);
                }}
                fullWidth
                required
                onClick={(e) => {
                  e.preventDefault();
                  updateQuantityValue(schema.rrwh__pi_tankSize, null);
                }}
                disabled={false}
              />
            </Grid>
            <Grid xs={3} className={classes.grid} style={{ paddingLeft: '2%' }}>
              <Typography variant="h6">{getQuantityUnit(schema.rrwh__pi_tankSize)}</Typography>
            </Grid>
          </Grid>
          <Grid container item xs={12} style={{ paddingTop: '10px', paddingBottom: '10px' }}>
            <Typography variant="caption">
              {getQuantityLabel(schema.rrwh_mandatedTankSize)}: {getQuantityValue(schema.rrwh_mandatedTankSize)} {getQuantityUnit(schema.rrwh_mandatedTankSize)}
            </Typography>
          </Grid>
          <Grid container item xs={12} style={{ paddingBottom: '10px' }}>
            <Typography variant="caption">
              {getQuantityLabel(schema.rrwh_additionalTankSize)}: {getQuantityValue(schema.rrwh_additionalTankSize) || '-'}{' '}
              {getQuantityUnit(schema.rrwh_additionalTankSize)}
            </Typography>
          </Grid>
        </Grid>

        <Grid item xs={4} direction="column">
          <Grid container item xs={12} style={{ paddingBottom: '10px' }}>
            <Typography className={classes.textHeader5}>{getQuantityLabel(schema.rrwh_daysServiced)}</Typography>
          </Grid>
          <Grid xs={12} container alignItems="flex-start" direction="row" style={{ display: 'flex', alignItems: 'center' }}>
            <Typography className={classes.textHeader5}>{getQuantityValue(schema.rrwh_daysServiced)}</Typography>
          </Grid>
        </Grid>

        <Grid item xs={4} direction="column">
          <Grid container item xs={12} style={{ paddingBottom: '10px' }}>
            <Typography className={classes.textHeader5}>{getQuantityLabel(schema.rrwh_annualRechargedWater)}</Typography>
          </Grid>
          <Grid xs={12} container alignItems="flex-start" direction="row" style={{ display: 'flex', alignItems: 'center' }}>
            <Typography className={classes.textHeader5}>
              {getQuantityValue(schema.rrwh_annualRechargedWater)} {getQuantityUnit(schema.rrwh_annualRechargedWater)}
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid container item xs={12}>
        <Grid item xs={4} direction="column">
          <Grid container item xs={12} style={{ paddingBottom: '10px' }}>
            <Typography className={classes.textHeader5}>{getQuantityLabel(schema.rrwh__pi_demandOption)}</Typography>
          </Grid>

          {demandOption.includes('domestic') ? (
            <Grid xs={12} container alignItems="flex-start" direction="row" style={{ display: 'flex', alignItems: 'center' }}>
              <CheckBox className={classes.nonselectedCheckbox} onClick={() => handleDemandOption('domestic')} />
              <Typography>Domestic</Typography>
            </Grid>
          ) : (
            <Grid xs={12} container alignItems="flex-start" direction="row" style={{ display: 'flex', alignItems: 'center' }}>
              <CheckBoxOutlineBlankOutlined className={classes.nonselectedCheckbox} onClick={() => handleDemandOption('domestic')} />
              <Typography>Domestic</Typography>
            </Grid>
          )}

          {demandOption.includes('drinking') ? (
            <Grid xs={12} container alignItems="flex-start" direction="row" style={{ display: 'flex', alignItems: 'center' }}>
              <CheckBox className={classes.nonselectedCheckbox} onClick={() => handleDemandOption('drinking')} />
              <Typography>Drinking</Typography>
            </Grid>
          ) : (
            <Grid xs={12} container alignItems="flex-start" direction="row" style={{ display: 'flex', alignItems: 'center' }}>
              <CheckBoxOutlineBlankOutlined className={classes.nonselectedCheckbox} onClick={() => handleDemandOption('drinking')} />
              <Typography>Drinking</Typography>
            </Grid>
          )}
        </Grid>

        <Grid item xs={6} direction="column">
          <Grid container item xs={12} style={{ paddingBottom: '8px' }}>
            <Typography className={classes.textHeader5}>{getQuantityLabel(schema.rrwh__pi_maximiseOption)}</Typography>
          </Grid>
          <Grid container item xs={12}>
            <Grid item xs={6} container alignItems="flex-start" direction="column" style={{}}>
              <Grid xs>
                <Typography className={classes.textHeader3}>
                  {maximiseOption === 'Maximise Reuse' && (
                    <RadioButtonCheckedIcon className={classes.selectedRadiobox} onClick={() => handleMaximiseOption('Maximise Reuse')} />
                  )}
                  {maximiseOption !== 'Maximise Reuse' && (
                    <RadioButtonUncheckedIcon className={classes.nonselectedRadiobox} onClick={() => handleMaximiseOption('Maximise Reuse')} />
                  )}
                  Maximise Reuse
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid container item xs={12}>
            <Grid item xs={6} container alignItems="flex-start" direction="column" style={{}}>
              <Grid xs>
                <Typography className={classes.textHeader3}>
                  {maximiseOption === 'Maximise Recharge' && (
                    <RadioButtonCheckedIcon className={classes.selectedRadiobox} onClick={() => handleMaximiseOption('Maximise Recharge')} />
                  )}
                  {maximiseOption !== 'Maximise Recharge' && (
                    <RadioButtonUncheckedIcon className={classes.nonselectedRadiobox} onClick={() => handleMaximiseOption('Maximise Recharge')} />
                  )}
                  Maximise Recharge
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid container item xs={12}>
        <Grid container item xs={12} style={{ paddingBottom: '10px' }}>
          <Typography className={classes.textHeader5}>{getQuantityLabel(schema.rrwh__pi_rechargeStructureOptions)}</Typography>
        </Grid>
        <Grid container item xs={12} style={{ paddingBottom: '10px' }}>
          <Typography variant="caption">The % should add up to 100%</Typography>
        </Grid>
        <Grid container item xs={12} style={{ paddingBottom: '10px' }}>
          <Grid item xs={6} direction="column">
            <CustomDropdown
              value={getQuantityValue(schema.rrwh__pi_rechargeStructureOptions)}
              onChange={(e) => {
                e.preventDefault();
                handleRechargeStructureOptions(e.target.value);
              }}
              options={RechargeStructureOptions}
              disabled={false}
              multiple={true}
            />
          </Grid>
          <Grid item xs={6} direction="column"></Grid>
        </Grid>

        <Grid container item xs={12} style={{ paddingBottom: '10px' }}>
          <Grid item xs={4} direction="column"></Grid>
          <Grid item xs={2} direction="column">
            <Typography variant="caption">Split %</Typography>
          </Grid>
          <Grid item xs={3} direction="column">
            <Typography variant="caption">No. of Structures</Typography>
          </Grid>
          <Grid item xs={3} direction="column">
            <Typography variant="caption">Surface are required</Typography>
          </Grid>
        </Grid>

        {rechargeStructureOptions.includes('Recharge Pits') && (
          <Grid container item xs={12} style={{ paddingBottom: '10px' }}>
            <Grid item xs={4} direction="column">
              <Typography className={classes.textHeader5}>Recharge Pits</Typography>
            </Grid>
            <Grid item xs={2} direction="column">
              <Grid container item xs={12} style={{ paddingBottom: '10px' }}>
                <Grid item xs={6} direction="column">
                  <ResetFields
                    id="outlined-basic"
                    variant="outlined"
                    value={getQuantityValue(schema.rrwh__pi_rpRechargePercentage)}
                    placeholder=""
                    onChange={(e) => {
                      e.preventDefault();
                      updateQuantityValue(schema.rrwh__pi_rpRechargePercentage, e.target.value);
                    }}
                    fullWidth
                    required
                    disabled={false}
                    removeResetIcon={true}
                  />
                </Grid>
                <Grid item xs={2} direction="column">
                  <Typography className={classes.textHeader5}>%</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={3} direction="column">
              <Typography className={classes.textHeader5}>{getQuantityValue(schema.rrwh_rpNumberOfStructures)}</Typography>
            </Grid>
            <Grid item xs={3} direction="column">
              <Typography className={classes.textHeader5}>{getQuantityValue(schema.rrwh_rpSurfaceArea)}</Typography>
            </Grid>
          </Grid>
        )}

        {rechargeStructureOptions.includes('Recharge Trenches') && (
          <Grid container item xs={12} style={{ paddingBottom: '10px' }}>
            <Grid item xs={4} direction="column">
              <Typography className={classes.textHeader5}>Recharge Trenches</Typography>
            </Grid>
            <Grid item xs={2} direction="column">
              <Grid container item xs={12} style={{ paddingBottom: '10px' }}>
                <Grid item xs={6} direction="column">
                  <ResetFields
                    id="outlined-basic"
                    variant="outlined"
                    value={getQuantityValue(schema.rrwh__pi_rtRechargePercentage)}
                    placeholder=""
                    onChange={(e) => {
                      e.preventDefault();
                      updateQuantityValue(schema.rrwh__pi_rtRechargePercentage, e.target.value);
                    }}
                    fullWidth
                    required
                    disabled={false}
                    removeResetIcon={true}
                  />
                </Grid>
                <Grid item xs={2} direction="column">
                  <Typography className={classes.textHeader5}>%</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={3} direction="column">
              <Typography className={classes.textHeader5}>{getQuantityValue(schema.rrwh_rtNumberOfStructures)}</Typography>
            </Grid>
            <Grid item xs={3} direction="column">
              <Typography className={classes.textHeader5}>{getQuantityValue(schema.rrwh_rtSurfaceArea)}</Typography>
            </Grid>
          </Grid>
        )}

        {rechargeStructureOptions.includes('Recharge Wells') && (
          <Grid container item xs={12} style={{ paddingBottom: '10px' }}>
            <Grid item xs={4} direction="column">
              <Typography className={classes.textHeader5}>Recharge Wells</Typography>
            </Grid>
            <Grid item xs={2} direction="column">
              <Grid container item xs={12} style={{ paddingBottom: '10px' }}>
                <Grid item xs={6} direction="column">
                  <ResetFields
                    id="outlined-basic"
                    variant="outlined"
                    value={getQuantityValue(schema.rrwh__pi_rwRechargePercentage)}
                    placeholder=""
                    onChange={(e) => {
                      e.preventDefault();
                      updateQuantityValue(schema.rrwh__pi_rwRechargePercentage, e.target.value);
                    }}
                    fullWidth
                    required
                    disabled={false}
                    removeResetIcon={true}
                  />
                </Grid>
                <Grid item xs={2} direction="column">
                  <Typography className={classes.textHeader5}>%</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={3} direction="column">
              <Typography className={classes.textHeader5}>{getQuantityValue(schema.rrwh_rwNumberOfStructures)}</Typography>
            </Grid>
            <Grid item xs={3} direction="column">
              <Typography className={classes.textHeader5}>{getQuantityValue(schema.rrwh_rwSurfaceArea)}</Typography>
            </Grid>
          </Grid>
        )}

        {rechargeStructureOptions.includes('Recharge Shafts with Borewells') && (
          <Grid container item xs={12} style={{ paddingBottom: '10px' }}>
            <Grid item xs={4} direction="column">
              <Typography className={classes.textHeader5}>Recharge Shafts with Borewells</Typography>
            </Grid>
            <Grid item xs={2} direction="column">
              <Grid container item xs={12} style={{ paddingBottom: '10px' }}>
                <Grid item xs={6} direction="column">
                  <ResetFields
                    id="outlined-basic"
                    variant="outlined"
                    value={getQuantityValue(schema.rrwh__pi_rsbwRechargePercentage)}
                    placeholder=""
                    onChange={(e) => {
                      e.preventDefault();
                      updateQuantityValue(schema.rrwh__pi_rsbwRechargePercentage, e.target.value);
                    }}
                    fullWidth
                    required
                    disabled={false}
                    removeResetIcon={true}
                  />
                </Grid>
                <Grid item xs={2} direction="column">
                  <Typography className={classes.textHeader5}>%</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={3} direction="column">
              <Typography className={classes.textHeader5}>{getQuantityValue(schema.rrwh_rsbwNumberOfStructures)}</Typography>
            </Grid>
            <Grid item xs={3} direction="column">
              <Typography className={classes.textHeader5}>{getQuantityValue(schema.rrwh_rsbwSurfaceArea)}</Typography>
            </Grid>
          </Grid>
        )}

        {rechargeStructureOptions.includes('Recharge Trenches with Borewells') && (
          <Grid container item xs={12} style={{ paddingBottom: '10px' }}>
            <Grid item xs={4} direction="column">
              <Typography className={classes.textHeader5}>Recharge Trenches with Borewells</Typography>
            </Grid>
            <Grid item xs={2} direction="column">
              <Grid container item xs={12} style={{ paddingBottom: '10px' }}>
                <Grid item xs={6} direction="column">
                  <ResetFields
                    id="outlined-basic"
                    variant="outlined"
                    value={getQuantityValue(schema.rrwh__pi_rtbwRechargePercentage)}
                    placeholder=""
                    onChange={(e) => {
                      e.preventDefault();
                      updateQuantityValue(schema.rrwh__pi_rtbwRechargePercentage, e.target.value);
                    }}
                    fullWidth
                    required
                    disabled={false}
                    removeResetIcon={true}
                  />
                </Grid>
                <Grid item xs={2} direction="column">
                  <Typography className={classes.textHeader5}>%</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={3} direction="column">
              <Typography className={classes.textHeader5}>{getQuantityValue(schema.rrwh_rtbwNumberOfStructures)}</Typography>
            </Grid>
            <Grid item xs={3} direction="column">
              <Typography className={classes.textHeader5}>{getQuantityValue(schema.rrwh_rtbwSurfaceArea)}</Typography>
            </Grid>
          </Grid>
        )}

        {rechargeStructureOptions.includes('Recharge Existing Bore Wells') && (
          <Grid container item xs={12} style={{ paddingBottom: '10px' }}>
            <Grid item xs={4} direction="column">
              <Typography className={classes.textHeader5}>Recharge Existing Bore Wells</Typography>
            </Grid>
            <Grid item xs={2} direction="column">
              <Grid container item xs={12} style={{ paddingBottom: '10px' }}>
                <Grid item xs={6} direction="column">
                  <ResetFields
                    id="outlined-basic"
                    variant="outlined"
                    value={getQuantityValue(schema.rrwh__pi_rbwRechargePercentage)}
                    placeholder=""
                    onChange={(e) => {
                      e.preventDefault();
                      updateQuantityValue(schema.rrwh__pi_rbwRechargePercentage, e.target.value);
                    }}
                    fullWidth
                    required
                    disabled={false}
                    removeResetIcon={true}
                  />
                </Grid>
                <Grid item xs={2} direction="column">
                  <Typography className={classes.textHeader5}>%</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={3} direction="column">
              <Typography className={classes.textHeader5}>{getQuantityValue(schema.rrwh_rbwNumberOfStructures)}</Typography>
            </Grid>
            <Grid item xs={3} direction="column">
              <Typography className={classes.textHeader5}>{getQuantityValue(schema.rrwh_rbwSurfaceArea)}</Typography>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};
export default RRWHCustomBasicParameters;
