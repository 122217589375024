import React, { useEffect, useState } from 'react';
import axios from '../../../../../config/Axios';
import ManualPIP from './interiorPaintComponents/ManualPIP';
import PresetsPIP from './interiorPaintComponents/PresetsPIP';
import { GreenRadio } from '../../../../common/RadioButton/GreenRadio';
import { Grid, Typography, FormControl, FormControlLabel, RadioGroup } from '@material-ui/core';
import CustomDropdown from '../../../interventions/interventionDetails/common/CustomDropdown';

export const DropDownItems = {
  brand: 'project__pi_interiorPaintWaterBasedBrandName',
  finish: 'project__pi_interiorPaintWaterBasedFinish',
  paint: 'project__pi_interiorPaintWaterBasedPaintName',
};

export const TextBoxItems = {
  manualBrand: 'project__pi_interiorPaintManualBrandName',
  manualPaint: 'project__pi_interiorPaintManualPaintName',
  manualCoverageArea: 'project__pi_interiorPaintManualCoverageArea',
  manualCapex: 'project__pi_interiorPaintManualCapex',
  manualEC: 'project__pi_interiorPaintManualEC',
};

function InteriorPaints(props) {
  const { data, interiorPaintBrands, appendChanged, projectPIP, interiorPaintUserChoicePIPValue, interiorPaintUserChoicePIP, manualPips, pipValues } = props;

  const DropDowns = Object.entries(DropDownItems).map((name) => data && data.find((pip) => pip.name === name[1]));
  const TextBoxes = Object.entries(TextBoxItems).map((name) => data && data.find((pip) => pip.name === name[1]));
  const vocDropDown = data && data.find((pip) => pip.name === 'project__pi_interiorPaintManualVocLevel');
  // all paints and finishes based upon brand
  const [selectedPaintFinishs, setSelectedPaintFinishs] = useState('');
  const [selectedPaints, setSelectedPaints] = useState('');

  // selected preset values
  const [selectedPaintBrand, setSelectedPaintBrand] = useState('');
  const [selectedPaintFinish, setSelectedPaintFinish] = useState('');
  const [selectedPaint, setSelectedPaint] = useState('');

  useEffect(() => {
    if (projectPIP) {
      const selectedPresetBrand = projectPIP && projectPIP.find((d) => d.PIPScheme.name === DropDownItems.brand)?.value;
      const selectedPresetFinish = projectPIP && projectPIP.find((d) => d.PIPScheme.name === DropDownItems.finish)?.value;
      const selectedPresetPaint = projectPIP && projectPIP.find((d) => d.PIPScheme.name === DropDownItems.paint)?.value;
      setSelectedPaintBrand(selectedPresetBrand);
      setSelectedPaintFinish(selectedPresetFinish);
      setSelectedPaint(selectedPresetPaint);
    }
  }, [projectPIP]);

  useEffect(() => {
    if (selectedPaintBrand)
      axios.get(`PaintProvider?list=Finish&type=Interior Paints&brand=${selectedPaintBrand}`).then((res) => setSelectedPaintFinishs(res && res.data.data.data));
  }, [selectedPaintBrand]);

  useEffect(() => {
    if (selectedPaintBrand && selectedPaintFinish)
      axios
        .get(`PaintProvider?list=Paint&brand=${selectedPaintBrand}&finish=${selectedPaintFinish}&type=Interior Paints`)
        .then((res) => setSelectedPaints(res && res.data.data.data));
  }, [selectedPaintBrand, selectedPaintFinish]);

  return (
    <>
      <Grid container direction="row" xs={12} style={{ height: 'Auto' }}>
        <Grid container alignItems="flex-start" item xs={12} style={{ marginLeft: '2%', marginTop: '3%' }}>
          <Typography variant="h5">Interior Paint</Typography>
        </Grid>
        <Grid container alignItems="flex-start" direction="row" xs={12} item style={{ marginLeft: '2%' }}>
          <FormControl component="fieldset">
            <RadioGroup
              aria-label="exterior paint"
              name="paint schemes"
              value={pipValues[interiorPaintUserChoicePIP._id] || ''}
              onChange={(e) => {
                appendChanged(interiorPaintUserChoicePIP && interiorPaintUserChoicePIP._id, e.target.value);
                // setInteriorPaintUserChoicePIPValue(e.target.value);
              }}
            >
              {interiorPaintUserChoicePIP.options.map((option, index) => {
                return (
                  <>
                    <FormControlLabel key={index} value={option} control={<GreenRadio />} label={option} />
                    {interiorPaintUserChoicePIPValue && index === 1 && interiorPaintUserChoicePIPValue === 'Water Based' && (
                      <>
                        {DropDowns?.map(({ name: dropdown, displayName, _id }, index) => {
                          if (displayName === 'Brand') {
                            return (
                              <PresetsPIP
                                key={index}
                                _id={_id}
                                displayName={displayName}
                                appendChanged={(a, b) => appendChanged(a, b)}
                                values={interiorPaintBrands}
                                disabledValue={false}
                                selectedValue={selectedPaintBrand}
                                setSelected={(a) => setSelectedPaintBrand(a)}
                              />
                            );
                          }
                          if (displayName === 'Finish') {
                            return (
                              <PresetsPIP
                                key={index}
                                _id={_id}
                                displayName={displayName}
                                appendChanged={(a, b) => appendChanged(a, b)}
                                values={selectedPaintFinishs}
                                disabledValue={selectedPaintBrand ? false : true}
                                selectedValue={selectedPaintFinish}
                                setSelected={(b) => setSelectedPaintFinish(b)}
                              />
                            );
                          }
                          if (displayName === 'Paint') {
                            return (
                              <PresetsPIP
                                key={index}
                                _id={_id}
                                displayName={displayName}
                                appendChanged={(a, b) => appendChanged(a, b)}
                                values={selectedPaints}
                                disabledValue={selectedPaintFinish ? false : true}
                                selectedValue={selectedPaint}
                                setSelected={(c) => setSelectedPaint(c)}
                              />
                            );
                          }
                        })}
                      </>
                    )}
                    {interiorPaintUserChoicePIPValue &&
                      index === 2 &&
                      interiorPaintUserChoicePIPValue === 'Manual' &&
                      manualPips &&
                      TextBoxes &&
                      TextBoxes.map(({ name: textbox, displayName, _id, description }) => (
                        <ManualPIP
                          description={description}
                          textbox={textbox}
                          displayName={displayName}
                          _id={_id}
                          pipValues={pipValues}
                          projectPIP={projectPIP}
                          appendChanged={(a, b) => appendChanged(a, b)}
                        />
                      ))}
                    {interiorPaintUserChoicePIPValue && index === 2 && interiorPaintUserChoicePIPValue === 'Manual' && vocDropDown && (
                      <Grid xs={10} style={{ marginTop: '12%', paddingLeft: '8px' }}>
                        <Grid container alignItems="flex-start" item xs={12} style={{ marginTop: '3%', marginBottom: '8%' }}>
                          <Typography variant="h6">{vocDropDown.displayName}</Typography>
                        </Grid>
                        <CustomDropdown
                          value={pipValues[vocDropDown._id]}
                          onChange={(e) => {
                            e.preventDefault();
                            appendChanged(vocDropDown._id, e.target.value);
                          }}
                          options={vocDropDown.options}
                          disabled={false}
                        />
                      </Grid>
                    )}
                  </>
                );
              })}
            </RadioGroup>
          </FormControl>
        </Grid>
      </Grid>
    </>
  );
}

export default InteriorPaints;
