import React from 'react';
import { FormControl, Grid, InputLabel, MenuItem, Select, Typography } from '@material-ui/core';
import SimplePIPS from '../../common/components/SimplePIPS';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import useStyles from './styles';

const ConcreteMaterialDetails = ({ data, appendChanged, projectPIP, pipValues }) => {
  const classes = useStyles();
  return (
    <>
      <Grid container direction="row" xs={12} style={{ height: 'Auto' }}>
        <Grid container alignItems="flex-start" item xs={12} style={{ marginLeft: '2%', marginTop: '1%' }}>
          <Typography variant="h5" style={{ fontWeight: 700, fontSize: '20px' }}>
            Concrete
          </Typography>
        </Grid>
      </Grid>
      {data &&
        data.map((pip, index) => {
          return pip.type === 'number' && pip.options.length !== 0 ? (
            <Grid alignItems="flex-start" item xs={12} style={{ marginLeft: '2%', marginTop: '1%' }}>
              <Grid item xs={12}>
                <Typography variant="h5">{pip.displayName}</Typography>
              </Grid>
              <Grid container item xs={12} alignItems="center">
                <Grid item>
                  <FormControl variant="outlined" style={{ width: '166px', borderRadius: `50px` }} className={classes.dropdown}>
                    <InputLabel id="demo-simple-select-label">Select</InputLabel>
                    <Select
                      disabled={false}
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      label={pip.displayName}
                      placeholder={pip.displayName}
                      className={classes.select}
                      value={pipValues[pip._id] && pipValues[pip._id] ? pipValues[pip._id] : ''}
                    >
                      {pip && pip.options.length > 0
                        ? pip.options.map((value) => {
                            return (
                              <MenuItem
                                onClick={() => {
                                  appendChanged(pip._id, Number(value));
                                }}
                                value={value}
                                name={value}
                                style={{ paddingTop: '2%' }}
                              >
                                {value}
                              </MenuItem>
                            );
                          })
                        : pip &&
                          pip.options.length > 0 &&
                          pip.options.map((value) => {
                            return (
                              <MenuItem
                                onClick={() => {
                                  appendChanged(pip._id, value);
                                }}
                                value={value}
                                name={value}
                                style={{ paddingTop: '2%' }}
                              >
                                {value}
                              </MenuItem>
                            );
                          })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item style={{ paddingLeft: '10px' }}>
                  <Typography>{pip.unit}</Typography>
                </Grid>
              </Grid>
            </Grid>
          ) : pip.type === 'number' && pip.options.length === 0 ? (
            <SimplePIPS
              key={index}
              index={index}
              unit="Sqm"
              field={pip}
              projectPIP={projectPIP}
              appendChanged={(fieldId, value) => appendChanged(fieldId, value)}
              type="material"
            />
          ) : (
            <Grid container alignItems="flex-start" item xs={12} style={{ marginLeft: '2%', marginTop: '1%' }}>
              <Grid item xs={12}>
                <Typography variant="h5">Type of Construction</Typography>
              </Grid>
              <Grid item xs={6} style={{ marginLeft: '2%', marginBottom: '1%' }}>
                <Typography className={classes.textHeader3}>
                  {pipValues[pip._id] === 'rcc frame structure' && (
                    <RadioButtonCheckedIcon className={classes.selectedRadiobox} onClick={() => appendChanged(pip?._id, 'rcc frame structure')} />
                  )}
                  {pipValues[pip._id] !== 'rcc frame structure' && (
                    <RadioButtonUncheckedIcon className={classes.nonselectedRadiobox} onClick={() => appendChanged(pip?._id, 'rcc frame structure')} />
                  )}
                  RCC frame structure
                </Typography>
                <Typography className={classes.textHeader3}>
                  {pipValues[pip._id] === 'aluminium form work' && (
                    <RadioButtonCheckedIcon className={classes.selectedRadiobox} onClick={() => appendChanged(pip?._id, 'aluminium form work')} />
                  )}
                  {pipValues[pip._id] !== 'aluminium form work' && (
                    <RadioButtonUncheckedIcon className={classes.nonselectedRadiobox} onClick={() => appendChanged(pip?._id, 'aluminium form work')} />
                  )}
                  Aluminium form work
                </Typography>
              </Grid>
            </Grid>
          );
        })}
    </>
  );
};

export default ConcreteMaterialDetails;
