import React, { useEffect, useMemo, useState } from 'react';
import { Container, Tabs, Tab } from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/core/styles';

// import Energy from './tabview/Energy';
import Water from './tabview/Water';
import Waste from './tabview/Waste';
import Materials from './tabview/Materials';
import CategorywiseInsights from './tabview/CategorywiseInsights';
import { useAppMetafireUtilities } from '../../../../metafire/hooks/useAppMetafireUtilities';
import numberWithCommas from '../../../../utils/numberToComma';
import unit from '../../../../utils/unitConfig';

const StyledTabs = withStyles((theme) => ({
  root: {
    borderBottom: '0.6px solid #CCCCCC',
  },
  indicator: {
    backgroundColor: '#F28D14',
  },
}))(Tabs);

const StyledTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    '&:focus': {
      outline: 'none',
    },
    minWidth: '70px',
    labelIcon: {
      color: '#9E9E9E',
      '&:focus': {
        fontStyle: 'normal',
        fontWeight: '900',
      },
    },
    image: {
      height: '500px',
      width: '500px',
    },
  },
}))(Tab);

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(9),
    position: 'sticky',

    top: 0,
  },
  title: {
    fontSize: 16,
    fontWeight: 500,
    color: '#555',
  },
  link: {
    marginRight: theme.spacing(2),
    color: '#555',
    fontSize: 16,
  },
}));

const CATEGORIES = {
  energy: 'Energy',
  water: 'Water',
  waste: 'Waste',
  materials: 'Materials',
};
const Rightbar = ({ goals, intervention }) => {
  const styles = {
    [CATEGORIES.energy]: {
      actual: '#F28D14',
      target: '#FFCE93',
    },
    [CATEGORIES.water]: {
      actual: '#3BC7EE',
      target: '#74DEFB',
    },
    [CATEGORIES.waste]: {
      actual: '#BC8A5F',
      target: '#F0D4BF',
    },
    [CATEGORIES.materials]: {
      actual: '#916AAC',
      target: '#EDCDFF',
    },
  };

  const classes = useStyles();
  const { getValue, getUnit, getRoundOffValue } = useAppMetafireUtilities();
  const [tab, setTab] = React.useState(CATEGORIES.energy);

  const handleChange = (event, label) => setTab(label);

  const selectedGoals = useMemo(() => {
    return goals?.find((category) => category?.id === `goalsInsights_${tab.toLowerCase()}`);
  }, [goals, tab]);

  const selectedIntervention = useMemo(() => {
    return intervention?.categorized?.find((category) => category?.name == tab);
  }, [intervention, tab]);

  const capexIncrease = selectedIntervention?.quantities?.finance?.capex;
  const resourceSavings = selectedIntervention?.quantities?.resource?.category;
  const progressPercentage = selectedGoals?.value?.progressPercentage || 0;
  const targetsAchieved = selectedGoals?.value?.targetsAchieved || 0;
  const targetsTargeted = selectedGoals?.value?.targetsTargeted || 0;
  const targetsPercentage = getRoundOffValue((targetsAchieved / targetsTargeted) * 100);
  return (
    <>
      <Container className={classes.container} data-tour="goaltracking">
        <StyledTabs value={tab} onChange={handleChange} variant="scrollable" scrollButtons="auto">
          <StyledTab value={CATEGORIES.energy} label="Energy" />
          <StyledTab value={CATEGORIES.water} label="Water" />
          <StyledTab value={CATEGORIES.waste} label="Waste" />
          <StyledTab value={CATEGORIES.materials} label="Materials" />
        </StyledTabs>
        <>
          <CategorywiseInsights
            title={`${selectedGoals?.label || 'Net Zero'}`}
            subheading="CAPEX Increase"
            background="#E5E5E5"
            barBackground={styles[tab].actual}
            barprogress={numberWithCommas(progressPercentage) || 0}
            barprogressText={`${numberWithCommas(getRoundOffValue(progressPercentage, 0))}%`}
            capexIncrease={`${numberWithCommas(getValue(capexIncrease))}`}
            capexUnit={`${getUnit(capexIncrease)}`}
          />

          <CategorywiseInsights
            title="Targets Achieved"
            subheading={`${selectedIntervention?.name} Savings`}
            barBackground={styles[tab].actual}
            background="#E5E5E5"
            barprogress={targetsPercentage || 0}
            barprogressText={`${targetsAchieved}/${targetsTargeted}`}
            capexIncrease={`${numberWithCommas(getValue(resourceSavings))}`}
            capexUnit={`${getUnit(resourceSavings)}`}
          />
        </>
      </Container>
    </>
  );
};

export default Rightbar;
