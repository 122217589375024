import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Grid, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  margin: {
    margin: theme.spacing(0),
    borderRadius: '20px',
  },
  color: {
    color: 'rgba(0, 0, 0, 0.38)',
  },
  spacing: {
    paddingTop: '5%',
  },
}));

export default function LinearProgressComponent({ barvalue = null, value, bgcolor, disabled }) {
  const classes = useStyles();
  const BorderLinearProgress = withStyles(() => ({
    root: {
      height: 15,
      background: '#E5E5E5',
    },
    bar: {
      borderRadius: 20,
      background: `${bgcolor}`,
    },
  }))(LinearProgress);

  return (
    <>
      <Grid item xs={9}>
        <BorderLinearProgress className={classes.margin} variant="determinate" value={barvalue || `${value}`} style={{ backgroundColor: `#E5E5E5` }} />
      </Grid>
      <Grid item xs={2} style={{ paddingLeft: '5%' }}>
        <Typography variant="h6" style={disabled === true ? { color: '#9E9E9E' } : {}}>{`${value}%`}</Typography>
      </Grid>
      <Grid item xs={1}></Grid>
    </>
  );
}
