import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  nonselectedRadiobox: {
    marginRight: '10px',
    cursor: 'pointer',
  },
  selectedRadiobox: {
    color: 'rgba(102, 227, 190, 1)',
    marginRight: '10px',
    cursor: 'pointer',
  },
  textHeader3: {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '24px',
    letterSpacing: '0.71px',
    textAlign: 'left',
    display: 'flex',
    alignItems: 'center',
    paddingTop: '10px',
  },
  dropdown: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderRadius: `10px`,
        width: '166px',
        paddingBottom: '30px',
        [theme.breakpoints.down('md')]: {
          width: '166px',
        },
        [theme.breakpoints.down('sm')]: {
          width: '110px',
        },
      },
    },
  },
  select: {
    padding: '1px',
    borderRadius: `15px`,
    [theme.breakpoints.down('sm')]: {
      width: '210px',
      padding: '2px',
      borderRadius: `15px`,
    },
  },
}));

export default useStyles;
