import React from 'react';
import { Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import Header from './Header';
import Footer from './Footer';
import rsToCr from '../../../../../utils/rsToCr';
import numberWithCommas from '../../../../../utils/numberToComma';

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#FFFFFF',
  },
  viewPage2: {
    margin: '20px 30px 30px 30px',
    padding: 10,
    flexGrow: 1,
  },
  pageCover: {
    height: '100%',
    width: '521px',
    paddingTop: '20px',
  },
  footerMargin: {
    padding: 10,
  },
});

const CategoryReportBarGraphPage = (props) => {
  let capex = props.capex;
  let opexSaving = props.opex;

  let barvalueOne = capex;
  let barvalueTwo = opexSaving;
  let ratio = barvalueTwo / barvalueOne > 1.2 ? 1.2 : barvalueTwo / barvalueOne;
  let calculatedBarvalueOne = barvalueOne !== 0 ? ratio * 90 : 10;

  let totalsaving = opexSaving;
  let totalResourceSavings = props.totalResourceSavings;
  let unit = props.unit;
  let barvalueThree = props.baselineConsumption;
  let barvalueFour = barvalueThree - totalResourceSavings;
  let calculatedBarvalue2 = (barvalueFour / barvalueThree) * 90;
  let percentage = props.percentageResourceSaving;

  const ResouceSavingText = () => {
    return (
      <Text style={{ fontFamily: 'Lato', fontSize: '11px', color: '#000000D4' }}>
        {props.category === 'Energy' &&
          `With ${numberWithCommas(
            Math.round(totalResourceSavings)
          )} ${unit} of energy saved by deploying Renewable sources of energy, the project saves ${Math.round(percentage && percentage.value)}% energy`}
        {props.category === 'Water' &&
          `With ${numberWithCommas(Math.round(totalResourceSavings))} ${unit} of water being saved, the project has ${Math.round(
            percentage && percentage.value
          )}% less water needed from external sources`}
        {props.category === 'Waste' &&
          `With ${numberWithCommas(Math.round(totalResourceSavings))} ${unit} of waste avoided from being sent to the landfill, the project has ${Math.round(
            percentage && percentage.value
          )}% less waste polluting the environment`}
        {props.category === 'Materials' &&
          `With ${numberWithCommas(Math.round(totalResourceSavings))} ${unit} tons of CO2 emissions avoided, the project is ${Math.round(
            percentage && percentage.value
          )}% closer to becoming Net Zero on Embodied Carbon`}
      </Text>
    );
  };

  return (
    <View>
      <View style={{ backgroundColor: '#15B7B9', height: '201px' }}>
        <View style={{ padding: '30px 20px 10px 20px' }}>
          <Header companyname={props.companyname} />
        </View>
        <View style={{ flexDirection: 'row', display: 'flex', marginTop: '10px' }}>
          <View style={{ width: '5%' }} />
          <View style={{ paddingTop: '15px', width: '50%' }}>
            <Text style={{ fontFamily: 'Lato', fontWeight: 700, fontSize: '30px', textTransform: 'uppercase' }}>{props.heading}</Text>
            <Text style={{ fontFamily: 'Lato', fontWeight: 700, fontSize: '30px', textTransform: 'uppercase' }}>Sustainability</Text>
          </View>
          <View style={{ width: '20%' }} />
          <View style={{ width: '20%' }}>
            <Image style={{ height: '100px', width: '100px' }} src={props.icon} />
          </View>
        </View>
      </View>
      <View style={styles.viewPage2}>
        <View>
          <Text style={{ fontFamily: 'Lato', fontWeight: 700, fontSize: '14px', textTransform: 'uppercase', color: '#000000D4' }}>financial impact</Text>
        </View>
        <View>
          <Text style={{ fontFamily: 'Lato', fontSize: '11px', color: '#666666', paddingTop: '15px' }}>CAPEX Increase</Text>
        </View>
        <View style={{ flexDirection: 'row', display: 'flex', paddingTop: '15px' }}>
          <View style={{ width: '50%' }}>
            <View
              style={{
                height: '24px',
                width: '240px',
                backgroundColor: '#F78D8D',
                borderTopRightRadius: '25px',
                borderBottomRightRadius: '25px',
              }}
            ></View>
          </View>
          <View style={{ paddingLeft: '20px', paddingTop: '3px' }}>
            <Text style={{ fontFamily: 'Lato', fontWeight: 'Bold', fontSize: '14px', color: '#F78D8D' }}>{rsToCr('Rs', barvalueOne)} Cr</Text>
          </View>
        </View>
        <View>
          <Text style={{ fontFamily: 'Lato', fontSize: '11px', color: '#666666', paddingTop: '15px' }}>Annual OPEX Savings</Text>
        </View>
        <View style={{ flexDirection: 'row', display: 'flex', paddingTop: '15px' }}>
          <View style={{ width: '50%' }}>
            <View
              style={{
                height: '24px',
                width: `${2.4 * (isNaN(calculatedBarvalueOne) ? 0 : calculatedBarvalueOne)}px`,
                backgroundColor: '#40E4CE',
                borderTopRightRadius: '25px',
                borderBottomRightRadius: '25px',
              }}
            ></View>
          </View>
          <View style={{ paddingLeft: '20px', paddingTop: '3px' }}>
            <Text style={{ fontFamily: 'Lato', fontWeight: 'Bold', fontSize: '14px', color: '#40E4CE' }}>{rsToCr('Rs', barvalueTwo)} Cr</Text>
          </View>
        </View>
        {/* new set */}
        <View style={{ paddingTop: '40px' }}>
          <Text style={{ fontFamily: 'Lato', fontWeight: 700, fontSize: '14px', textTransform: 'uppercase' }}>Resource Impact</Text>
        </View>
        <View>
          <Text style={{ fontFamily: 'Lato', fontSize: '11px', color: '#666666', paddingTop: '15px' }}>{props.title1}</Text>
        </View>
        <View style={{ flexDirection: 'row', display: 'flex', paddingTop: '15px' }}>
          <View style={{ width: '50%' }}>
            <View style={{ height: '24px', width: '240px', backgroundColor: '#F78D8D', borderTopRightRadius: '25px', borderBottomRightRadius: '25px' }}></View>
          </View>
          <View style={{ paddingLeft: '20px', paddingTop: '3px' }}>
            <Text style={{ fontFamily: 'Lato', fontWeight: 'Bold', fontSize: '14px', color: '#F78D8D' }}>{`${numberWithCommas(
              Math.round(barvalueThree)
            )} ${unit}`}</Text>
          </View>
        </View>
        <View>
          <Text style={{ fontFamily: 'Lato', fontSize: '11px', color: '#666666', paddingTop: '15px' }}>{props.title2}</Text>
        </View>
        <View style={{ flexDirection: 'row', display: 'flex', paddingTop: '15px' }}>
          <View style={{ width: '50%' }}>
            <View
              style={{
                height: '24px',
                width: `${2.4 * (isNaN(calculatedBarvalue2) ? 0 : calculatedBarvalue2)}px`,
                backgroundColor: '#40E4CE',
                borderTopRightRadius: '25px',
                borderBottomRightRadius: '25px',
              }}
            ></View>
          </View>
          <View style={{ paddingLeft: '20px', paddingTop: '3px' }}>
            <Text style={{ fontFamily: 'Lato', fontWeight: 'Bold', fontSize: '14px', color: '#40E4CE' }}>{`${numberWithCommas(
              Math.round(barvalueFour)
            )} ${unit}`}</Text>
          </View>
        </View>
        {/* new set */}
        <View style={{ flexDirection: 'row', display: 'flex', paddingTop: '45px' }}>
          <View style={{ width: '60%' }}>
            <Text style={{ fontFamily: 'Lato', fontWeight: 'Bold', fontSize: '24px' }}>{`${rsToCr('Rs', totalsaving)} Cr`}</Text>
          </View>
          <View>
            <Text style={{ fontFamily: 'Lato', fontWeight: 'Bold', fontSize: '24px' }}>{`${numberWithCommas(Math.round(totalResourceSavings))} ${unit}`}</Text>
          </View>
        </View>
        <View style={{ flexDirection: 'row', display: 'flex', paddingTop: '35px' }}>
          <View style={{ width: '50%' }}>
            <Text style={{ fontFamily: 'Lato', fontSize: '11px', color: '#000000D4' }}>
              {props.category === 'Energy'
                ? 'is the annual savings on electricity bills for the project'
                : props.category === 'Water'
                ? `Is the annual savings that would otherwise be spent to procure water for the project from external sources like water tankers`
                : props.category === 'Waste'
                ? 'Is the annual savings for the project, with reduced waste sent to landfills'
                : props.category === 'Materials'
                ? 'Is the annual savings for the project, with reduced maintenance cost'
                : ''}
            </Text>
          </View>
          <View style={{ width: '10%' }} />
          <View style={{ width: '40%' }}>
            <ResouceSavingText />
          </View>
        </View>
      </View>
      <View style={styles.footerMargin}>
        <Footer year={props.year} />
      </View>
    </View>
  );
};

export default CategoryReportBarGraphPage;
