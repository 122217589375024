import React from 'react';
import { Typography, Grid } from '@material-ui/core';
import useStyles from './styles';
import { useInterventionDetailsContext } from '../context/InterventionDetails';

const IndoorFlooringUnBProjectInputs = (props) => {
  const classes = useStyles();
  const {
    schema,
    get: { getQuantityValue, getQuantityUnit },
  } = useInterventionDetailsContext();
  let subCategory = getQuantityValue(schema.indoorFlooringUnB_subcategory);
  return (
    <Grid container item xs={12} direction="column">
      <Typography className={classes.textHeader1}>Project Inputs</Typography>
      {subCategory !== 'villa' && (
        <>
          <Grid container item xs={12} style={{ paddingTop: '1%' }}>
            <Grid item xs>
              <Typography className={classes.textHeader5}>Studio</Typography>
              <Typography className={classes.textHeader3}>
                {getQuantityValue(schema.indoorFlooringUnB_studio)} {getQuantityUnit(schema.indoorFlooringUnB_studio)}
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography className={classes.textHeader5}>1 BHK</Typography>
              <Typography className={classes.textHeader3}>
                {getQuantityValue(schema.indoorFlooringUnB_oneBHK)} {getQuantityUnit(schema.indoorFlooringUnB_oneBHK)}
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography className={classes.textHeader5}>2 BHK</Typography>
              <Typography className={classes.textHeader3}>
                {getQuantityValue(schema.indoorFlooringUnB_twoBHK)} {getQuantityUnit(schema.indoorFlooringUnB_twoBHK)}
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography className={classes.textHeader5}>3 BHK</Typography>
              <Typography className={classes.textHeader3}>
                {getQuantityValue(schema.indoorFlooringUnB_threeBHK)} {getQuantityUnit(schema.indoorFlooringUnB_threeBHK)}
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography className={classes.textHeader5}>4 BHK</Typography>
              <Typography className={classes.textHeader3}>
                {getQuantityValue(schema.indoorFlooringUnB_fourBHK)} {getQuantityUnit(schema.indoorFlooringUnB_fourBHK)}
              </Typography>
            </Grid>
          </Grid>
          <Grid container item xs={12} style={{ paddingTop: '1%' }}>
            <Grid item xs>
              <Typography className={classes.textHeader5}>Studio Carpet Area</Typography>
              <Typography className={classes.textHeader3}>
                {getQuantityValue(schema.indoorFlooringUnB_studioCarpetArea)} {getQuantityUnit(schema.indoorFlooringUnB_studioCarpetArea)}
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography className={classes.textHeader5}>1 BHK Carpet Area</Typography>
              <Typography className={classes.textHeader3}>
                {getQuantityValue(schema.indoorFlooringUnB_oneBhkCarpetArea)} {getQuantityUnit(schema.indoorFlooringUnB_oneBhkCarpetArea)}
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography className={classes.textHeader5}>2 BHK Carpet Area</Typography>
              <Typography className={classes.textHeader3}>
                {getQuantityValue(schema.indoorFlooringUnB_twoBhkCarpetArea)} {getQuantityUnit(schema.indoorFlooringUnB_twoBhkCarpetArea)}
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography className={classes.textHeader5}>3 BHK Carpet Area</Typography>
              <Typography className={classes.textHeader3}>
                {getQuantityValue(schema.indoorFlooringUnB_threeBhkCarpetArea)} {getQuantityUnit(schema.indoorFlooringUnB_threeBhkCarpetArea)}
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography className={classes.textHeader5}>4 BHK Carpet Area</Typography>
              <Typography className={classes.textHeader3}>
                {getQuantityValue(schema.indoorFlooringUnB_fourBhkCarpetArea)} {getQuantityUnit(schema.indoorFlooringUnB_fourBhkCarpetArea)}
              </Typography>
            </Grid>
          </Grid>
          {/* Penthouse */}
          <Grid container item xs={12} style={{ paddingTop: '1%' }}>
            <Grid item xs>
              <Typography className={classes.textHeader5}>PH 1 BHK</Typography>
              <Typography className={classes.textHeader3}>
                {getQuantityValue(schema.indoorFlooringUnB_penthouseOneBHK)} {getQuantityUnit(schema.indoorFlooringUnB_penthouseOneBHK)}
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography className={classes.textHeader5}>PH 2 BHK</Typography>
              <Typography className={classes.textHeader3}>
                {getQuantityValue(schema.indoorFlooringUnB_penthouseTwoBHK)} {getQuantityUnit(schema.indoorFlooringUnB_penthouseTwoBHK)}
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography className={classes.textHeader5}>PH 3 BHK</Typography>
              <Typography className={classes.textHeader3}>
                {getQuantityValue(schema.indoorFlooringUnB_penthouseThreeBHK)} {getQuantityUnit(schema.indoorFlooringUnB_penthouseThreeBHK)}
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography className={classes.textHeader5}>PH 4 BHK</Typography>
              <Typography className={classes.textHeader3}>
                {getQuantityValue(schema.indoorFlooringUnB_penthouseFourBHK)} {getQuantityUnit(schema.indoorFlooringUnB_penthouseFourBHK)}
              </Typography>
            </Grid>
          </Grid>
          <Grid container item xs={12} style={{ paddingTop: '1%' }}>
            <Grid item xs>
              <Typography className={classes.textHeader5}>PH 1 BHK Carpet Area</Typography>
              <Typography className={classes.textHeader3}>
                {getQuantityValue(schema.indoorFlooringUnB_penthouseOneBhkCarpetArea)} {getQuantityUnit(schema.indoorFlooringUnB_penthouseOneBhkCarpetArea)}
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography className={classes.textHeader5}>PH 2 BHK Carpet Area</Typography>
              <Typography className={classes.textHeader3}>
                {getQuantityValue(schema.indoorFlooringUnB_penthouseTwoBhkCarpetArea)} {getQuantityUnit(schema.indoorFlooringUnB_penthouseTwoBhkCarpetArea)}
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography className={classes.textHeader5}>PH 3 BHK Carpet Area</Typography>
              <Typography className={classes.textHeader3}>
                {getQuantityValue(schema.indoorFlooringUnB_penthouseThreeBhkCarpetArea)} {getQuantityUnit(schema.indoorFlooringUnB_penthouseThreeBhkCarpetArea)}
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography className={classes.textHeader5}>PH 4 BHK Carpet Area</Typography>
              <Typography className={classes.textHeader3}>
                {getQuantityValue(schema.indoorFlooringUnB_penthouseFourBhkCarpetArea)} {getQuantityUnit(schema.indoorFlooringUnB_penthouseFourBhkCarpetArea)}
              </Typography>
            </Grid>
          </Grid>
        </>
      )}
      {/* Villa */}
      {subCategory && subCategory === 'villa' && (
        <>
          <Grid container item xs={12} style={{ paddingTop: '1%' }}>
            <Grid item xs>
              <Typography className={classes.textHeader5}>1 BHK</Typography>
              <Typography className={classes.textHeader3}>
                {getQuantityValue(schema.indoorFlooringUnB_villaOneBHK)} {getQuantityUnit(schema.indoorFlooringUnB_villaOneBHK)}
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography className={classes.textHeader5}>2 BHK</Typography>
              <Typography className={classes.textHeader3}>
                {getQuantityValue(schema.indoorFlooringUnB_villaTwoBHK)} {getQuantityUnit(schema.indoorFlooringUnB_villaTwoBHK)}
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography className={classes.textHeader5}>3 BHK</Typography>
              <Typography className={classes.textHeader3}>
                {getQuantityValue(schema.indoorFlooringUnB_villaThreeBHK)} {getQuantityUnit(schema.indoorFlooringUnB_villaThreeBHK)}
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography className={classes.textHeader5}>4 BHK</Typography>
              <Typography className={classes.textHeader3}>
                {getQuantityValue(schema.indoorFlooringUnB_villaFourBHK)} {getQuantityUnit(schema.indoorFlooringUnB_villaFourBHK)}
              </Typography>
            </Grid>
          </Grid>
          <Grid container item xs={12} style={{ paddingTop: '1%' }}>
            <Grid item xs>
              <Typography className={classes.textHeader5}>1 BHK Carpet Area</Typography>
              <Typography className={classes.textHeader3}>
                {getQuantityValue(schema.indoorFlooringUnB_villaOneBHKCarpetArea)} {getQuantityUnit(schema.indoorFlooringUnB_villaOneBHKCarpetArea)}
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography className={classes.textHeader5}>2 BHK Carpet Area</Typography>
              <Typography className={classes.textHeader3}>
                {getQuantityValue(schema.indoorFlooringUnB_villaTwoBHKCarpetArea)} {getQuantityUnit(schema.indoorFlooringUnB_villaTwoBHKCarpetArea)}
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography className={classes.textHeader5}>3 BHK Carpet Area</Typography>
              <Typography className={classes.textHeader3}>
                {getQuantityValue(schema.indoorFlooringUnB_villaThreeBHKCarpetArea)} {getQuantityUnit(schema.indoorFlooringUnB_villaThreeBHKCarpetArea)}
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography className={classes.textHeader5}>4 BHK Carpet Area</Typography>
              <Typography className={classes.textHeader3}>
                {getQuantityValue(schema.indoorFlooringUnB_villaFourBHKCarpetArea)} {getQuantityUnit(schema.indoorFlooringUnB_villaFourBHKCarpetArea)}
              </Typography>
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  );
};
export default IndoorFlooringUnBProjectInputs;
