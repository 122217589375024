import React, { useEffect, useState, useContext } from 'react';
import { Document, Page, StyleSheet, PDFViewer, Font } from '@react-pdf/renderer';
import axios from '../../../../../config/Axios';
import OpenSansBold from '../../../../fonts/OpenSans-Bold.ttf';
import OpenSansRegular from '../../../../fonts/OpenSans-Regular.ttf';
// import OpenSansExtrabold from '../../../../fonts/OpenSans-ExtraBold.ttf';
import OpenSansSemiBold from '../../../../fonts/OpenSans-SemiBold.ttf';
import { UserContext } from '../../../../../routing/IndexRouting';
import { ProjectContext } from '../../../../../routing/IndexRouting';
// import { InterventionMappingContext } from '../../../../../projectRouting/Index';

import HeadingPage from './HeadingPage';
import ExecutiveReportPage from './ExecutiveReportPage';
import EnvironmentalImpactPage from './EnvironmentalImpactPage';
import Page4 from './Page4';
import Page5 from './Page5';
import EmptyPage from './EmptyPage';
import Page6 from './Page6';
import Page7 from './Page7';

// import dummyenrgylogo from '../../../../../assets/new-pdf/dummyenrgylogo.png';
import EnergyRounded from '../../../../../assets/new-pdf/EnergyRounded.png';
import WaterRounded from '../../../../../assets/new-pdf/WaterRounded.png';
import MaterialsIcon from '../../../../../assets/new-pdf/MaterialsIcon.png';
import WaterIcon from '../../../../../assets/new-pdf/WaterIcon.png';
import energyIcon from '../../../../../assets/new-pdf/dummyenrgylogo.png';
import WasteIcon from '../../../../../assets/new-pdf/WasteIcon.png';
import WasteRounded from '../../../../../assets/new-pdf/WasteRounded.png';

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#FFFFFF',
  },
  viewer: {
    width: window.innerWidth - 8, //the pdf viewer will take up all of the width and height
    height: window.innerHeight - 8,
  },
});

Font.register({
  family: 'Open Sans',
  fonts: [
    {
      src: OpenSansRegular,
    },
    {
      src: OpenSansBold,
    },
    {
      src: OpenSansSemiBold,
    },
  ],
});
const PDFFile = (props) => {
  // let projectId = props.match.params.id;
  // const [userprofile, setUserprofile] = useState('');
  const { userDetails } = useContext(UserContext);
  const { projectData } = useContext(ProjectContext);
  // const { interventionMappingContext, handleInterventionMapping } = useContext(InterventionMappingContext);
  const [asset, setAsset] = useState('');

  useEffect(() => {
    let id = userDetails.organisation.payloadId;
    axios.get(`/sign-s3/${id}`).then((response) => {
      if (response.data.data) {
        setAsset(response.data.data.data);
      }
    });
  }, []);

  let projectId = props.match.params.id;

  const [projectdata, setProjectData] = React.useState('');
  const [date, setDate] = React.useState('');
  const [ProjectinterventionMappingData, setprojectInterventionMappingData] = useState('');
  const [allVendorMapping, setAllvendorMappings] = useState('');
  const [allvendors, setAllvendors] = useState('');

  const [waterEnabledGrouped, setWaterEnabledGrouped] = useState('');
  const [EnergyEnabledGrouped, setEnergyEnabledGrouped] = useState('');
  const [WasteEnabledGrouped, setWasteEnabledGrouped] = useState('');
  const [year, setYear] = useState('');
  const [numnber, SetNumber] = useState('');
  const [waternumber, setWaterNumber] = useState('');
  const [wateruncategorized, setUncategorizedWaterinterv] = useState('');
  const [EnergyunCategorized, setEnergyuncategorized] = useState('');
  const [wasteunCategorized, setWasteunCategorized] = useState('');
  const [energyNumber, setEnergyNumber] = useState('');
  useEffect(() => {
    axios.get(`/projectv2/${projectId}?NumberOfYears=1`).then((response) => {
      axios.get(`/ProjectInterventionMapping/project/${projectId}`).then((res) => {
        if (response.data && response.data.data && response.data.data.data) setProjectData(response.data.data.data);
        if (res) setprojectInterventionMappingData(res.data.data.data);
      });
    });
    let today = new Date();
    let dd = String(today.getDate()).padStart(2, '0');
    let mm = String(today.getMonth() + 1).padStart(2, '0');
    let yyyy = today.getFullYear();
    today = dd + '/' + mm + '/' + yyyy;
    setDate(today);
    setYear(yyyy);
  }, [projectId]);

  useEffect(() => {
    axios.get(`/interventionVendorMapping`).then((response) => {
      axios.get(`/vendorsv2`).then((res) => {
        if (response) setAllvendorMappings(response.data.data.data);
        if (res) setAllvendors(res.data.data.data);
      });
    });
  }, []);

  // flow diagram data
  let energyflowdiagram = projectdata && projectdata.interventions.categorized.find((interv) => interv && interv.name === 'Energy').quantities.flowDiagram;
  let waterflowdiagram = projectdata && projectdata.interventions.categorized.find((interv) => interv && interv.name === 'Water').quantities.flowDiagram;
  let wasteflowdiagram = projectdata && projectdata.interventions.categorized.find((interv) => interv && interv.name === 'Waste').quantities.flowDiagram;

  // for water interventions generate based on enabled values
  const WaterData = projectdata && projectdata.interventions.categorized.find((data) => data && data.name === 'Water');
  useEffect(() => {
    let arr = [];
    let n = 3;
    if (WaterData)
      WaterData &&
        WaterData.subcategories.filter((subcategory) => {
          subcategory.interventions &&
            subcategory.interventions
              .sort((a, b) =>
                a.name === 'Centralized RO' ||
                (a.name === 'Grey Water Re-use' && b.name === 'Black Water Re-use') ||
                (a.name === 'Rooftop RWH' && b.name === 'Surface RWH')
                  ? -1
                  : b.name === 'Dual Flush'
                  ? -1
                  : 0
              )
              .sort((a, b) => (b.name === 'Smart Water Meters' ? -1 : 0));
          subcategory.interventions &&
            subcategory.interventions.filter((intervention) => intervention && intervention.enabled === true && arr.push(intervention));
        });

    if (arr && arr.length > 0) {
      let groups = arr.reduce((r, e, i) => (i % n ? r[r.length - 1].push(e) : r.push([e])) && r, []);
      setWaterEnabledGrouped(groups && groups);
      setWaterNumber(arr.length);
      setUncategorizedWaterinterv(arr);
    }
  }, [WaterData]);

  const EnergyData = projectdata && projectdata.interventions.categorized.find((data) => data && data.name === 'Energy');
  const materialsData = projectdata && projectdata.interventions.categorized.find((data) => data && data.name === 'Materials');
  useEffect(() => {
    let arr = [];
    let n = 3;
    if (EnergyData)
      EnergyData &&
        EnergyData.subcategories.filter((subcategory) =>
          subcategory.interventions.filter((intervention) => intervention.enabled === true && arr.push(intervention))
        );
    if (arr && arr.length > 0) {
      let groups = arr.reduce((r, e, i) => (i % n ? r[r.length - 1].push(e) : r.push([e])) && r, []);
      setEnergyEnabledGrouped(groups && groups);
      setEnergyNumber(arr.length);
      setEnergyuncategorized(arr);
    }
  }, [EnergyData]);

  const WasteData = projectdata && projectdata.interventions.categorized.find((data) => data && data.name === 'Waste');

  useEffect(() => {
    let arr = [];
    let n = 3;
    if (WasteData)
      WasteData &&
        WasteData.subcategories.filter((subcategory) =>
          subcategory.interventions.filter((intervention) => intervention && intervention.enabled === true && arr.push(intervention))
        );
    if (arr && arr.length > 0) {
      let groups = arr.reduce((r, e, i) => (i % n ? r[r.length - 1].push(e) : r.push([e])) && r, []);
      setWasteEnabledGrouped(groups && groups);
      SetNumber(arr.length);
      setWasteunCategorized(arr);
    }
  }, [WasteData]);
  return (
    <PDFViewer style={styles.viewer}>
      <Document>
        <Page size="A4" style={styles.page}>
          <HeadingPage asset={asset} projectname={projectdata && projectdata.name} date={date} />
        </Page>
        <Page size="A4" style={styles.page}>
          <ExecutiveReportPage
            sustainabilitypercentage={
              projectdata
                ? projectdata.sustainabilityLevel && projectdata.sustainabilityLevel.value === 1
                  ? '25%'
                  : projectdata.sustainabilityLevel.value === 2
                  ? '50%'
                  : projectdata.sustainabilityLevel.value === 3
                  ? '75%'
                  : projectdata.sustainabilityLevel.value === 4
                  ? '100%'
                  : '0%'
                : '0%'
            }
            companyname={projectdata.name}
            data={projectdata}
            year={year}
          />
        </Page>
        <Page size="A4" style={styles.page}>
          <EnvironmentalImpactPage
            companyname={projectdata && projectdata.name}
            energy={projectdata && projectdata.interventions.categorized.filter((data) => data.name === 'Energy')}
            water={projectdata && projectdata.interventions.categorized.filter((data) => data.name === 'Water')}
            waste={projectdata && projectdata.interventions.categorized.filter((data) => data.name === 'Waste')}
            materials={materialsData && [materialsData]}
            year={year}
          />
        </Page>
        <Page size="A4" style={styles.page}>
          <Page4
            heading="Energy"
            category="Energy"
            icon={EnergyRounded}
            companyname={projectdata && projectdata.name}
            data={projectdata && projectdata.interventions.categorized.filter((data) => data.name === 'Energy')}
            let
            percentage={
              projectdata &&
              projectdata.interventions.categorized
                .filter((data) => data.name === 'Energy')[0]
                .subcategories.find((ele) => ele.name === 'Renewable Sources')
                .interventions[0].quantities.details.find((data) => data.name === 'energy.totalpercentagesaving')
            }
            year={year}
            title1={'Base Case Energy consumption'}
            title2={'Sustainable Case Energy consumption'}
          />
        </Page>
        <Page size="A4" style={styles.page}>
          <Page5
            companyname={projectdata && projectdata.name}
            totalressaving={projectdata && projectdata.interventions?.categorized?.filter((data) => data.name === 'Energy')[0]?.quantities?.resource?.category}
            energyaftersavings={
              projectdata &&
              projectdata.interventions?.categorized
                ?.filter((el) => el.name == 'Energy')[0]
                .subcategories[0]?.interventions[0]?.quantities?.details?.filter((el) => el.name == 'energy.energyaftersavings')[0]
            }
            category="Energy"
            border="0.5625px solid #9E9E9E"
            color="#F28D14"
            headingIcon={EnergyRounded}
            dummyicon={energyIcon}
            year={year}
            projectdata={projectdata && projectdata}
          />
        </Page>
        <Page size="A4" style={styles.page}>
          <Page6
            companyname={projectdata && projectdata.name}
            ProjectinterventionMappingData={ProjectinterventionMappingData && ProjectinterventionMappingData}
            allVendorMappings={allVendorMapping && allVendorMapping}
            allvendors={allvendors && allvendors}
            EnabledGrouped={EnergyEnabledGrouped && EnergyEnabledGrouped}
            border="1px solid #F28D14"
            color="#F28D14"
            year={year}
            category="Water"
            uncategorized={EnergyunCategorized}
            numnber={energyNumber}
          />
        </Page>
        <Page size="A4" style={styles.page}>
          <Page7
            companyname={projectdata && projectdata.name}
            type="energy"
            mainGrid={`${energyflowdiagram && energyflowdiagram.left.mainGrid.value}`}
            residentiallighting={`${energyflowdiagram && energyflowdiagram.right.residentialLighting.value}`}
            residentialhvac={`${energyflowdiagram && energyflowdiagram.right.residentialHvac.value}`}
            residentialfan={`${energyflowdiagram && energyflowdiagram.right.residentialFan.value}`}
            residentialhotwater={`${energyflowdiagram && energyflowdiagram.right.residentialHotWater.value}`}
            homeappliances={`${energyflowdiagram && energyflowdiagram.right.homeAppliances.value}`}
            commonarea={`${energyflowdiagram && energyflowdiagram.right.commonArea.value}`}
            dieselgenerator={`${energyflowdiagram && energyflowdiagram.left.dieselGenerator.value}`}
            solarweaterheater={`${energyflowdiagram && energyflowdiagram.left.solarWaterHeater.value}`}
            solarpv={`${energyflowdiagram && energyflowdiagram.left.solarPv.value}`}
            biogas={`${energyflowdiagram && energyflowdiagram.left.biogas.value}`}
            maingridtohotwater={`${energyflowdiagram && energyflowdiagram.connections[0].value.value}`}
            maingridtolighting={`${energyflowdiagram && energyflowdiagram.connections[1].value.value}`}
            dieslgeneratortolighting={`${energyflowdiagram && energyflowdiagram.connections[5].value.value}`}
            dieselgeneratortoappliances={`${energyflowdiagram && energyflowdiagram.connections[7].value.value}`}
            dieslgeneratortofan={`${energyflowdiagram && energyflowdiagram.connections[6].value.value}`}
            dieslgeneratortocommonarea="NA"
            maingridtoresidentialfan={`${energyflowdiagram && energyflowdiagram.connections[2].value.value}`}
            maingridtohomeappliances={`${energyflowdiagram && energyflowdiagram.connections[3].value.value}`}
            maingridtocommonarea="NA"
            maingridtohvac={`${energyflowdiagram && energyflowdiagram.connections[4].value.value}`}
            buiogastocommonarea={`${energyflowdiagram && energyflowdiagram.connections[10].value.value}`}
            solarwaterheatertoresidentialhotwater={`${energyflowdiagram && energyflowdiagram.connections[8].value.value}`}
            solarpvtocommonarea={`${energyflowdiagram && energyflowdiagram.connections[9].value.value}`}
            year={year}
          />
        </Page>
        <Page size="A4" style={styles.page}>
          <Page4
            category="Water"
            heading="Water"
            icon={WaterRounded}
            companyname={projectdata && projectdata.name}
            data={projectdata && projectdata.interventions.categorized.filter((data) => data.name === 'Water')}
            let
            percentage={
              projectdata &&
              projectdata.interventions.categorized
                .filter((data) => data.name === 'Water')[0]
                .subcategories.find((ele) => ele.name === 'Demand Optimization')
                .interventions[0].quantities.details.find((data) => data.name === 'water.totalpercentagesaving')
            }
            year={year}
            title1={'Base Case Water Consumption'}
            title2={'Sustainable Case Water Consumpion'}
          />
        </Page>
        <Page size="A4" style={styles.page}>
          <Page5
            companyname={projectdata && projectdata.name}
            totalressaving={projectdata && projectdata.interventions.categorized.filter((data) => data.name === 'Water')[0].quantities.resource.category}
            category="Water"
            year={year}
            border="0.5625px solid #9E9E9E"
            color="#3BC7EE"
            headingIcon={WaterRounded}
            dummyicon={WaterIcon}
            projectdata={projectdata && projectdata}
          />
        </Page>
        {/* <Page size="A4" style={styles.page}>
          <EmptyPage
            data={projectdata && projectdata.interventions.categorized.filter((data) => data.name === 'Water')}
            border="0.5625px solid #9E9E9E"
            color="#3BC7EE"
            dummyicon={WaterIcon}
          />
        </Page> */}
        <Page size="A4" style={styles.page}>
          <Page6
            companyname={projectdata && projectdata.name}
            ProjectinterventionMappingData={ProjectinterventionMappingData && ProjectinterventionMappingData}
            allVendorMappings={allVendorMapping && allVendorMapping}
            allvendors={allvendors && allvendors}
            EnabledGrouped={waterEnabledGrouped && waterEnabledGrouped}
            year={year}
            border="1px solid #3BC7EE"
            color="#3BC7EE"
            category="Water"
            numnber={waternumber}
            uncategorized={wateruncategorized}
          />
        </Page>
        <Page size="A4" style={styles.page}>
          <Page7
            type="water"
            companyname={projectdata && projectdata.name}
            year={year}
            TreatedGreyWater={waterflowdiagram && waterflowdiagram.left.treatedGreyWater.value}
            FreshWater={waterflowdiagram && waterflowdiagram.left.freshWater.value}
            CentralizedRo={waterflowdiagram && waterflowdiagram.left.centralizedRO.value}
            WTP={waterflowdiagram && waterflowdiagram.left.wtp.value}
            CommonAreaUse={waterflowdiagram && waterflowdiagram.right.commonAreaUse.value}
            DomesticWaterUse={waterflowdiagram && waterflowdiagram.right.domesticWaterUse.value}
            HealthFaucet={waterflowdiagram && waterflowdiagram.right.healthFaucet.value}
            Flushing={waterflowdiagram && waterflowdiagram.right.flushing.value}
            Drinking={waterflowdiagram && waterflowdiagram.right.drinking.value}
            GreyWaterSTP={waterflowdiagram && waterflowdiagram.right.greyWaterStp.value}
            BlackWaterSTP={waterflowdiagram && waterflowdiagram.right.blackWaterStp.value}
          />
        </Page>
        <Page size="A4" style={styles.page}>
          <Page4
            category="Waste"
            companyname={projectdata && projectdata.name}
            data={projectdata && projectdata.interventions.categorized.filter((data) => data.name === 'Waste')}
            percentage={
              projectdata &&
              projectdata.interventions.categorized
                .filter((data) => data.name === 'Waste')[0]
                .subcategories.find((ele) => ele.name === 'Management')
                .interventions[0].quantities.details.find((data) => data.name === 'waste.totalpercentagesaving')
            }
            year={year}
            title1={'Base Case Waste Generated'}
            title2={'Sustainable Case Waste Generated'}
            heading="Waste"
            icon={WasteRounded}
          />
        </Page>
        <Page size="A4" style={styles.page}>
          <Page5
            companyname={projectdata && projectdata.name}
            totalressaving={projectdata && projectdata.interventions.categorized.filter((data) => data.name === 'Waste')[0].quantities.resource.category}
            category="Waste"
            year={year}
            border="0.5625px solid #9E9E9E"
            color="#BC8A5F"
            headingIcon={WasteRounded}
            dummyicon={WasteIcon}
            projectdata={projectdata && projectdata}
          />
        </Page>
        <Page size="A4" style={styles.page}>
          <Page6
            companyname={projectdata && projectdata.name}
            ProjectinterventionMappingData={ProjectinterventionMappingData && ProjectinterventionMappingData}
            allVendorMappings={allVendorMapping && allVendorMapping}
            allvendors={allvendors && allvendors}
            EnabledGrouped={WasteEnabledGrouped && WasteEnabledGrouped}
            year={year}
            category="Waste"
            border="1px solid #BC8A5F"
            color="#BC8A5F"
            numnber={numnber}
            uncategorized={wasteunCategorized}
          />
        </Page>
        <Page size="A4" style={styles.page}>
          <Page7
            rejectwaste={wasteflowdiagram && wasteflowdiagram.left.RejectWaste.value}
            sanitarywaste={wasteflowdiagram && wasteflowdiagram.left.SanitaryWaste.value}
            medicalwaste={wasteflowdiagram && wasteflowdiagram.left.MedicalWaste.value}
            ewaste={wasteflowdiagram && wasteflowdiagram.left.E_Waste.value}
            drywaste={wasteflowdiagram && wasteflowdiagram.left.DryWaste.value}
            organicwaste={wasteflowdiagram && wasteflowdiagram.left.OrganicWaste.value}
            type="waste"
            companyname={projectdata && projectdata.name}
            year={year}
          />
        </Page>
        <Page size="A4" style={styles.page}>
          <Page4
            heading="Materials"
            category="Materials"
            icon={MaterialsIcon}
            companyname={projectdata && projectdata.name}
            data={materialsData && [materialsData]}
            let
            percentage={0}
            year={year}
            title1={'Base Case Materials generated'}
            title2={'Sustainable Case Materials generated'}
          />
        </Page>
      </Document>
    </PDFViewer>
  );
};
export default PDFFile;
