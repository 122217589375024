import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  bold: {
    fontFamily: 'Lato',
    fontWeight: 700,
  },
  light: {
    fontFamily: 'Lato',
    fontWeight: 300,
    marginLeft: '3px',
  },
}));

export default function Bold({ value, unit = '', variant = 'h6', isHighlight = false }) {
  const classes = useStyles();
  return (
    <Typography variant={variant} style={value < 0 && isHighlight ? { color: '#26E0A3' } : {}} className={classes.bold}>
      {value} <span className={classes.light}>{unit}</span>
    </Typography>
  );
}
