import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  textHeader1: {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '19.2px',
    letterSpacing: '0.71px',
    color: 'rgba(46, 46, 46, 1)',
    textAlign: 'left',
    display: 'flex',
    alignItems: 'center',
  },
  nonselectedCheckbox: {
    color: 'rgba(102, 227, 190, 1)',
    fontSize: '25px',
    marginRight: '10px',
    cursor: 'pointer',
  },
  selectedCheckbox: {
    color: 'rgba(102, 227, 190, 1)',
    fontSize: '25px',
    marginRight: '10px',
    cursor: 'pointer',
  },
  textheader2: {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '19px',
    letterSpacing: '0.7px',
    color: 'rgba(46, 46, 46, 1)',
    paddingTop: '10%',
  },
  nonselectedRadiobox: {
    // color: "rgba(102, 227, 190, 1)",
    // fontSize: "25px",
    marginRight: '10px',
    cursor: 'pointer',
  },
  selectedRadiobox: {
    color: 'rgba(102, 227, 190, 1)',
    // fontSize: "25px",
    marginRight: '10px',
    cursor: 'pointer',
  },
  textHeader3: {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '24px',
    letterSpacing: '0.71px',
    textAlign: 'left',
    display: 'flex',
    alignItems: 'center',
    paddingTop: '10px',
  },
  textHeader3disabled: {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '24px',
    letterSpacing: '0.71px',
    textAlign: 'left',
    display: 'flex',
    alignItems: 'center',
    paddingTop: '10px',
    color: 'rgba(158, 158, 158, 1)',
  },
  textHeader4: {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '19px',
    letterSpacing: '0.71px',
    paddingTop: '10px',
    color: 'rgba(102, 102, 102, 1)',
  },
  textconstants: {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '32px',
    letterSpacing: '0.71px',
    // paddingTop: "10px",
    color: 'rgba(46, 46, 46, 1)',
  },
  textHeader5: {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '24px',
    letterSpacing: '0.71px',
    textAlign: 'left',
    display: 'flex',
    alignItems: 'center',
    paddingTop: '10px',
    color: 'rgba(102, 102, 102, 1)',
  },

  nonselectedRadioboxdisabled: {
    color: 'rgba(158, 158, 158, 1)',
    marginRight: '10px',
  },
  boxStyleSelected: {
    width: '79px',
    height: '28px',
    background: '#66E3BE',
    borderRadius: '20px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: 4,
    cursor: 'pointer',
  },
  boxStyleNonselected: {
    width: '79px',
    height: '28px',
    background: '#fffff',
    borderRadius: '20px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: 4,
    border: '1px solid #C6F2CD',
    cursor: 'pointer',
  },
  pillTextSelected: {
    fontFamily: 'Lato',
    fontSize: '12px',
    fontWeight: 700,
    lineHeight: '14.4px',
    letterSpacing: '1px',
    textAlign: 'center',
    color: '#FFFFFF',
  },
  pillTextNonSelected: {
    fontFamily: 'Lato',
    fontSize: '12px',
    fontWeight: 700,
    lineHeight: '14.4px',
    letterSpacing: '1px',
    textAlign: 'center',
    textUnderlinePosition: 'from-font',
    textDecorationSkipInk: 'none',
    color: '#666666',
  },
  mandateText: {
    fontFamily: 'Lato',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '0.71px',
    // textAlign: 'left',
    textUnderlinePosition: 'from-font',
    textDecorationSkipInk: 'none',
    textAlign: 'justify',
  },
  infoStyle: {
    color: '#F3FCFA',
    background: '#26E0A3',
    borderRadius: '50%',
    border: 'none',
    marginRight: '10px',
    marginTop: '2px',
  },
  mandateBox: {
    border: '2px solid #49DDB1',
    borderRadius: '10px',
    background: '#F3FCFA',
    padding: 4,
    marginTop: 20,
  },
  customTextField: {
    '& .MuiOutlinedInput-root': {
      border: 'none',
      borderRadius: 0,
      borderBottom: '1px solid #FFFFFF',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '& .MuiOutlinedInput-input': {
      padding: '4px 5px',
      color: '#FFFFFF',
      fontWeight: 500,
    },
  },
  whiteAdornment: {
    color: '#FFFFFF',
    fontWeight: 500,
  },
}));

export default useStyles;
