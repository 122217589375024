import React from 'react';
import { Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import Header from './Header';
import Footer from './Footer';
import IntervDataCard from './IntervDataCard';
import numberWithCommas from '../../../../../utils/numberToComma';
import { getAnnualBaselineConsumption, percentChange } from './ReportUtils';
import { categoryWiseInterventionSummary } from './CategoryWiseSummaryPageUtils';

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#FFFFFF',
  },
  viewPage2: {
    margin: '10px 20px 20px 20px',
    flexGrow: 1,
  },
  pageCover: {
    height: '100%',
    width: '521px',
    paddingTop: '20px',
  },
  footerMargin: {
    padding: 10,
  },
});

const CategoryWiseSummaryPage = (props) => {
  const { interventionData, categorisedReportData } = props;
  const totalLPCDPreSdplus = props.totalLPCDPreSdplus;
  const totalLPCDPostSdplus = props.totalLPCDPostSdplus;

  const energyBefore = getAnnualBaselineConsumption(interventionData, 'Energy');
  const wasteBefore = getAnnualBaselineConsumption(interventionData, 'Waste');
  const waterBefore = getAnnualBaselineConsumption(interventionData, 'Water');
  const materialsBefore = getAnnualBaselineConsumption(interventionData, 'Materials');

  const overallEnergy = props?.totalResourceSavings && energyBefore ? energyBefore?.value - props?.totalResourceSavings?.value : 0;
  const overallWater = props?.totalResourceSavings && waterBefore ? waterBefore?.value - props?.totalResourceSavings?.value : 0;
  const overallWaste = props?.totalResourceSavings && wasteBefore ? wasteBefore?.value - props?.totalResourceSavings?.value : 0;
  const overallMaterials = props?.totalResourceSavings && materialsBefore ? materialsBefore?.value - props?.totalResourceSavings?.value : 0;
  return (
    <View>
      <View style={{ backgroundColor: '#15B7B9', height: '201px' }}>
        <View style={{ padding: '30px 20px 10px 20px' }}>
          <Header companyname={props.companyname} />
        </View>
        <View style={{ flexDirection: 'row', display: 'flex', marginTop: '10px' }}>
          <View style={{ width: '5%' }} />
          <View style={{ paddingTop: '5px', width: '50%' }}>
            <Text
              style={{
                fontFamily: 'Lato',
                fontWeight: 700,
                fontSize: props?.category !== 'Materials' ? '30px' : '25px',
                textTransform: 'uppercase',
              }}
            >{`SD+ ${props.category} impact`}</Text>
            <Text
              style={{
                fontFamily: 'Lato',
                fontWeight: 700,
                fontSize: props?.category !== 'Materials' ? '30px' : '25px',
                textTransform: 'uppercase',
              }}
            >
              Summary
            </Text>
            <Text style={{ fontFamily: 'Lato', fontSize: '20px', color: '#FFFFFF', paddingTop: '15px' }}>{`${numberWithCommas(
              Math.round(props.totalResourceSavings && props.totalResourceSavings.value)
            )} ${props.totalResourceSavings && props.totalResourceSavings.unit} Annual Savings`}</Text>
          </View>
          <View style={{ width: '20%' }} />
          <View style={{ width: '20%' }}>
            <Image style={{ height: '100px', width: '100px' }} src={props.headingIcon} />
          </View>
        </View>
      </View>
      <View style={styles.viewPage2}>
        <View
          style={{
            border: '1px solid #9E9E9E',
            height: props.category !== 'Water' ? '60px' : '84px',
            flexDirection: 'row',
            display: 'flex',
            marginTop: '10px',
            borderRadius: '10px',
          }}
        >
          <View style={{ paddingTop: '35px', paddingLeft: '10px', width: '40%' }}>
            {props.category === 'Energy' && (
              <Text style={{ color: '#666666', fontFamily: 'Lato', fontSize: '9px', fontWeight: 400 }}>Overall Energy Consumption</Text>
            )}
            {props.category === 'Waste' && (
              <Text style={{ color: '#666666', fontFamily: 'Lato', fontSize: '9px', fontWeight: 400 }}>Overall Waste Generated</Text>
            )}
            {props.category === 'Water' && (
              <>
                <Text style={{ color: '#666666', fontFamily: 'Lato', fontSize: '9px', fontWeight: 400 }}>Overall Water Consumption</Text>
                <Text style={{ color: '#666666', fontFamily: 'Lato', fontSize: '9px', paddingTop: '10px', fontWeight: 400 }}>
                  Liters per Capita per Day (LPCD)
                </Text>
              </>
            )}
            {props.category === 'Materials' && (
              <Text style={{ color: '#666666', fontFamily: 'Lato', fontSize: '9px', fontWeight: 400 }}>Overall Embodied Carbon Avoided</Text>
            )}
          </View>
          <View style={{ width: '15%' }} />
          <View style={{ paddingTop: '15px', width: '20%' }}>
            <Text style={{ fontFamily: 'Lato', fontSize: '9px', fontWeight: 700, color: '#2E2E2E' }}>Before</Text>
            {props.category === 'Energy' && (
              <Text style={{ color: '#666666', fontFamily: 'Lato', fontSize: '9px', paddingTop: '10px' }}>{`${numberWithCommas(
                energyBefore && energyBefore?.value
              )} ${props.totalResourceSavings && props.totalResourceSavings.unit}`}</Text>
            )}
            {props.category === 'Waste' && (
              <Text style={{ color: '#666666', fontFamily: 'Lato', fontSize: '9px', paddingTop: '10px' }}>{`${numberWithCommas(
                Math.round(wasteBefore && wasteBefore?.value)
              )} ${props.totalResourceSavings && props.totalResourceSavings.unit}`}</Text>
            )}
            {props.category === 'Materials' && (
              <Text style={{ color: '#666666', fontFamily: 'Lato', fontSize: '9px', paddingTop: '10px' }}>{`${numberWithCommas(
                Math.round(materialsBefore && materialsBefore?.value)
              )} ${props.totalResourceSavings && props.totalResourceSavings.unit}`}</Text>
            )}
            {props.category === 'Water' && (
              <>
                <Text style={{ color: '#666666', fontFamily: 'Lato', fontSize: '9px', paddingTop: '10px' }}>{`${numberWithCommas(
                  Math.round(waterBefore && waterBefore?.value)
                )} ${props.totalResourceSavings && props.totalResourceSavings.unit}`}</Text>
                <Text style={{ color: '#666666', fontFamily: 'Lato', fontSize: '9px', paddingTop: '10px' }}>{`${numberWithCommas(
                  Math.round(totalLPCDPreSdplus && totalLPCDPreSdplus)
                )} L`}</Text>
              </>
            )}
          </View>
          <View style={{ width: '5%' }} />
          <View style={{ paddingTop: '15px', width: '20%' }}>
            <Text style={{ fontFamily: 'Lato', fontSize: '9px', fontWeight: 700, color: '#2E2E2E' }}>After</Text>
            {props.category === 'Energy' && (
              <Text style={{ color: props.color, fontFamily: 'Lato', fontSize: '9px', paddingTop: '10px' }}>{`${numberWithCommas(
                Math.round(overallEnergy && overallEnergy)
              )} ${props.totalResourceSavings && props.totalResourceSavings.unit}`}</Text>
            )}
            {props.category === 'Waste' && (
              <Text style={{ color: props.color, fontFamily: 'Lato', fontSize: '9px', paddingTop: '10px' }}>{`${numberWithCommas(
                Math.round(overallWaste && overallWaste)
              )} ${props.totalResourceSavings && props.totalResourceSavings.unit}`}</Text>
            )}
            {props.category === 'Materials' && (
              <Text style={{ color: props.color, fontFamily: 'Lato', fontSize: '9px', paddingTop: '10px' }}>{`${numberWithCommas(
                Math.round(overallMaterials && overallMaterials)
              )} ${props.totalResourceSavings && props.totalResourceSavings.unit}`}</Text>
            )}
            {props.category === 'Water' && (
              <>
                <Text style={{ color: props.color, fontFamily: 'Lato', fontSize: '9px', paddingTop: '10px' }}>{`${numberWithCommas(Math.round(overallWater))} ${
                  props.totalResourceSavings && props.totalResourceSavings.unit
                }`}</Text>
                <Text style={{ color: props.color, fontFamily: 'Lato', fontSize: '9px', paddingTop: '10px' }}>{`${numberWithCommas(
                  Math.round(totalLPCDPostSdplus && totalLPCDPostSdplus)
                )} ${props.totalResourceSavings && props.totalResourceSavings.unit}`}</Text>
              </>
            )}
          </View>
          <View style={{ width: '5%' }} />
          <View style={{ paddingTop: '15px', paddingLeft: '10px', width: '20%' }}>
            <Text style={{ fontFamily: 'Lato', fontSize: '9px', fontWeight: 700, color: '#2E2E2E' }}>Change</Text>
            {props.category === 'Energy' && (
              <Text style={{ color: props.color, fontFamily: 'Lato', fontSize: '9px', paddingTop: '10px' }}>
                {Math.round(props?.resourceSavingPercentage)} %
              </Text>
            )}
            {props.category === 'Waste' && (
              <Text style={{ color: props.color, fontFamily: 'Lato', fontSize: '9px', paddingTop: '10px' }}>
                {Math.round(props?.resourceSavingPercentage)} %
              </Text>
            )}
            {props.category === 'Materials' && (
              <Text style={{ color: props.color, fontFamily: 'Lato', fontSize: '9px', paddingTop: '10px' }}>
                {percentChange(overallMaterials && overallMaterials, materialsBefore && materialsBefore.value)} %
              </Text>
            )}
            {props.category === 'Water' && (
              <>
                <Text style={{ color: props.color, fontFamily: 'Lato', fontSize: '9px', paddingTop: '10px' }}>
                  {Math.round(props?.resourceSavingPercentage)} %
                </Text>
                <Text style={{ color: props.color, fontFamily: 'Lato', fontSize: '9px', paddingTop: '10px' }}>
                  {percentChange(totalLPCDPostSdplus && totalLPCDPostSdplus, totalLPCDPreSdplus && totalLPCDPreSdplus)} %
                </Text>
              </>
            )}
          </View>
        </View>
        {/* common card */}
        {categoryWiseInterventionSummary(categorisedReportData, props?.category).map((d) => (
          <IntervDataCard intervData={d} border={props?.border} color={props?.color} icon={d?.icon} />
        ))}
      </View>
      <View style={{ marginTop: '2%' }}>
        <Footer year={props.year} />
      </View>
    </View>
  );
};

export default CategoryWiseSummaryPage;
