import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import Header from './Header';
import Footer from './Footer';
import ActiveIntervCard from './ActiveIntervcard';
import rsToCr from '../../../../../utils/rsToCr';
import numberWithCommas from '../../../../../utils/numberToComma';
import { convertToLakhs, getCapacityUnitDynamically, intervContentFeatureKeyMap, intervNameFeatureKeyMap } from './ReportUtils';
// import vendorcivil from '../../../../../assets/new-pdf/vendorcivil.png';

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#FFFFFF',
  },
  viewPage2: {
    margin: '10px 20px 20px 20px',
    padding: 10,
    flexGrow: 1,
  },
  pageCover: {
    height: '100%',
    width: '521px',
    paddingTop: '20px',
  },
  footerMargin: {
    // top: 30,
    // paddingTop: '90%',
  },
});

const ImplementInterventionsDetailsPage = (props) => {
  const selectedVendorFunction = (index, mappingId, data, vendorMappings, vendors, intname) => {
    if (mappingId && data && vendorMappings && vendors) {
      let A = data.find((a) => a._id === mappingId && a.hasOwnProperty('selectedVendor'));
      let B = A && vendorMappings.find((vendor) => vendor._id === A.selectedVendor);
      let C = B && vendors.find((v) => v._id === B.vendor._id);
      return { logo: C && C.logo.URL };
    }
  };

  return (
    <View style={styles.viewPage2}>
      <Header companyname={props.companyname} />
      <Text style={{ fontFamily: 'Lato', fontWeight: 'Bold', fontSize: '14px', textTransform: 'uppercase', marginTop: '20px' }}>Implemented interventions</Text>
      {props.categorisedReportData && props.categorisedReportData.length > 0 ? (
        props.categorisedReportData.map((interv, index) => {
          return (
            <View>
              <ActiveIntervCard
                border={props.border}
                color={props.color}
                content={intervContentFeatureKeyMap[interv.interventionKey]}
                name={intervNameFeatureKeyMap[interv.interventionKey]}
                capex={convertToLakhs(interv.capex)}
                opex={convertToLakhs(interv.opex)}
                resource={interv.resourceSavingsAnnual || interv.resourceSavings}
                unit={props.resourceSavingUnit}
                capacity={interv.capacity || interv.flow || interv.stpCapacity || interv.noOfSensors || interv.quantity}
                fullFlow={interv?.fullFlow || ''}
                halfFlow={interv?.halfFlow || ''}
                capacityUnit={getCapacityUnitDynamically(interv.interventionKey, interv.option)}
                areaUnit={'sqm'}
                vocLevel={interv?.vocLevel || ''}
                category={props?.category}
                area={interv.areaRequired}
                interventionKey={interv.interventionKey}
                embodiedCarbon={interv?.embodiedCarbon || ''}
                brand={interv?.brand || ''}
                selectedVendor={
                  selectedVendorFunction(
                    index,
                    interv.interventionProjectMappingId,
                    props.ProjectinterventionMappingData && props.ProjectinterventionMappingData,
                    props.allVendorMappings && props.allVendorMappings,
                    props.allvendors && props.allvendors,
                    interv.interventionKey
                  ) &&
                  selectedVendorFunction(
                    index,
                    interv.interventionProjectMappingId,
                    props.ProjectinterventionMappingData && props.ProjectinterventionMappingData,
                    props.allVendorMappings && props.allVendorMappings,
                    props.allvendors && props.allvendors,
                    interv.interventionKey
                  )
                }
              />
            </View>
          );
        })
      ) : (
        <ActiveIntervCard
          border={props.border}
          color={props.color}
          area={0}
          capex={0}
          areaUnit="-"
          capacity={0}
          opex={0}
          capacityUnit="-"
          resource={0}
          unit="-"
        />
      )}

      <View
        style={{
          marginTop: '2%',
        }}
      >
        <Footer year={props.year} />
      </View>
    </View>
  );
};

export default ImplementInterventionsDetailsPage;
