export const getEnabledCount = (custom, interv) => {
  let intervCount = 0;
  let customCount = custom;
  interv
    .map((arr) => arr.length)
    .forEach((D) => {
      intervCount = intervCount + D;
    });
  return customCount + intervCount;
};

export const calculateEnabledCount = (data) => {
  const interv = data?.subcategories?.filter((interventions) => interventions?.name !== 'Custom Interventions');
  const custom = data?.subcategories?.filter((interventions) => interventions?.name === 'Custom Interventions');
  const customCount = custom[0]?.interventions?.filter((item) => item?.enabled === true)?.length;
  const intervCount = interv?.map((intervention) => intervention?.interventions?.filter((item) => item?.enabled === true));
  return getEnabledCount(customCount, intervCount);
};

export const getTotalInterventionsInCategory = (data = []) => {
  return data?.subcategories?.reduce((memo, sbCategory) => {
    memo += sbCategory?.interventions?.length || 0;
    return memo;
  }, 0);
};

export const getEnabledInterventionsInCategory = (data = []) => {
  return data?.subcategories?.reduce((memo, sbCategory) => {
    sbCategory?.interventions.forEach((intervention) => {
      if (intervention?.quantities?.enabled?.value === true) {
        memo.push(intervention);
      }
    });
    return memo;
  }, []);
};

export const getEnabledAndActivatedInterventionsInCategory = (data = []) => {
  return data?.subcategories?.reduce((memo, sbCategory) => {
    sbCategory?.interventions.forEach((intervention) => {
      if (intervention?.quantities?.enabled?.value === true && intervention?.quantities?.activated?.value === true) {
        memo.push(intervention);
      }
    });
    return memo;
  }, []);
};

export const getActivatedInterventionsInCategory = (data = []) => {
  return data?.subcategories?.reduce((memo, sbCategory) => {
    sbCategory?.interventions.forEach((intervention) => {
      if (intervention?.quantities?.activated?.value === true) {
        memo.push(intervention);
      }
    });
    return memo;
  }, []);
};

export function capitalizeFilterValues(products = [], keyName) {
  if (Array.isArray(products)) {
    return products.reduce((memo, product) => {
      if (product?.filters && product?.filters[keyName]) {
        let cloneProduct = Object.assign({}, product);
        const updatedValues = cloneProduct.filters[keyName].map((value) => {
          return value
            .split(' ')
            .map((word) =>
              word
                .split('-')
                .map((part) => part.charAt(0).toUpperCase() + part.slice(1))
                .join(' ')
            )
            .join(' ');
        });
        cloneProduct.filters[keyName] = updatedValues;
        memo.push(cloneProduct);
      } else {
        memo.push(product);
      }
      return memo;
    }, []);
  } else {
    console.error('The input is not an array of products');
    return products;
  }
}
export function getLabelDynamically(name) {
  return name === 'Materials' ? 'Carbon' : name;
}

export function sortResponse(response, key) {
  if ((key && key.startsWith('spv')) || key === 'wwm') {
    const systemCapacityItem = response.find((item) => item.label === 'System Capacity');
    if (systemCapacityItem) {
      return [systemCapacityItem, ...response.filter((item) => item.label !== 'System Capacity')];
    }
  } else if (key && key.startsWith('hwm')) {
    const capacityItem = response.find((item) => item.label === 'System');
    if (capacityItem) {
      return [capacityItem, ...response.filter((item) => item.label !== 'System')];
    }
  } else if (key === 'twr') {
    const percentageItems = response.filter((item) => item.unit === '%');
    const stpCapacity = response.filter((item) => item.id === 'twr__pi_userStpCapacity');
    const roCapacity = response.filter((item) => item.id === 'twr__pi_userRoCapacity');
    return [...stpCapacity, ...roCapacity, ...percentageItems];
  } else if (key === 'nstp') {
    const area = response.filter((item) => item.id === 'nstp_nstpArea');
    const stpCapacity = response.filter((item) => item.id === 'nstp_stpCapacity');
    return [...stpCapacity, ...area];
  } else if (key === 'csp') {
    const dailyDryWaste = response.filter((item) => item.id === 'csp_dailyDryWaste');
    const dailyMedicalWaste = response.filter((item) => item.id === 'csp_dailyMedicalWaste');
    const dailyEWaste = response.filter((item) => item.id === 'csp_dailyEWaste');
    const areaRequired = response.filter((item) => item.id === 'csp_areaRequired');
    return [...dailyDryWaste, ...dailyMedicalWaste, ...dailyEWaste, ...areaRequired];
  } else if (key === 'structuralSteel') {
    const steelQuantity = response.filter((item) => item.id === 'structuralSteel__pi_steelQuantity');
    const typeOfSteel = response.filter((item) => item.id === 'structuralSteel_typeOfSteel');
    return [...steelQuantity, ...typeOfSteel];
  }
  return response;
}

export function getTextColourForIntervention(feature) {
  if (
    [
      'twr',
      'nstp',
      'efficientFixturesDualFlush',
      'efficientFixturesShower',
      'efficientFixturesKitchenSink',
      'efficientFixturesHealthFaucet',
      'efficientFixturesFaucet',
      'swm',
    ].includes(feature)
  )
    return '#3BC7EE';
  if (
    ['hwmVilla', 'hwmApartment', 'hwmClubhouse', 'hwmSwimmingPool', 'hwmPenthouse', 'spvApartment', 'spvClubhouse', 'spvVilla', 'spvPenthouse'].includes(
      feature
    )
  )
    return '#FFAC49';
  if (
    [
      'exteriorPaintsSunny',
      'exteriorPaintsNonSunny',
      'indoorFlooringUnB',
      'indoorFlooringBathroom',
      'indoorFlooringKitchen',
      'indoorFlooringBedroom',
      'indoorFlooringLnD',
    ].includes(feature)
  )
    return '#916AAC';
  if (['wwm', 'csp'].includes(feature)) return '#BC8A5F';
  return '#000000';
}

export function getTextColourForCategory(category) {
  if (['energy'].includes(category.toLowerCase())) return '#FFAC49';
  if (['waste'].includes(category.toLowerCase())) return '#BC8A5F';
  if (['water'].includes(category.toLowerCase())) return '#3BC7EE';
  if (['material'].includes(category.toLowerCase())) return '#916AAC';
  return '#000000';
}

export function orderCategory(data) {
  const presetCategoryOrder = ['Energy', 'Water', 'Waste', 'Materials'];
  return (
    data &&
    data
      ?.filter((category) => presetCategoryOrder.includes(category?.name))
      .sort((a, b) => presetCategoryOrder.indexOf(a.name) - presetCategoryOrder.indexOf(b.name))
  );
}
export const formatString = (dataObj) => {
  const keys = Object.keys(dataObj);
  let dataS = '';
  keys.forEach((d, i) => {
    if (i !== keys.length - 1) return (dataS += `${d}: ${dataObj[d]},`);
    else return (dataS += `${d}: ${dataObj[d]}`);
  });
  return dataS;
};

export const ProductFilterKeys = {
  brand: '#brand',
  variant: '#variant',
  capacity: '#capacity',
  eco_labels: '#eco_labels',
  subcategory: '#subcategory',
  system_types: '#system_types',
  solutions: '#solutions',
  panel_type: '#panel_type',
  application: '#application',
  inverter_type: '#inverter_type',
  capacity_range: '#capacity_range',
  panel_capacity: '#panel_capacity',
};

export const getFilterDetailsForProduct = (data = {}, filterByValues = [], filterByKeys = []) => {
  let filter = null;
  const { product = {}, category = null, subCategory = null } = data;
  const categoryValue = category;
  const subCategoryValue = subCategory;

  if (filterByKeys && filterByKeys.length) {
    filter =
      product?.filters &&
      Object.entries(product?.filters)
        .filter(([key, value]) => filterByKeys.includes(key) && value.length > 0 && value !== null && value !== undefined)
        .map(([, value]) => value.filter((v) => v))
        .flat()
        .join(',');
  }

  if (filterByValues && filterByValues.length) {
    filter = filterByValues.flat().join(',');
  }

  return { category: categoryValue, subCategory: subCategoryValue, filter };
};

export function getCategoryByFeature(featureKey) {
  const energy = ['hwmApartment', 'hwmVilla', 'hwmPenthouse', 'hwmClubhouse', 'hwmSwimmingPool', 'spvApartment', 'spvVilla', 'spvPenthouse', 'spvClubhouse'];
  const water = [
    'efficientFixturesDualFlush',
    'efficientFixturesFaucet',
    'efficientFixturesHealthFaucet',
    'efficientFixturesKitchenSink',
    'efficientFixturesShower',
    'twr',
    'nstp',
    'swm',
    'rrwh',
    'srwh',
  ];
  const materials = [
    'indoorFlooringBedroom',
    'indoorFlooringKitchen',
    'indoorFlooringBathroom',
    'indoorFlooringLnD',
    'indoorFlooringUnB',
    'sc',
    'structuralSteel',
    'exteriorPaintsSunny',
    'exteriorPaintsNonSunny',
    'interiorPaints',
  ];
  const waste = ['wwm', 'csp'];
  if (energy.includes(featureKey)) return 'energy';
  if (water.includes(featureKey)) return 'water';
  if (materials.includes(featureKey)) return 'materials';
  if (waste.includes(featureKey)) return 'waste';
  return '';
}
