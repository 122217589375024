// import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid } from '@material-ui/core';
// import { Link } from 'react-router-dom';
// import ShowSaveAndCancel from './showSaveAndCancel';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import ResetFields from '../common/textFiledwithReset';
import { useInterventionDetailsContext } from '../context/InterventionDetails';
import useStyles from './styles';
import InfoIcon from '@material-ui/icons/Info';

const StructuralSteelCustomBasicParameters = (props) => {
  const classes = useStyles();
  const {
    schema,
    get: { getQuantityValue, getQuantityDefaultValue, getQuantityLabel, getQuantityUnit },
    set: { updateQuantityValue },
  } = useInterventionDetailsContext();

  const handlePanelType = (type) => {
    updateQuantityValue(schema.structuralSteel__pi_panelType, type);
    updateQuantityValue(schema.structuralSteel__pi_selectedProductIdentifier, null);
  };
  const handleInverterType = (type) => {
    updateQuantityValue(schema.structuralSteel__pi_inverterType, type);
    updateQuantityValue(schema.structuralSteel__pi_selectedProductIdentifier, null);
  };
  const panelType = getQuantityValue(schema.structuralSteel__pi_panelType);
  const inverterType = getQuantityValue(schema.structuralSteel__pi_inverterType);
  return (
    <Grid container direction="column" alignItems="flex-start" item xs={12}>
      <>
        <Grid container item xs={12} style={{ paddingTop: '10px' }}>
          <Grid item xs={4} direction="column">
            <Grid container item xs={12} style={{ paddingBottom: '10px', display: 'flex', alignItems: 'center' }}>
              <Typography className={classes.textHeader5}>{getQuantityLabel(schema.structuralSteel__pi_steelQuantity)}</Typography>
            </Grid>
            <Grid xs={12} container alignItems="flex-start" direction="row" style={{ display: 'flex', alignItems: 'center' }}>
              <Grid xs={7}>
                <ResetFields
                  id="outlined-basic"
                  variant="outlined"
                  value={getQuantityValue(schema.structuralSteel__pi_steelQuantity)}
                  placeholder=""
                  onChange={(e) => {
                    e.preventDefault();
                    updateQuantityValue(schema.structuralSteel__pi_steelQuantity, e.target.value);
                  }}
                  fullWidth
                  required
                  onClick={(e) => {
                    e.preventDefault();
                    updateQuantityValue(schema.structuralSteel__pi_steelQuantity, getQuantityDefaultValue(schema.structuralSteel__pi_steelQuantity));
                  }}
                  disabled={false}
                />
              </Grid>
              <Grid xs={3} className={classes.grid} style={{ paddingLeft: '2%' }}>
                <Typography variant="h6">{getQuantityUnit(schema.structuralSteel__pi_steelQuantity)}</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={4} direction="column">
            <Grid container item xs={12} style={{ paddingBottom: '10px' }}>
              <Typography className={classes.textHeader5}>{getQuantityLabel(schema.structuralSteel_typeOfSteel)}</Typography>
            </Grid>
            <Grid xs={12} container alignItems="flex-start" direction="column">
              <Typography className={classes.textHeader3}>{getQuantityValue(schema.structuralSteel_typeOfSteel)}</Typography>
            </Grid>{' '}
          </Grid>
        </Grid>
        <Grid container item xs={12} style={{ paddingTop: '10px' }} className={classes.mandateBox}>
          {/* First instance */}
          <Grid item>
            <InfoIcon className={classes.infoStyle} />
          </Grid>
          <Grid item xs={11}>
            <Typography className={classes.mandateText}>
              Basic Oxygen Furnace (BOF) Steel is considered as the base case for calculation as that is widely used
            </Typography>
          </Grid>

          {/* Second instance */}
          <Grid container item xs={12} style={{ paddingTop: '10px' }}>
            <Grid item>
              <InfoIcon className={classes.infoStyle} />
            </Grid>
            <Grid item xs={11}>
              <Typography className={classes.mandateText}>
                Induction Furnace (IF) steel is produced primarily from recycled scrap metal, reducing the need for virgin materials and lowering carbon
                emissions.
              </Typography>
            </Grid>
          </Grid>
          <Grid container item xs={12} style={{ paddingTop: '10px', paddingBottom: '10px' }}>
            <Grid item>
              <InfoIcon className={classes.infoStyle} />
            </Grid>
            <Grid item xs={11}>
              <Typography className={classes.mandateText}>
                Energy Optimizing Furnace (EOF) steel is an energy-efficient steelmaking process that reduces carbon emissions.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </>
    </Grid>
  );
};
export default StructuralSteelCustomBasicParameters;
