import React from 'react';
import { makeStyles, Grid, Typography } from '@material-ui/core';
// import CustomizedBar from '../../insightsPage/insights/child-components/CustomizedBar';
import CustomizedLongBar from '../common/CustomisedLongBar';
import { extractData } from '../../../../utils/dataConditionsCheck';
import CustomizedBar from '../common/CustomisedBar';
import numberWithCommas from '../../../../utils/numberToComma';
import rsToCr from '../../../../utils/rsToCr';
import unit from '../../../../utils/unitConfig';
import { IGBCCategories } from '../utils/types';

const useStyles = makeStyles((theme) => ({
  fff: {
    minHeight: 380,
    padding: 18,
    marginBottom: theme.spacing(2),
    background: 'linear-gradient(0deg, #FFFFFF, #FFFFFF), #BFF5E6',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.05)',
    borderRadius: '6px',
    position: 'relative',
    width: 'auto',
  },
  secondaryTextcolor: {
    color: '#9E9E9E',
    paddingBottom: '10px',
  },
  bartitletext: {
    fontSize: '8px',
    lineHeight: '12px',
    letterSpacing: '0.5px',
    fontWeight: 400,
    textAlign: 'center',
  },
}));

const categorywiseColors = [
  { name: IGBCCategories.sustainableDesign, color: '#26E0A3' },
  { name: IGBCCategories.waterConservation, color: '#C9F5FF5' },
  { name: IGBCCategories.energyEfficiency, color: '#FFF4D5' },
  { name: IGBCCategories.material, color: '#E6C1FF' },
  { name: IGBCCategories.residentHealth, color: '#FEE7EC' },
  { name: IGBCCategories.innovation, color: '#E1E6FF' },
];

export default function IGBCPoints({ igbcCertificationData, bargraphData }) {
  const classes = useStyles();
  return (
    <>
      <Grid item xs={5} container direction="row" alignItems="left" spacing={1} style={{ paddingLeft: '1%' }}>
        <Grid xs={12} item>
          <Typography variant="h6">
            <b>IGBC Points</b>
          </Typography>
        </Grid>
        <Grid xs={12} item style={{ color: '#666666', marginTop: '2%' }}>
          <Typography variant="h2">
            {igbcCertificationData && igbcCertificationData.overall?.totalpointsachieved && igbcCertificationData.overall?.totalpointsachieved}
          </Typography>
        </Grid>
        <Grid xs={12} item style={{ marginTop: '2%' }}>
          <Typography variant="h6" style={{ fontSize: '12px' }} className={classes.secondaryTextcolor}>
            {igbcCertificationData && igbcCertificationData.certificationstatus?.status && igbcCertificationData.certificationstatus?.status}
          </Typography>
        </Grid>
        <Grid xs={12} item style={{ marginTop: '3%' }}>
          <Typography variant="h6" style={{ lineHeight: '22px', fontSize: '12px' }} className={classes.secondaryTextcolor}>
            {igbcCertificationData && igbcCertificationData.overall?.totalpointsachieved && igbcCertificationData.overall?.hasOwnProperty('hints')
              ? igbcCertificationData.overall?.hints
              : ''}
          </Typography>
        </Grid>
        <Grid xs={12} item style={{ marginTop: '2%' }}>
          <Typography variant="h6">
            <b>Financial Insight</b>
          </Typography>
        </Grid>
        <Grid xs={12} item container spacing={2} style={{ marginTop: '1%' }}>
          <Grid item xs={12}>
            <Typography variant="h6" className={classes.secondaryTextcolor}>
              CAPEX
            </Typography>
            {
              <CustomizedBar
                bgcolor="#fece3c"
                insideColor="#E5E5E5"
                value={
                  igbcCertificationData && igbcCertificationData.overall
                    ? igbcCertificationData.overall.capex.value !== null
                      ? !isNaN(igbcCertificationData.overall.capex.value)
                        ? `${rsToCr('Rs', Math.round(igbcCertificationData.overall.capex.value), +' ' + unit('cr'))}`
                        : `${rsToCr('Rs', igbcCertificationData.overall.capex.value, +' ' + unit('cr'))}`
                      : 0
                    : '-'
                }
                barvalue={
                  igbcCertificationData && igbcCertificationData.overall
                    ? igbcCertificationData.overall.capex.value !== null
                      ? !isNaN(igbcCertificationData.overall.capex.value)
                        ? `${numberWithCommas(Math.round(igbcCertificationData.overall.capex.value))}`
                        : numberWithCommas(igbcCertificationData.overall.capex.value)
                      : 0
                    : '-'
                }
              />
            }
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" className={classes.secondaryTextcolor}>
              OPEX Savings
            </Typography>
            <CustomizedBar
              bgcolor="#74DEFB"
              insideColor="#E5E5E5"
              value={
                igbcCertificationData && igbcCertificationData.overall
                  ? igbcCertificationData.overall.opex.value !== null
                    ? !isNaN(igbcCertificationData.overall.opex.value)
                      ? `${rsToCr('Rs', Math.round(Math.round(igbcCertificationData.overall.opex.value)), +' ' + unit('cr'))}`
                      : `${rsToCr('Rs', Math.round(igbcCertificationData.overall.opex.value), +' ' + unit('cr'))}`
                    : 0
                  : 0
              }
              barvalue={
                igbcCertificationData && igbcCertificationData.overall
                  ? igbcCertificationData.overall.opex.value !== null
                    ? !isNaN(igbcCertificationData.overall.opex.value)
                      ? `${numberWithCommas(Math.round(igbcCertificationData.overall.opex.value))}`
                      : numberWithCommas(igbcCertificationData.overall.opex.value)
                    : 0
                  : 0
              }
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={7} container direction="column" alignItems="center" justifyContent="center">
        <Grid style={{ transform: 'rotate(270deg)', height: 378 }}>
          {bargraphData &&
            bargraphData.map((category, index) => {
              return (
                <>
                  <Grid xs={2} item style={{ borderLeft: '1px solid rgba(158, 158, 158, 0.25)', paddingBottom: '4%' }}>
                    <CustomizedLongBar
                      bgcolor="#E5E5E5"
                      barColor={
                        categorywiseColors &&
                        categorywiseColors.find((d) => d.name === category?.name) &&
                        categorywiseColors.find((d) => d.name === category?.name).color
                      }
                      value={10}
                      barvalue={
                        category?.overall && category?.overall.totalpoints && category?.overall.totalpointsachieved
                          ? (category?.overall?.totalpointsachieved / category?.overall?.totalpoints) * 100
                          : 0
                      }
                      width={280}
                      categoryData={category && category}
                    />
                  </Grid>
                </>
              );
            })}
        </Grid>
        <Grid alignItems="center" justifyContent="center">
          <Grid container item xs={12} sm={12} alignItems="center" justifyContent="center" style={{ marginTop: '-10%', width: 378 }}>
            <Grid item xs={2}>
              <Typography className={classes.bartitletext}>Sustainable design</Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography className={classes.bartitletext}>Water conservation</Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography className={classes.bartitletext}>Energy Efficency</Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography className={classes.bartitletext}>Material & Resources</Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography className={classes.bartitletext}>Resident health & well-being</Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography className={classes.bartitletext}>Innovation & design</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
