import { Button, Grid, Typography, makeStyles } from '@material-ui/core';
import axios from 'axios';
import Tour from 'reactour';
import { InterventionCategoryContext, wlakthroughContext } from '../../../../../projectRouting/Index';
import { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { clearAllBodyScrollLocks, disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

const useStyles = makeStyles((theme) => ({
  tourtexthead: {
    fontStyle: 'normal',
    fontWeight: 850,
    fontSize: '14px',
    lineHeight: '22px',
    textAlign: 'center',
    letterSpacing: '0.714286px',
    color: '#000000',
    paddingBottom: '10px',
  },
  tourtext: {
    fontStyle: 'normal',
    fontSize: '12px',
    lineHeight: '24px',
    textAlign: 'justify',
    letterSpacing: '0.714286px',
    color: '#000000',
  },
}));

const InterventionSummaryTour = ({ selectedInterventionByCategory }) => {
  const classes = useStyles();
  const { interventionCategoryContext, handleInterventionCategory } = useContext(InterventionCategoryContext);
  const { uiWalkthroughContext, handleMyWalkthrough } = useContext(wlakthroughContext);
  const [isTourOpen, setIsTourOpen] = useState(false);
  const [userprofile, setUserprofile] = useState(null);
  const history = useHistory();
  const params = useParams();
  const projectId = params?.id;

  useEffect(() => {
    clearAllBodyScrollLocks();
    axios.get(`/userprofiles`).then((resp) => {
      if (resp.data && resp.data.data && resp.data.data) {
        setUserprofile(resp.data.data.data);
        handleMyWalkthrough(resp.data.data.data.showhowitworks);
      }
    });
  }, []);

  useEffect(() => {
    if (uiWalkthroughContext) setIsTourOpen(uiWalkthroughContext);
  }, [uiWalkthroughContext]);

  const handleCloseTour = () => {
    if (userprofile.showhowitworks === true) {
      axios.put(`/userprofiles/${userprofile._id}/howitworks`, { howitworks: false }).then((resp) => {});
    }
    setIsTourOpen(false);
    handleMyWalkthrough(false);
    clearAllBodyScrollLocks();
  };

  const handleTourClick = () => {
    if (interventionCategoryContext && selectedInterventionByCategory) {
      let mappingid = selectedInterventionByCategory.subcategories[0].interventions[0].interventionProjectMappingId;
      history.push(`/projects/${projectId}/interventions/${mappingid}`);
    }
  };

  const handleEnd = () => {
    axios.put(`/userprofiles/${userprofile._id}/howitworks`, { howitworks: false }).then((resp) => {});
    handleMyWalkthrough(false);
    clearAllBodyScrollLocks();
  };

  /*Get status of how it works*/
  const disableBody = (target) => disableBodyScroll(target);
  const enableBody = (target) => enableBodyScroll(target);

  let tourProps = {
    isOpen: isTourOpen,
    onRequestClose: handleCloseTour,
    steps: [
      {
        selector: '[data-tour="table"]',
        content: (
          <Grid style={{ marginBottom: '-15px' }}>
            <Typography className={classes.tourtexthead}>Interventions/Solutions</Typography>
            <Typography className={classes.tourtext}>
              Solutions are listed under their respective category & summary of their financial & environmental impact are displayed
            </Typography>
            <Typography className={classes.tourtext} style={{ paddingTop: '10px' }}>
              Solutions can be enabled or disabled as per user’s requirement
            </Typography>
          </Grid>
        ),
        position: 'auto',
        style: {
          backgroundColor: '#FFFFFF',
          borderRadius: '8px',
          height: '240px',
        },
      },
      {
        selector: '[data-tour="diagram"]',
        content: (
          <Grid style={{ marginBottom: '-15px' }}>
            <Typography className={classes.tourtexthead}>Flow Diagram</Typography>
            <Typography className={classes.tourtext}>A visual representaion of daily resource flow for each category</Typography>
          </Grid>
        ),
        position: 'bottom',
        style: {
          backgroundColor: '#FFFFFF',
          borderRadius: '8px',
          height: '150px',
        },
      },
      {
        selector: '[data-tour="custom"]',
        content: (
          <Grid style={{ marginBottom: '-15px' }}>
            <Typography className={classes.tourtexthead}>Custom Interventions</Typography>
            <Typography className={classes.tourtext}>
              Interventions that are not yet present on SD+ can be added manually so that they can be considered in the overall summary
            </Typography>
            <Typography className={classes.tourtext} style={{ paddingTop: '10px' }}>
              Please note that custom interventions will not have a intervention details page{' '}
            </Typography>
          </Grid>
        ),
        position: 'bottom',
        style: {
          backgroundColor: '#FFFFFF',
          borderRadius: '8px',
          height: '240px',
        },
      },
      {
        selector: '[data-tour="report"]',
        content: (
          <Grid style={{ marginBottom: '-15px' }}>
            {/* <Typography className={classes.tourtexthead}>Create Report</Typography>
            <Typography className={classes.tourtext}>
              Download a shareable report (in pdf format) summarising all project information avaiable on SD+{' '}
            </Typography> */}
            <Button style={{ color: '#29E1AC', backgroundColor: '#FFFFFF', fontSize: '12px' }} onClick={handleTourClick}>
              Intervention Details
            </Button>
          </Grid>
        ),
        position: 'bottom',
        style: {
          backgroundColor: '#FFFFFF',
          borderRadius: '8px',
          height: '170px',
        },
      },
    ],
  };
  return (
    <>
      <Tour
        {...tourProps}
        onAfterOpen={disableBody}
        onBeforeClose={enableBody}
        disableInteraction={true}
        scrollDuration={100}
        lastStepNextButton={
          <Button style={{ color: '#29E1AC', marginLeft: '150%', marginTop: '-60px', backgroundColor: '#FFFFFF', fontSize: '12px' }} onClick={handleEnd}>
            End
          </Button>
        }
        disableKeyboardNavigation={['esc']}
        showNavigation={false}
        showNumber={false}
        prevButton
        nextButton={<Button style={{ color: '#29E1AC', backgroundColor: '#FFFFFF', marginLeft: '150%', fontSize: '12px' }}>Next</Button>}
        badgeContent={(curr, tot) => `${curr} of ${tot}`}
        rounded={12}
        startAt={0}
      />
    </>
  );
};
export default InterventionSummaryTour;
