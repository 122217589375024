import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import Fields from '../../../../../common/textfield/Fields';

function ManualPIP({ displayName, pipValues, description, projectPIP, _id, textbox, appendChanged }) {
  return (
    <>
      <Grid container alignItems="flex-start" item xs={12} style={{ height: 'Auto', marginTop: '30px', paddingLeft: '8px' }}>
        <Typography variant="h6">{displayName}</Typography>
      </Grid>
      {description && description !== 'NA' ? (
        <Grid alignItems="center" item xs={12} style={{ marginTop: '3%', paddingLeft: '8px' }}>
          <Typography variant="h6" style={{ lineHeight: '20px', fontSize: '13px', letterSpacing: '0.571429px', color: 'rgba(0, 0, 0, 0.38)' }}>
            {description}
          </Typography>
        </Grid>
      ) : null}
      <Grid container alignItems="flex-start" item xs={12} style={{ marginLeft: '2%', marginTop: '4%' }}>
        <Fields
          placeholder={`Enter ${displayName}`}
          required
          value={pipValues[_id]}
          onChange={(e) => {
            e.preventDefault();
            appendChanged(_id, e.target.value);
          }}
          fullWidth
        />
      </Grid>
    </>
  );
}

export default ManualPIP;
