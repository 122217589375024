import React from 'react';
import { Typography, Grid } from '@material-ui/core';
import useStyles from './styles';
import { useInterventionDetailsContext } from '../context/InterventionDetails';

const IndoorFlooringLnDProjectInputs = (props) => {
  const classes = useStyles();
  const {
    schema,
    get: { getQuantityValue, getQuantityUnit },
  } = useInterventionDetailsContext();
  let subCategory = getQuantityValue(schema.indoorFlooringLnD_subcategory);
  return (
    <Grid container item xs={12} direction="column">
      <Typography className={classes.textHeader1}>Project Inputs</Typography>
      {subCategory !== 'villa' && (
        <>
          <Grid container item xs={12} style={{ paddingTop: '1%' }}>
            <Grid item xs>
              <Typography className={classes.textHeader5}>Studio</Typography>
              <Typography className={classes.textHeader3}>
                {getQuantityValue(schema.indoorFlooringLnD_studio)} {getQuantityUnit(schema.indoorFlooringLnD_studio)}
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography className={classes.textHeader5}>1 BHK</Typography>
              <Typography className={classes.textHeader3}>
                {getQuantityValue(schema.indoorFlooringLnD_oneBHK)} {getQuantityUnit(schema.indoorFlooringLnD_oneBHK)}
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography className={classes.textHeader5}>2 BHK</Typography>
              <Typography className={classes.textHeader3}>
                {getQuantityValue(schema.indoorFlooringLnD_twoBHK)} {getQuantityUnit(schema.indoorFlooringLnD_twoBHK)}
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography className={classes.textHeader5}>3 BHK</Typography>
              <Typography className={classes.textHeader3}>
                {getQuantityValue(schema.indoorFlooringLnD_threeBHK)} {getQuantityUnit(schema.indoorFlooringLnD_threeBHK)}
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography className={classes.textHeader5}>4 BHK</Typography>
              <Typography className={classes.textHeader3}>
                {getQuantityValue(schema.indoorFlooringLnD_fourBHK)} {getQuantityUnit(schema.indoorFlooringLnD_fourBHK)}
              </Typography>
            </Grid>
          </Grid>
          <Grid container item xs={12} style={{ paddingTop: '1%' }}>
            <Grid item xs>
              <Typography className={classes.textHeader5}>Studio Carpet Area</Typography>
              <Typography className={classes.textHeader3}>
                {getQuantityValue(schema.indoorFlooringLnD_studioCarpetArea)} {getQuantityUnit(schema.indoorFlooringLnD_studioCarpetArea)}
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography className={classes.textHeader5}>1 BHK Carpet Area</Typography>
              <Typography className={classes.textHeader3}>
                {getQuantityValue(schema.indoorFlooringLnD_oneBhkCarpetArea)} {getQuantityUnit(schema.indoorFlooringLnD_oneBhkCarpetArea)}
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography className={classes.textHeader5}>2 BHK Carpet Area</Typography>
              <Typography className={classes.textHeader3}>
                {getQuantityValue(schema.indoorFlooringLnD_twoBhkCarpetArea)} {getQuantityUnit(schema.indoorFlooringLnD_twoBhkCarpetArea)}
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography className={classes.textHeader5}>3 BHK Carpet Area</Typography>
              <Typography className={classes.textHeader3}>
                {getQuantityValue(schema.indoorFlooringLnD_threeBhkCarpetArea)} {getQuantityUnit(schema.indoorFlooringLnD_threeBhkCarpetArea)}
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography className={classes.textHeader5}>4 BHK Carpet Area</Typography>
              <Typography className={classes.textHeader3}>
                {getQuantityValue(schema.indoorFlooringLnD_fourBhkCarpetArea)} {getQuantityUnit(schema.indoorFlooringLnD_fourBhkCarpetArea)}
              </Typography>
            </Grid>
          </Grid>
          {/* Penthouse */}
          <Grid container item xs={12} style={{ paddingTop: '1%' }}>
            <Grid item xs>
              <Typography className={classes.textHeader5}>PH 1 BHK</Typography>
              <Typography className={classes.textHeader3}>
                {getQuantityValue(schema.indoorFlooringLnD_penthouseOneBHK)} {getQuantityUnit(schema.indoorFlooringLnD_penthouseOneBHK)}
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography className={classes.textHeader5}>PH 2 BHK</Typography>
              <Typography className={classes.textHeader3}>
                {getQuantityValue(schema.indoorFlooringLnD_penthouseTwoBHK)} {getQuantityUnit(schema.indoorFlooringLnD_penthouseTwoBHK)}
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography className={classes.textHeader5}>PH 3 BHK</Typography>
              <Typography className={classes.textHeader3}>
                {getQuantityValue(schema.indoorFlooringLnD_penthouseThreeBHK)} {getQuantityUnit(schema.indoorFlooringLnD_penthouseThreeBHK)}
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography className={classes.textHeader5}>PH 4 BHK</Typography>
              <Typography className={classes.textHeader3}>
                {getQuantityValue(schema.indoorFlooringLnD_penthouseFourBHK)} {getQuantityUnit(schema.indoorFlooringLnD_penthouseFourBHK)}
              </Typography>
            </Grid>
          </Grid>
          <Grid container item xs={12} style={{ paddingTop: '1%' }}>
            <Grid item xs>
              <Typography className={classes.textHeader5}>PH 1 BHK Carpet Area</Typography>
              <Typography className={classes.textHeader3}>
                {getQuantityValue(schema.indoorFlooringLnD_penthouseOneBhkCarpetArea)} {getQuantityUnit(schema.indoorFlooringLnD_penthouseOneBhkCarpetArea)}
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography className={classes.textHeader5}>PH 2 BHK Carpet Area</Typography>
              <Typography className={classes.textHeader3}>
                {getQuantityValue(schema.indoorFlooringLnD_penthouseTwoBhkCarpetArea)} {getQuantityUnit(schema.indoorFlooringLnD_penthouseTwoBhkCarpetArea)}
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography className={classes.textHeader5}>PH 3 BHK Carpet Area</Typography>
              <Typography className={classes.textHeader3}>
                {getQuantityValue(schema.indoorFlooringLnD_penthouseThreeBhkCarpetArea)} {getQuantityUnit(schema.indoorFlooringLnD_penthouseThreeBhkCarpetArea)}
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography className={classes.textHeader5}>PH 4 BHK Carpet Area</Typography>
              <Typography className={classes.textHeader3}>
                {getQuantityValue(schema.indoorFlooringLnD_penthouseFourBhkCarpetArea)} {getQuantityUnit(schema.indoorFlooringLnD_penthouseFourBhkCarpetArea)}
              </Typography>
            </Grid>
          </Grid>
        </>
      )}
      {/* Villa */}
      {subCategory && subCategory === 'villa' && (
        <>
          <Grid container item xs={12} style={{ paddingTop: '1%' }}>
            <Grid item xs>
              <Typography className={classes.textHeader5}>1 BHK</Typography>
              <Typography className={classes.textHeader3}>
                {getQuantityValue(schema.indoorFlooringLnD_villaOneBHK)} {getQuantityUnit(schema.indoorFlooringLnD_villaOneBHK)}
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography className={classes.textHeader5}>2 BHK</Typography>
              <Typography className={classes.textHeader3}>
                {getQuantityValue(schema.indoorFlooringLnD_villaTwoBHK)} {getQuantityUnit(schema.indoorFlooringLnD_villaTwoBHK)}
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography className={classes.textHeader5}>3 BHK</Typography>
              <Typography className={classes.textHeader3}>
                {getQuantityValue(schema.indoorFlooringLnD_villaThreeBHK)} {getQuantityUnit(schema.indoorFlooringLnD_villaThreeBHK)}
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography className={classes.textHeader5}>4 BHK</Typography>
              <Typography className={classes.textHeader3}>
                {getQuantityValue(schema.indoorFlooringLnD_villaFourBHK)} {getQuantityUnit(schema.indoorFlooringLnD_villaFourBHK)}
              </Typography>
            </Grid>
          </Grid>
          <Grid container item xs={12} style={{ paddingTop: '1%' }}>
            <Grid item xs>
              <Typography className={classes.textHeader5}>1 BHK Carpet Area</Typography>
              <Typography className={classes.textHeader3}>
                {getQuantityValue(schema.indoorFlooringLnD_villaOneBHKCarpetArea)} {getQuantityUnit(schema.indoorFlooringLnD_villaOneBHKCarpetArea)}
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography className={classes.textHeader5}>2 BHK Carpet Area</Typography>
              <Typography className={classes.textHeader3}>
                {getQuantityValue(schema.indoorFlooringLnD_villaTwoBHKCarpetArea)} {getQuantityUnit(schema.indoorFlooringLnD_villaTwoBHKCarpetArea)}
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography className={classes.textHeader5}>3 BHK Carpet Area</Typography>
              <Typography className={classes.textHeader3}>
                {getQuantityValue(schema.indoorFlooringLnD_villaThreeBHKCarpetArea)} {getQuantityUnit(schema.indoorFlooringLnD_villaThreeBHKCarpetArea)}
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography className={classes.textHeader5}>4 BHK Carpet Area</Typography>
              <Typography className={classes.textHeader3}>
                {getQuantityValue(schema.indoorFlooringLnD_villaFourBHKCarpetArea)} {getQuantityUnit(schema.indoorFlooringLnD_villaFourBHKCarpetArea)}
              </Typography>
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  );
};
export default IndoorFlooringLnDProjectInputs;
