import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import Header from '../../../common/header/Header';
import TabButtonGrp from '../common/ToogleButtonGrp';
import CommonEditCertification from './commonEditCertification';
import useApiRequest from '../../../hooks/useApiRequest';
import ResidentHealthAndWellBeing from './ResidentHealthAndWellBeing';
import { IGBCCategories } from '../utils/types';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexGrow: 10,
    marginTop: '8%',
    marginLeft: '6%',
    marginRight: '6%',
  },
  content: {
    flexGrow: 1,
    marginLeft: '8%',
    marginRight: '8%',
    marginTop: '2%',
  },
}));

export default function EditCertification(props) {
  const classes = useStyles();
  let history = useHistory();
  const params = new URLSearchParams(props.location.search);
  const activeStep = params.get('category');
  console.log(activeStep, 'active step in');

  const [next, setNext] = useState('');
  const [loader, setLoader] = useState(true);
  const [categoriesData, setCategoriesData] = useState('');

  const [{ status, response }, makeRequest] = useApiRequest(`/igbc-category`, {
    verb: 'get',
  });

  if (status === null) makeRequest();

  const changeUrl = (projectID, category) => {
    if (category !== undefined) {
      setLoader(true);
      window.scroll({ top: 0, left: 0, behavior: 'smooth' });
      history.push(`/projects/${projectID}/certification/edit?category=${category}`);
    }
  };

  useEffect(() => {
    if (response && response.data && response.data && response.data.data.data) {
      setCategoriesData(response.data.data.data);
    }
  }, [response]);

  return (
    <>
      <Header />
      <div className={classes.root}>
        <TabButtonGrp
          activeStep={activeStep}
          projectId={props.match.params.id}
          categoriesData={categoriesData}
          handleURL={(projectID, category) => changeUrl(projectID, category)}
          handleNext={(category) => setNext(category)}
        />
      </div>
      <main className={classes.content}>
        {activeStep && activeStep !== IGBCCategories.residentHealth ? (
          <CommonEditCertification
            activeStep={activeStep}
            projectId={props.match.params.id}
            handleNext={() => {
              changeUrl(props.match.params.id, next);
            }}
            loader={loader}
            setLoader={(v) => setLoader(v)}
          />
        ) : (
          <ResidentHealthAndWellBeing
            projectId={props.match.params.id}
            handleNext={() => {
              changeUrl(props.match.params.id, next);
            }}
            next={next}
          />
        )}
      </main>
    </>
  );
}
