import React from 'react';
import { Typography, Grid } from '@material-ui/core';
import useStyles from './styles';
import { useInterventionDetailsContext } from '../context/InterventionDetails';

const IndoorFlooringBedroomProjectInputs = (props) => {
  const classes = useStyles();
  const {
    schema,
    get: { getQuantityValue, getQuantityUnit },
  } = useInterventionDetailsContext();
  let subCategory = getQuantityValue(schema.indoorFlooringBedroom_subcategory);
  return (
    <Grid container item xs={12} direction="column">
      <Typography className={classes.textHeader1}>Project Inputs</Typography>
      {subCategory !== 'villa' && (
        <>
          <Grid container item xs={12} style={{ paddingTop: '1%' }}>
            <Grid item xs>
              <Typography className={classes.textHeader5}>Studio</Typography>
              <Typography className={classes.textHeader3}>
                {getQuantityValue(schema.indoorFlooringBedroom_studio)} {getQuantityUnit(schema.indoorFlooringBedroom_studio)}
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography className={classes.textHeader5}>1 BHK</Typography>
              <Typography className={classes.textHeader3}>
                {getQuantityValue(schema.indoorFlooringBedroom_oneBHK)} {getQuantityUnit(schema.indoorFlooringBedroom_oneBHK)}
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography className={classes.textHeader5}>2 BHK</Typography>
              <Typography className={classes.textHeader3}>
                {getQuantityValue(schema.indoorFlooringBedroom_twoBHK)} {getQuantityUnit(schema.indoorFlooringBedroom_twoBHK)}
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography className={classes.textHeader5}>3 BHK</Typography>
              <Typography className={classes.textHeader3}>
                {getQuantityValue(schema.indoorFlooringBedroom_threeBHK)} {getQuantityUnit(schema.indoorFlooringBedroom_threeBHK)}
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography className={classes.textHeader5}>4 BHK</Typography>
              <Typography className={classes.textHeader3}>
                {getQuantityValue(schema.indoorFlooringBedroom_fourBHK)} {getQuantityUnit(schema.indoorFlooringBedroom_fourBHK)}
              </Typography>
            </Grid>
          </Grid>
          <Grid container item xs={12} style={{ paddingTop: '1%' }}>
            <Grid item xs>
              <Typography className={classes.textHeader5}>Studio Carpet Area</Typography>
              <Typography className={classes.textHeader3}>
                {getQuantityValue(schema.indoorFlooringBedroom_studioCarpetArea)} {getQuantityUnit(schema.indoorFlooringBedroom_studioCarpetArea)}
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography className={classes.textHeader5}>1 BHK Carpet Area</Typography>
              <Typography className={classes.textHeader3}>
                {getQuantityValue(schema.indoorFlooringBedroom_oneBhkCarpetArea)} {getQuantityUnit(schema.indoorFlooringBedroom_oneBhkCarpetArea)}
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography className={classes.textHeader5}>2 BHK Carpet Area</Typography>
              <Typography className={classes.textHeader3}>
                {getQuantityValue(schema.indoorFlooringBedroom_twoBhkCarpetArea)} {getQuantityUnit(schema.indoorFlooringBedroom_twoBhkCarpetArea)}
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography className={classes.textHeader5}>3 BHK Carpet Area</Typography>
              <Typography className={classes.textHeader3}>
                {getQuantityValue(schema.indoorFlooringBedroom_threeBhkCarpetArea)} {getQuantityUnit(schema.indoorFlooringBedroom_threeBhkCarpetArea)}
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography className={classes.textHeader5}>4 BHK Carpet Area</Typography>
              <Typography className={classes.textHeader3}>
                {getQuantityValue(schema.indoorFlooringBedroom_fourBhkCarpetArea)} {getQuantityUnit(schema.indoorFlooringBedroom_fourBhkCarpetArea)}
              </Typography>
            </Grid>
          </Grid>
          {/* Penthouse */}
          <Grid container item xs={12} style={{ paddingTop: '1%' }}>
            <Grid item xs>
              <Typography className={classes.textHeader5}>PH 1 BHK</Typography>
              <Typography className={classes.textHeader3}>
                {getQuantityValue(schema.indoorFlooringBedroom_penthouseOneBHK)} {getQuantityUnit(schema.indoorFlooringBedroom_penthouseOneBHK)}
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography className={classes.textHeader5}>PH 2 BHK</Typography>
              <Typography className={classes.textHeader3}>
                {getQuantityValue(schema.indoorFlooringBedroom_penthouseTwoBHK)} {getQuantityUnit(schema.indoorFlooringBedroom_penthouseTwoBHK)}
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography className={classes.textHeader5}>PH 3 BHK</Typography>
              <Typography className={classes.textHeader3}>
                {getQuantityValue(schema.indoorFlooringBedroom_penthouseThreeBHK)} {getQuantityUnit(schema.indoorFlooringBedroom_penthouseThreeBHK)}
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography className={classes.textHeader5}>PH 4 BHK</Typography>
              <Typography className={classes.textHeader3}>
                {getQuantityValue(schema.indoorFlooringBedroom_penthouseFourBHK)} {getQuantityUnit(schema.indoorFlooringBedroom_penthouseFourBHK)}
              </Typography>
            </Grid>
          </Grid>
          <Grid container item xs={12} style={{ paddingTop: '1%' }}>
            <Grid item xs>
              <Typography className={classes.textHeader5}>PH 1 BHK Carpet Area</Typography>
              <Typography className={classes.textHeader3}>
                {getQuantityValue(schema.indoorFlooringBedroom_penthouseOneBhkCarpetArea)}{' '}
                {getQuantityUnit(schema.indoorFlooringBedroom_penthouseOneBhkCarpetArea)}
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography className={classes.textHeader5}>PH 2 BHK Carpet Area</Typography>
              <Typography className={classes.textHeader3}>
                {getQuantityValue(schema.indoorFlooringBedroom_penthouseTwoBhkCarpetArea)}{' '}
                {getQuantityUnit(schema.indoorFlooringBedroom_penthouseTwoBhkCarpetArea)}
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography className={classes.textHeader5}>PH 3 BHK Carpet Area</Typography>
              <Typography className={classes.textHeader3}>
                {getQuantityValue(schema.indoorFlooringBedroom_penthouseThreeBhkCarpetArea)}{' '}
                {getQuantityUnit(schema.indoorFlooringBedroom_penthouseThreeBhkCarpetArea)}
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography className={classes.textHeader5}>PH 4 BHK Carpet Area</Typography>
              <Typography className={classes.textHeader3}>
                {getQuantityValue(schema.indoorFlooringBedroom_penthouseFourBhkCarpetArea)}{' '}
                {getQuantityUnit(schema.indoorFlooringBedroom_penthouseFourBhkCarpetArea)}
              </Typography>
            </Grid>
          </Grid>
        </>
      )}
      {/* Villa */}
      {subCategory && subCategory === 'villa' && (
        <>
          <Grid container item xs={12} style={{ paddingTop: '1%' }}>
            <Grid item xs>
              <Typography className={classes.textHeader5}>1 BHK</Typography>
              <Typography className={classes.textHeader3}>
                {getQuantityValue(schema.indoorFlooringBedroom_villaOneBHK)} {getQuantityUnit(schema.indoorFlooringBedroom_villaOneBHK)}
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography className={classes.textHeader5}>2 BHK</Typography>
              <Typography className={classes.textHeader3}>
                {getQuantityValue(schema.indoorFlooringBedroom_villaTwoBHK)} {getQuantityUnit(schema.indoorFlooringBedroom_villaTwoBHK)}
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography className={classes.textHeader5}>3 BHK</Typography>
              <Typography className={classes.textHeader3}>
                {getQuantityValue(schema.indoorFlooringBedroom_villaThreeBHK)} {getQuantityUnit(schema.indoorFlooringBedroom_villaThreeBHK)}
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography className={classes.textHeader5}>4 BHK</Typography>
              <Typography className={classes.textHeader3}>
                {getQuantityValue(schema.indoorFlooringBedroom_villaFourBHK)} {getQuantityUnit(schema.indoorFlooringBedroom_villaFourBHK)}
              </Typography>
            </Grid>
          </Grid>
          <Grid container item xs={12} style={{ paddingTop: '1%' }}>
            <Grid item xs>
              <Typography className={classes.textHeader5}>1 BHK Carpet Area</Typography>
              <Typography className={classes.textHeader3}>
                {getQuantityValue(schema.indoorFlooringBedroom_villaOneBHKCarpetArea)} {getQuantityUnit(schema.indoorFlooringBedroom_villaOneBHKCarpetArea)}
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography className={classes.textHeader5}>2 BHK Carpet Area</Typography>
              <Typography className={classes.textHeader3}>
                {getQuantityValue(schema.indoorFlooringBedroom_villaTwoBHKCarpetArea)} {getQuantityUnit(schema.indoorFlooringBedroom_villaTwoBHKCarpetArea)}
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography className={classes.textHeader5}>3 BHK Carpet Area</Typography>
              <Typography className={classes.textHeader3}>
                {getQuantityValue(schema.indoorFlooringBedroom_villaThreeBHKCarpetArea)} {getQuantityUnit(schema.indoorFlooringBedroom_villaThreeBHKCarpetArea)}
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography className={classes.textHeader5}>4 BHK Carpet Area</Typography>
              <Typography className={classes.textHeader3}>
                {getQuantityValue(schema.indoorFlooringBedroom_villaFourBHKCarpetArea)} {getQuantityUnit(schema.indoorFlooringBedroom_villaFourBHKCarpetArea)}
              </Typography>
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  );
};
export default IndoorFlooringBedroomProjectInputs;
