import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Card } from '@material-ui/core';
import numberWithCommas from '../../../../../utils/numberToComma';
import ErrorIcon from '@material-ui/icons/Info';
import Tooltip from '../../../../common/tooltip/Tooltip';
import CustomizedTooltips from '../../../../common/tooltip/TooltipV2';
import { useAppMetafireUtilities } from '../../../../../metafire/hooks/useAppMetafireUtilities';
import Bold from '../../../../../v2/utils/Bold';

function CommonCardNew({
  capex,
  capexTotalValue,
  capexTotalConvertedValue,
  title,
  inputs,
  height,
  width,
  tooltipBody,
  tooltipReady,
  selectedIntervention,
  showTotalCapex,
}) {
  const {
    getRoundOffValue,
    utils: { getUiRoundTag, getDecimalPlacesByTag, defaultRoundOff },
  } = useAppMetafireUtilities();
  const useStyles = makeStyles((theme) => ({
    paper1: {
      outline: 'none',
      // height: height,
      // minWidth: width,
      padding: theme.spacing(2, 1, 2, 4),
      background: '#FFFFFF',
      borderRadius: '10px',
      boxShadow: '2px 2px 8px rgba(24, 50, 119, 0.1)',
    },
    alignTypo: {
      marginTop: '5%',
    },
    alignUnits: {
      paddingLeft: 10,
    },
    errorIcon: {
      color: '#66E3BE',
      cursor: 'pointer',
      height: '70%',
      marginTop: '2%',
    },
    alignTypo2: {
      marginTop: '3%',
    },
  }));
  const classes = useStyles();

  function isString(value) {
    return typeof value === 'string';
  }

  const getDisplayValue = (item) => {
    // If item or its value is undefined or null, return '-' as the display value
    if (item?.value === undefined || item?.value === null) {
      return '-';
    }

    // If item value is not a number (NaN), return the value directly as it's likely a string
    if (isNaN(item?.value)) return item?.value;

    // Function to extract round value from tags if 'uiRound:' is present
    const getRoundValueFromTags = (item) => {
      const roundTag = getUiRoundTag(item);
      const roundValue = getDecimalPlacesByTag(roundTag);
      return roundValue || defaultRoundOff;
    };
    const roundValue = getRoundValueFromTags(item);

    // If the item value is not a string, proceed to handle numeric formatting
    if (!isString(item?.value)) {
      // Special handling for specific items with labels 'IRR' and 'Payback Period'
      if (item?.label === 'IRR' || item?.label === 'Payback Period') {
        // If the item contains payback period value and the value is negative, return '-' instead of the actual value
        return item?.value < 0 && item?.label === 'Payback Period' ? '-' : `${numberWithCommas(getRoundOffValue(item?.value, roundValue))}`;
      }

      // For all other legal cases, round off the value and format it with commas for thousands
      return `${numberWithCommas(getRoundOffValue(item?.value, roundValue))}`;
    } else if (item?.value) {
      return item?.value;
    }

    return '-';
  };

  const getValue = (item) => {
    if (!item) return null;
    const isSDGNumber = item.label === 'SDG Number';
    const value = item.value;
    if (isSDGNumber) {
      return Array.isArray(value) ? value.join(' & ') : value;
    }
    if (Array.isArray(value)) {
      return value.map((val, index) => (
        <b
          key={index}
          style={{
            color: val < 0 ? '#26E0A3' : 'black',
          }}
        >
          {isNaN(val) ? val : numberWithCommas(getRoundOffValue(val))}
        </b>
      ));
    }
    return (
      <b
        style={{
          color: value < 0 ? '#26E0A3' : 'black',
        }}
      >
        {getDisplayValue(item)}
      </b>
    );
  };

  return (
    <>
      <Card className={classes.paper1}>
        <Grid container direction="row" xs={12}>
          {inputs &&
            inputs?.map((item, index) => {
              return (
                <>
                  {/* {item.label !== `Tank Size - Default Recommendation` && ( */}
                  <Grid xs={6} container direction="row" style={index > 1 ? { marginTop: '6%' } : null}>
                    <Grid xs={12} container direction="row">
                      <Typography color="textSecondary">{item?.label?.toLowerCase() === 'capex' ? 'Capex Increase' : item?.label} </Typography>
                      {tooltipReady && (item?.label === 'SDG Number' || (selectedIntervention && selectedIntervention === 'Sanitary Napkin Incinerator')) ? (
                        <>
                          <CustomizedTooltips
                            icon="info"
                            title={
                              <>
                                <Typography>{tooltipBody && tooltipBody ? tooltipBody : ''}</Typography>
                              </>
                            }
                            placement="right"
                          />
                        </>
                      ) : (
                        ''
                      )}
                    </Grid>
                    <Grid xs={12} direction="row">
                      <Typography className={classes.alignTypo} style={Math.sign(item?.value) === -1 ? {} : {}}>
                        <Bold isHighlight={true} value={getValue(item)} unit={item?.unit} />
                      </Typography>
                    </Grid>
                  </Grid>
                  {index === 1 &&
                    (title === 'Intervention Results' ? (
                      <Grid xs={12} style={{ marginTop: '2%' }}>
                        {capex ? (
                          <Typography style={{ fontSize: '11px' }}>
                            <b style={{ marginRight: '2%' }}>{numberWithCommas(capexTotalValue[0]?.value)}</b>
                            {`Rs`} in Total CAPEX
                          </Typography>
                        ) : (
                          <Typography style={{ fontSize: '11px' }}>
                            <b style={{ marginRight: '2%' }}>{numberWithCommas(capexTotalConvertedValue[0]?.value)}</b>
                            {`Rs/Sqft`} in Total CAPEX
                          </Typography>
                        )}
                      </Grid>
                    ) : (
                      <Grid xs={12} style={{ marginTop: '2%', visibility: 'hidden' }}>
                        <Typography style={{ fontSize: '11px' }}>
                          <b style={{ marginRight: '2%' }}>-</b>
                          {`-`}
                        </Typography>
                      </Grid>
                    ))}

                  {/* )} */}
                </>
              );
            })}
        </Grid>
      </Card>
    </>
  );
}
export default CommonCardNew;
{
  /* <Grid xs={6} direction="row">
                      <Typography className={classes.alignTypo} style={Math.sign(item.value) === -1 ? {} : {}}>
                        {item?.label?.toLowerCase() === 'capex' ? <Typography style={{ fontSize: '11px', }}>{item?.label?.toLowerCase() === 'capex' ? 'Capex Increase' : item?.label} </Typography> : ''}
                      </Typography>

                    </Grid> */
}
