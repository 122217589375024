import React, { useEffect, useState } from 'react';
import { Typography, Grid, Box, TextField, InputAdornment } from '@material-ui/core';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import ResetFields from '../common/textFiledwithReset';
import { useInterventionDetailsContext } from '../context/InterventionDetails';
import useStyles from './styles';
import InfoIcon from '@material-ui/icons/Info';

const ScCustomBasicParameters = (props) => {
  const classes = useStyles();
  const {
    schema,
    get: { getQuantityValue, getQuantityDefaultValue, getQuantityLabel, getQuantityUnit },
    set: { updateQuantityValue },
  } = useInterventionDetailsContext();
  const [customVerticalValue, setcustomVerticalValue] = useState(0);
  const [isVerticalCustomSelected, setisVerticalCustomSelected] = useState(false);
  const [customSubStructureValue, setcustomSubStructureValue] = useState(0);
  const [isSubStructureCustomSelected, setisSubStructureCustomSelected] = useState(false);
  const [customSuperStructureValue, setcustomSuperStructureValue] = useState(0);
  const [isSuperStructureCustomSelected, setisSuperStructureCustomSelected] = useState(false);

  let subStructureQuantity =
    getQuantityValue(schema.sc_typeOfConstruction) === 'aluminium form work' ? schema.sc__pi_afSubStructureQuantity : schema.sc__pi_subStructureQuantity;
  let superStructureQuantity =
    getQuantityValue(schema.sc_typeOfConstruction) === 'aluminium form work' ? schema.sc__pi_afSuperStructureQuantity : schema.sc__pi_superStructureQuantity;
  let verticalQuantity = schema.sc__pi_afVerticalQuantity;
  let mineralAdmixture = getQuantityValue(schema.sc__pi_mineralAdmixture);
  let substructureBasedOnAdmixture = mineralAdmixture === 'ggbs' ? schema.sc__pi_subStructureggbsPercentage : schema.sc__pi_subStructureFlyAsh;
  let subStructurePercentage = getQuantityValue(substructureBasedOnAdmixture);
  let superStructureBasedOnAdmixture = mineralAdmixture === 'ggbs' ? schema.sc__pi_superStructureggbsPercentage : schema.sc__pi_superStructureFlyAsh;
  let superStructurePercentage = getQuantityValue(superStructureBasedOnAdmixture);
  let verticalBasedOnAdmixture = mineralAdmixture === 'ggbs' ? schema.sc__pi_verticaleggbsPercentage : schema.sc__pi_verticalFlyAsh;
  let verticalPercentage = getQuantityValue(verticalBasedOnAdmixture);
  let isVerticalVisible = getQuantityValue(schema.sc_typeOfConstruction) === 'aluminium form work' ? true : false;

  const handleCustomVerticalBoxClick = () => {
    setisVerticalCustomSelected(true);
    updateQuantityValue(verticalBasedOnAdmixture, customVerticalValue);
  };

  const handleVerticalBoxClick = (value) => {
    setisVerticalCustomSelected(false);
    updateQuantityValue(verticalBasedOnAdmixture, value);
  };

  const handleCustomSubStructureBoxClick = () => {
    setisSubStructureCustomSelected(true);
    updateQuantityValue(substructureBasedOnAdmixture, customSubStructureValue);
  };

  const handleSubStructureBoxClick = (value) => {
    setisSubStructureCustomSelected(false);
    updateQuantityValue(substructureBasedOnAdmixture, value);
  };

  const handleCustomSuperStructureBoxClick = () => {
    setisSuperStructureCustomSelected(true);
    updateQuantityValue(superStructureBasedOnAdmixture, customSuperStructureValue);
  };

  const handleSuperStructureBoxClick = (value) => {
    setisSuperStructureCustomSelected(false);
    updateQuantityValue(superStructureBasedOnAdmixture, value);
  };

  useEffect(() => {
    if (verticalPercentage !== 20 && verticalPercentage !== 25 && verticalPercentage !== 30 && verticalPercentage !== 35) {
      setisVerticalCustomSelected(true);
      setcustomVerticalValue(verticalPercentage);
    }
    if (subStructurePercentage !== 20 && subStructurePercentage !== 25 && subStructurePercentage !== 30 && subStructurePercentage !== 35) {
      setisSubStructureCustomSelected(true);
      setcustomSubStructureValue(subStructurePercentage);
    }
    if (superStructurePercentage !== 20 && superStructurePercentage !== 25 && superStructurePercentage !== 30 && superStructurePercentage !== 35) {
      setisSuperStructureCustomSelected(true);
      setcustomSuperStructureValue(superStructurePercentage);
    }
  }, []);

  return (
    <Grid container direction="column" alignItems="left" item xs={12}>
      <Grid container item xs={12}>
        <Grid item xs={4} direction="column">
          <Grid container item xs={12} style={{ paddingBottom: '10px' }}>
            <Typography className={classes.textHeader5}>{getQuantityLabel(subStructureQuantity)}</Typography>
          </Grid>
          <Grid xs={12} container alignItems="flex-start" direction="row" style={{ display: 'flex', alignItems: 'center' }}>
            <Grid xs={7}>
              <ResetFields
                id="outlined-basic"
                variant="outlined"
                value={getQuantityValue(subStructureQuantity)}
                placeholder=""
                onChange={(e) => {
                  e.preventDefault();
                  updateQuantityValue(subStructureQuantity, e.target.value);
                }}
                fullWidth
                required
                onClick={(e) => {
                  e.preventDefault();
                  updateQuantityValue(subStructureQuantity, getQuantityDefaultValue(subStructureQuantity));
                }}
                disabled={false}
              />
            </Grid>
            <Grid xs={3} className={classes.grid} style={{ paddingLeft: '2%' }}>
              <Typography variant="h6">{getQuantityUnit(subStructureQuantity)}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4} direction="column">
          <Grid container item xs={12} style={{ paddingBottom: '10px' }}>
            <Typography className={classes.textHeader5}>{getQuantityLabel(superStructureQuantity)}</Typography>
          </Grid>
          <Grid xs={12} container alignItems="flex-start" direction="row" style={{ display: 'flex', alignItems: 'center' }}>
            <Grid xs={7}>
              <ResetFields
                id="outlined-basic"
                variant="outlined"
                value={getQuantityValue(superStructureQuantity)}
                placeholder=""
                onChange={(e) => {
                  e.preventDefault();
                  updateQuantityValue(superStructureQuantity, e.target.value);
                }}
                fullWidth
                required
                onClick={(e) => {
                  e.preventDefault();
                  updateQuantityValue(superStructureQuantity, getQuantityDefaultValue(superStructureQuantity));
                }}
                disabled={false}
              />
            </Grid>
            <Grid xs={3} className={classes.grid} style={{ paddingLeft: '2%' }}>
              <Typography variant="h6">{getQuantityUnit(superStructureQuantity)}</Typography>
            </Grid>
          </Grid>
        </Grid>
        {isVerticalVisible && (
          <Grid item xs={4} direction="column">
            <Grid container item xs={12} style={{ paddingBottom: '10px' }}>
              <Typography className={classes.textHeader5}>{getQuantityLabel(verticalQuantity)}</Typography>
            </Grid>
            <Grid xs={12} container alignItems="flex-start" direction="row" style={{ display: 'flex', alignItems: 'center' }}>
              <Grid xs={7}>
                <ResetFields
                  id="outlined-basic"
                  variant="outlined"
                  value={getQuantityValue(verticalQuantity)}
                  placeholder=""
                  onChange={(e) => {
                    e.preventDefault();
                    updateQuantityValue(verticalQuantity, e.target.value);
                  }}
                  fullWidth
                  required
                  onClick={(e) => {
                    e.preventDefault();
                    updateQuantityValue(verticalQuantity, getQuantityDefaultValue(verticalQuantity));
                  }}
                  disabled={false}
                />
              </Grid>
              <Grid xs={3} className={classes.grid} style={{ paddingLeft: '2%' }}>
                <Typography variant="h6">{getQuantityUnit(verticalQuantity)}</Typography>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
      <Grid container item xs={12}>
        <Grid container item xs={12} style={{ paddingTop: '30px' }}>
          <Typography className={classes.textHeader1}>{getQuantityLabel(schema.sc__pi_mineralAdmixture)}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography className={classes.textHeader3}>
            {mineralAdmixture === 'ggbs' && (
              <RadioButtonCheckedIcon className={classes.selectedRadiobox} onClick={() => updateQuantityValue(schema.sc__pi_mineralAdmixture, 'ggbs')} />
            )}
            {mineralAdmixture !== 'ggbs' && (
              <RadioButtonUncheckedIcon className={classes.nonselectedRadiobox} onClick={() => updateQuantityValue(schema.sc__pi_mineralAdmixture, 'ggbs')} />
            )}
            GGBS / Slag
          </Typography>
          <Typography className={classes.textHeader3}>
            {mineralAdmixture === 'flyAsh' && (
              <RadioButtonCheckedIcon className={classes.selectedRadiobox} onClick={() => updateQuantityValue(schema.sc__pi_mineralAdmixture, 'flyAsh')} />
            )}
            {mineralAdmixture !== 'flyAsh' && (
              <RadioButtonUncheckedIcon className={classes.nonselectedRadiobox} onClick={() => updateQuantityValue(schema.sc__pi_mineralAdmixture, 'flyAsh')} />
            )}
            Fly Ash
          </Typography>
        </Grid>
      </Grid>
      <Grid container item xs={12} style={{ paddingBottom: '10px', paddingTop: '30px' }}>
        <Typography className={classes.textHeader1}>Mineral Admixture Content</Typography>
      </Grid>
      <Grid container item xs={12} style={{ paddingBottom: '10px' }}>
        <Typography className={classes.textHeader3}>{getQuantityLabel(schema.sc__pi_subStructureggbsPercentage)}</Typography>
      </Grid>
      <Grid container item xs={12}>
        <Grid item>
          <Box
            className={subStructurePercentage === 20 ? classes.boxStyleSelected : classes.boxStyleNonselected}
            onClick={() => handleSubStructureBoxClick(20)}
          >
            <Typography className={subStructurePercentage === 20 ? classes.pillTextSelected : classes.pillTextNonSelected}>20%</Typography>
          </Box>
        </Grid>
        <Grid item>
          <Box
            className={subStructurePercentage === 25 ? classes.boxStyleSelected : classes.boxStyleNonselected}
            onClick={() => handleSubStructureBoxClick(25)}
          >
            <Typography className={subStructurePercentage === 25 ? classes.pillTextSelected : classes.pillTextNonSelected}>25%</Typography>
          </Box>
        </Grid>
        <Grid item>
          <Box
            className={subStructurePercentage === 30 ? classes.boxStyleSelected : classes.boxStyleNonselected}
            onClick={() => handleSubStructureBoxClick(30)}
          >
            <Typography className={subStructurePercentage === 30 ? classes.pillTextSelected : classes.pillTextNonSelected}>30%</Typography>
          </Box>
        </Grid>
        <Grid item>
          <Box
            className={subStructurePercentage === 35 ? classes.boxStyleSelected : classes.boxStyleNonselected}
            onClick={() => handleSubStructureBoxClick(35)}
          >
            <Typography className={subStructurePercentage === 35 ? classes.pillTextSelected : classes.pillTextNonSelected}>35%</Typography>
          </Box>
        </Grid>
        <Grid item>
          <Box
            className={isSubStructureCustomSelected ? classes.boxStyleSelected : classes.boxStyleNonselected}
            onClick={() => handleCustomSubStructureBoxClick()}
          >
            {!isSubStructureCustomSelected ? (
              <Typography className={classes.pillTextNonSelected}>Custom</Typography>
            ) : (
              <TextField
                type="text"
                value={customSubStructureValue}
                onChange={(e) => {
                  const value = e.target.value;
                  if (/^(\d+(\.\d*)?|\.?\d*)$/.test(value)) {
                    updateQuantityValue(substructureBasedOnAdmixture, parseFloat(value) || 0);
                    setcustomSubStructureValue(value);
                  }
                }}
                variant="outlined"
                size="small"
                placeholder="Enter value"
                style={{ width: '60px' }}
                className={classes.customTextField}
                InputProps={{
                  disableUnderline: false,
                  endAdornment: (
                    <InputAdornment position="end">
                      <Typography style={{ color: '#FFFFFF', fontWeight: 500 }}>%</Typography>
                    </InputAdornment>
                  ),
                  inputProps: {
                    style: {
                      appearance: 'none',
                      MozAppearance: 'textfield',
                    },
                  },
                }}
              />
            )}
          </Box>
        </Grid>
      </Grid>
      <Grid container item xs={12} style={{ paddingBottom: '10px' }}>
        <Typography className={classes.textHeader3}>{getQuantityLabel(superStructureBasedOnAdmixture)}</Typography>
      </Grid>
      <Grid container item xs={12}>
        <Grid item>
          <Box
            className={superStructurePercentage === 20 ? classes.boxStyleSelected : classes.boxStyleNonselected}
            onClick={() => handleSuperStructureBoxClick(20)}
          >
            <Typography className={superStructurePercentage === 20 ? classes.pillTextSelected : classes.pillTextNonSelected}>20%</Typography>
          </Box>
        </Grid>
        <Grid item>
          <Box
            className={superStructurePercentage === 25 ? classes.boxStyleSelected : classes.boxStyleNonselected}
            onClick={() => handleSuperStructureBoxClick(25)}
          >
            <Typography className={superStructurePercentage === 25 ? classes.pillTextSelected : classes.pillTextNonSelected}>25%</Typography>
          </Box>
        </Grid>
        <Grid item>
          <Box
            className={superStructurePercentage === 30 ? classes.boxStyleSelected : classes.boxStyleNonselected}
            onClick={() => handleSuperStructureBoxClick(30)}
          >
            <Typography className={superStructurePercentage === 30 ? classes.pillTextSelected : classes.pillTextNonSelected}>30%</Typography>
          </Box>
        </Grid>
        <Grid item>
          <Box
            className={superStructurePercentage === 35 ? classes.boxStyleSelected : classes.boxStyleNonselected}
            onClick={() => handleSuperStructureBoxClick(35)}
          >
            <Typography className={superStructurePercentage === 35 ? classes.pillTextSelected : classes.pillTextNonSelected}>35%</Typography>
          </Box>
        </Grid>
        <Grid item>
          <Box
            className={isSuperStructureCustomSelected ? classes.boxStyleSelected : classes.boxStyleNonselected}
            onClick={() => handleCustomSuperStructureBoxClick()}
          >
            {!isSuperStructureCustomSelected ? (
              <Typography className={classes.pillTextNonSelected}>Custom</Typography>
            ) : (
              <TextField
                type="text"
                value={customSuperStructureValue}
                onChange={(e) => {
                  const value = e.target.value;
                  if (/^(\d+(\.\d*)?|\.?\d*)$/.test(value)) {
                    updateQuantityValue(superStructureBasedOnAdmixture, parseFloat(value) || 0);
                    setcustomSuperStructureValue(value);
                  }
                }}
                variant="outlined"
                size="small"
                placeholder="Enter value"
                style={{ width: '60px' }}
                className={classes.customTextField}
                InputProps={{
                  disableUnderline: false,
                  endAdornment: (
                    <InputAdornment position="end">
                      <Typography style={{ color: '#FFFFFF', fontWeight: 500 }}>%</Typography>
                    </InputAdornment>
                  ),
                  inputProps: {
                    style: {
                      appearance: 'none',
                      MozAppearance: 'textfield',
                    },
                  },
                }}
              />
            )}
          </Box>
        </Grid>
      </Grid>
      {isVerticalVisible && (
        <>
          <Grid container item xs={12} style={{ paddingBottom: '10px' }}>
            <Typography className={classes.textHeader3}>{getQuantityLabel(verticalBasedOnAdmixture)}</Typography>
          </Grid>
          <Grid container item xs={12}>
            <Grid item>
              <Box className={verticalPercentage === 20 ? classes.boxStyleSelected : classes.boxStyleNonselected} onClick={() => handleVerticalBoxClick(20)}>
                <Typography className={verticalPercentage === 20 ? classes.pillTextSelected : classes.pillTextNonSelected}>20%</Typography>
              </Box>
            </Grid>
            <Grid item>
              <Box className={verticalPercentage === 25 ? classes.boxStyleSelected : classes.boxStyleNonselected} onClick={() => handleVerticalBoxClick(25)}>
                <Typography className={verticalPercentage === 25 ? classes.pillTextSelected : classes.pillTextNonSelected}>25%</Typography>
              </Box>
            </Grid>
            <Grid item>
              <Box className={verticalPercentage === 30 ? classes.boxStyleSelected : classes.boxStyleNonselected} onClick={() => handleVerticalBoxClick(30)}>
                <Typography className={verticalPercentage === 30 ? classes.pillTextSelected : classes.pillTextNonSelected}>30%</Typography>
              </Box>
            </Grid>
            <Grid item>
              <Box className={verticalPercentage === 35 ? classes.boxStyleSelected : classes.boxStyleNonselected} onClick={() => handleVerticalBoxClick(35)}>
                <Typography className={verticalPercentage === 35 ? classes.pillTextSelected : classes.pillTextNonSelected}>35%</Typography>
              </Box>
            </Grid>
            <Grid item>
              <Box className={isVerticalCustomSelected ? classes.boxStyleSelected : classes.boxStyleNonselected} onClick={() => handleCustomVerticalBoxClick()}>
                {!isVerticalCustomSelected ? (
                  <Typography className={classes.pillTextNonSelected}>Custom</Typography>
                ) : (
                  <TextField
                    type="text"
                    value={customVerticalValue}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (/^(\d+(\.\d*)?|\.?\d*)$/.test(value)) {
                        updateQuantityValue(verticalBasedOnAdmixture, parseFloat(value) || 0);
                        setcustomVerticalValue(value);
                      }
                    }}
                    variant="outlined"
                    size="small"
                    placeholder="Enter value"
                    style={{ width: '60px' }}
                    className={classes.customTextField}
                    InputProps={{
                      disableUnderline: false,
                      endAdornment: (
                        <InputAdornment position="end">
                          <Typography style={{ color: '#FFFFFF', fontWeight: 500 }}>%</Typography>
                        </InputAdornment>
                      ),
                      inputProps: {
                        style: {
                          appearance: 'none',
                          MozAppearance: 'textfield',
                        },
                      },
                    }}
                  />
                )}
              </Box>
            </Grid>
          </Grid>
        </>
      )}
      <Grid container item xs={12} className={classes.mandateBox}>
        <Grid item>
          <InfoIcon className={classes.infoStyle} />
        </Grid>
        <Grid item xs={11}>
          <Typography className={classes.mandateText}>
            Mineral admixtures like Fly Ash and Ground Granulated Blast-furnace Slag (GGBS) in concrete significantly reduce the reliance on Portland cement,
            lowering carbon emissions associated with cement production. They utilize industrial by-products, promoting waste management and conserving natural
            resources. This sustainable approach decreases energy consumption and supports a circular economy in construction.
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default ScCustomBasicParameters;
