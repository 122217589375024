import React, { useState, useEffect } from 'react';
import { Typography, Grid } from '@material-ui/core';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ResetFields from '../common/textFiledwithReset';
import useStyles from './styles';
import { useInterventionDetailsContext } from '../context/InterventionDetails';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';

const StructuralSteelCustomAdvancedParameters = (props) => {
  const classes = useStyles();
  const {
    schema,
    get: { getQuantityValue, getQuantityDefaultValue, getQuantityLabel, getQuantityUnit },
    set: { updateQuantityValue },
  } = useInterventionDetailsContext();
  const handleRadioClick = (selectedOption) => {
    Object.keys(schema).forEach((key) => {
      if (key.includes('pi_quantityFactorOption')) {
        updateQuantityValue(schema[key], key === selectedOption);
      }
    });
  };

  return (
    <Grid container direction="column" alignItems="left" item xs={12}>
      <Typography className={classes.textHeader4}>{getQuantityLabel(schema.structuralSteel__pi_capexSource)}</Typography>
      <Grid container item xs={12}>
        <Grid item xs={4} direction="column">
          <Grid container item xs={12} style={{ paddingBottom: '10px' }}>
            <Typography className={classes.textHeader3}>
              {getQuantityValue(schema.structuralSteel__pi_capexSource) === 'VENDOR' ? (
                <CheckCircleIcon
                  className={classes.selectedCheckbox}
                  onClick={() => {
                    updateQuantityValue(schema.structuralSteel__pi_capexSource, 'VENDOR');
                  }}
                />
              ) : (
                <RadioButtonUncheckedIcon
                  className={classes.nonselectedCheckbox}
                  onClick={() => {
                    updateQuantityValue(schema.structuralSteel__pi_capexSource, 'VENDOR');
                  }}
                />
              )}
              {getQuantityLabel(schema.structuralSteel_vendorCapex)}
            </Typography>
          </Grid>
          <Grid xs={12} container alignItems="flex-start" direction="row" style={{ display: 'flex', alignItems: 'center' }}>
            <Grid xs={7}>
              <Typography className={classes.textHeader3}>{getQuantityValue(schema.structuralSteel_vendorCapex)} RS</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4} direction="column">
          <Grid container item xs={12} style={{ paddingBottom: '10px' }}>
            <Typography className={classes.textHeader3}>
              {getQuantityValue(schema.structuralSteel__pi_capexSource) === 'CUSTOM' ? (
                <CheckCircleIcon
                  className={classes.selectedCheckbox}
                  onClick={() => {
                    updateQuantityValue(schema.structuralSteel__pi_capexSource, 'CUSTOM');
                  }}
                />
              ) : (
                <RadioButtonUncheckedIcon
                  className={classes.nonselectedCheckbox}
                  onClick={() => {
                    updateQuantityValue(schema.structuralSteel__pi_capexSource, 'CUSTOM');
                  }}
                />
              )}
              {getQuantityLabel(schema.structuralSteel__pi_customCapex)}
            </Typography>
          </Grid>
          <Grid xs={12} container alignItems="flex-start" direction="row" style={{ display: 'flex', alignItems: 'center' }}>
            <Grid xs={7}>
              <ResetFields
                id="outlined-basic"
                variant="outlined"
                value={getQuantityValue(schema.structuralSteel__pi_customCapex)}
                placeholder=""
                onChange={(e) => {
                  e.preventDefault();
                  updateQuantityValue(schema.structuralSteel__pi_customCapex, e.target.value);
                }}
                fullWidth
                required
                disabled={getQuantityValue(schema.structuralSteel__pi_capexSource) !== 'CUSTOM' ? true : false}
                removeResetIcon={true}
              />
            </Grid>
            <Grid xs={3} className={classes.grid} style={{ paddingLeft: '2%' }}>
              <Typography variant="h6" className={{} ? classes.textDisable : undefined}>
                {getQuantityUnit(schema.structuralSteel__pi_customCapex)}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Typography className={classes.textHeader1} style={{ paddingTop: '3%' }}>
        Steel Quantity Factor
      </Typography>
      <Grid item xs={6}>
        {[
          { key: 'structuralSteel__pi_quantityFactorOptionOne', labelKey: 'structuralSteel_quantityFactorOne' },
          { key: 'structuralSteel__pi_quantityFactorOptionTwo', labelKey: 'structuralSteel_quantityFactorTwo' },
          { key: 'structuralSteel__pi_quantityFactorOptionThree', labelKey: 'structuralSteel_quantityFactorThree', default: true },
          { key: 'structuralSteel__pi_quantityFactorOptionFour', labelKey: 'structuralSteel_quantityFactorFour' },
          { key: 'structuralSteel__pi_quantityFactorOptionFive', labelKey: null },
        ].map((option, index) => (
          <Typography key={index} className={classes.textHeader3} onClick={() => handleRadioClick(option.key)}>
            {getQuantityValue(schema[option.key]) ? (
              <RadioButtonCheckedIcon className={classes.selectedRadiobox} />
            ) : (
              <RadioButtonUncheckedIcon className={classes.nonselectedRadiobox} />
            )}
            {option.labelKey ? (
              <>
                {getQuantityValue(schema[option.labelKey])} {getQuantityUnit(schema[option.labelKey])}
              </>
            ) : (
              'Custom'
            )}
            {option.default && ' (Default)'}
          </Typography>
        ))}

        {
          <Grid xs={12} container alignItems="flex-start" direction="row" style={{ display: 'flex', alignItems: 'center', paddingTop: '10px' }}>
            <Grid xs={5}>
              <ResetFields
                id="outlined-basic"
                variant="outlined"
                value={getQuantityValue(schema.structuralSteel__pi_quantityFactorUserInput)}
                placeholder=""
                onChange={(e) => {
                  e.preventDefault();
                  updateQuantityValue(schema.structuralSteel__pi_quantityFactorUserInput, e.target.value);
                }}
                fullWidth
                required
                disabled={!getQuantityValue(schema.structuralSteel__pi_quantityFactorOptionFive)}
                onClick={(e) => {
                  e.preventDefault();
                  updateQuantityValue(
                    schema.structuralSteel__pi_quantityFactorUserInput,
                    getQuantityDefaultValue(schema.structuralSteel__pi_quantityFactorUserInput)
                  );
                }}
              />
            </Grid>
            <Grid xs={3} className={classes.grid} style={{ paddingLeft: '10px' }}>
              <Typography className={classes.textHeader3}>{getQuantityUnit(schema.structuralSteel__pi_quantityFactorUserInput)}</Typography>
            </Grid>
          </Grid>
        }
      </Grid>

      <Grid container item xs={12} style={{ paddingTop: '50px', paddingBottom: '20px' }}></Grid>
    </Grid>
  );
};
export default StructuralSteelCustomAdvancedParameters;
