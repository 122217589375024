import React from 'react';
import { Text, View } from '@react-pdf/renderer';
// import dummyVendor from '../../../../../assets/new-pdf/dummyVendor.png';
import { areaReqdDisplayed, displayEmbodiedCarbon, getCapacityOrFlowText, getResourceTextDynamically, vocLevelDisplayed } from './ReportUtils';
import numberWithCommas from '../../../../../utils/numberToComma';

const ActiveIntervCard = (props) => {
  return (
    <View style={{ border: props.border, borderRadius: '10px', height: '162px', marginTop: '16px' }} break>
      <View style={{ flexDirection: 'row', display: 'flex' }}>
        <View style={{ paddingTop: '10px', width: '45%', paddingLeft: '10px', paddingRight: '10px' }}>
          <Text style={{ fontFamily: 'Lato', fontWeight: 700, fontSize: '12px', color: props.color }}>{props.name && props.name}</Text>
          <Text style={{ fontFamily: 'Lato', fontSize: '10px', color: '#666666', paddingTop: '5px' }}>{props.content}</Text>
          <View style={{ display: 'flex', flexDirection: 'row', paddingTop: '5px', alignItems: 'center' }}>
            <Text style={{ fontFamily: 'Lato', fontWeight: 700, fontSize: '11px' }}>{getCapacityOrFlowText(props?.interventionKey)} </Text>
            {props?.interventionKey !== 'hwmApartment' && props?.interventionKey !== 'efficientFixturesDualFlush' && props?.interventionKey !== 'sc' && (
              <Text style={{ fontFamily: 'Lato-Italic', fontSize: '10px' }}>
                {Math.round(props?.capacity)} {props?.capacityUnit}
              </Text>
            )}
            {props?.interventionKey === 'hwmApartment' && typeof props?.capacity === 'object' && (
              <Text style={{ fontFamily: 'Lato-Italic', fontSize: '10px' }}>
                {Math.round(props?.capacity?.hp)} {props?.capacityUnit} (HP), {Math.round(props?.capacity?.swh)} {props?.capacityUnit} (SWH)
              </Text>
            )}
            {props?.interventionKey === 'hwmApartment' && typeof props?.capacity !== 'object' && (
              <Text style={{ fontFamily: 'Lato-Italic', fontSize: '10px' }}>
                {Math.round(props?.capacity)} {props?.capacityUnit}
              </Text>
            )}
            {props?.interventionKey === 'efficientFixturesDualFlush' && (
              <Text style={{ fontFamily: 'Lato-Italic', fontSize: '10px' }}>
                Half {Math.round(props.halfFlow)} {props.capacityUnit}, Full {Math.round(props.fullFlow)} {props.capacityUnit}
              </Text>
            )}
            {props?.interventionKey === 'sc' && typeof props?.capacity === 'object' && (
              <Text style={{ fontFamily: 'Lato-Italic', fontSize: '8px', marginLeft: '10px' }}>
                Sub Structure - {Math.round(props?.capacity?.subStructure)}
                {props.capacityUnit}, Super Structure - {Math.round(props?.capacity?.superStructure)}
                {props.capacityUnit}, Vertical - {Math.round(props?.capacity?.vertical)}
                {props.capacityUnit}
              </Text>
            )}
          </View>
          <View style={{ flexDirection: 'row', display: 'flex', paddingTop: '10px' }}>
            <View>
              <Text style={{ fontFamily: 'Lato', fontWeight: 'Bold', fontSize: '10px', paddingTop: '10px' }}>Selected Vendor:</Text>
            </View>
            <View>
              <Text style={{ fontFamily: 'Lato', fontSize: '10px', paddingTop: '10px' }}> {props?.brand}</Text>
            </View>
          </View>
        </View>
        <View style={{ width: '20%', paddingTop: '10px' }}>
          <Text style={{ fontFamily: 'Lato', fontWeight: 700, fontSize: '11px', color: '#666666', textAlign: 'center' }}>CAPEX Increase</Text>
          <Text style={{ fontFamily: 'Lato', fontWeight: 700, fontSize: '11px', color: '#666666', textAlign: 'center' }}>(lakhs)</Text>
          <Text style={{ fontFamily: 'Lato', fontWeight: 700, fontSize: '18px', color: props.color, textAlign: 'center', paddingTop: '10px' }}>
            {props.capex}
          </Text>
          {areaReqdDisplayed(props.interventionKey) && (
            <>
              <Text style={{ fontFamily: 'Lato', fontWeight: 700, fontSize: '10px', color: '#666666', textAlign: 'center', paddingTop: '15px' }}>
                Space Required
              </Text>
              <Text style={{ fontFamily: 'Lato', fontWeight: 700, fontSize: '10px', color: '#666666', textAlign: 'center' }}>{`(${props.areaUnit})`}</Text>
              <Text style={{ fontFamily: 'Lato', fontWeight: 700, fontSize: '18px', color: props.color, textAlign: 'center', paddingTop: '10px' }}>
                {props?.area?.toFixed(2) || '-'}
              </Text>
            </>
          )}
          {vocLevelDisplayed(props.interventionKey) && (
            <>
              <Text style={{ fontFamily: 'Lato', fontWeight: 700, fontSize: '10px', color: '#666666', textAlign: 'center', paddingTop: '15px' }}>
                VOC Level
              </Text>
              <Text style={{ fontFamily: 'Lato', fontWeight: 700, fontSize: '18px', color: props.color, textAlign: 'center', paddingTop: '10px' }}>
                {props?.vocLevel || '-'}
              </Text>
            </>
          )}
        </View>
        <View style={{ width: '30%', paddingTop: '10px' }}>
          <Text style={{ fontFamily: 'Lato', fontWeight: 700, fontSize: '10px', color: '#666666', textAlign: 'center' }}>
            {getResourceTextDynamically(props?.category)}
          </Text>
          <Text style={{ fontFamily: 'Lato', fontWeight: 700, fontSize: '10px', color: '#666666', textAlign: 'center' }}> {`(${props.unit})`}</Text>
          {displayEmbodiedCarbon(props.interventionKey) ? (
            <Text style={{ fontFamily: 'Lato', fontWeight: 700, fontSize: '18px', color: props.color, textAlign: 'center', paddingTop: '10px' }}>
              {numberWithCommas(Math.round(props?.embodiedCarbon))}
            </Text>
          ) : (
            <Text style={{ fontFamily: 'Lato', fontWeight: 700, fontSize: '18px', color: props.color, textAlign: 'center', paddingTop: '10px' }}>
              {numberWithCommas(Math.round(props?.resource))}
            </Text>
          )}

          <Text style={{ fontFamily: 'Lato', fontWeight: 700, fontSize: '10px', color: '#666666', textAlign: 'center', paddingTop: '15px' }}>
            Annual OPEX Savings
          </Text>
          <Text style={{ fontFamily: 'Lato', fontWeight: 700, fontSize: '10px', color: '#666666', textAlign: 'center' }}>(lakhs)</Text>
          <Text style={{ fontFamily: 'Lato', fontWeight: 700, fontSize: '18px', color: props.color, textAlign: 'center', paddingTop: '10px' }}>
            {props.opex}
          </Text>
        </View>
      </View>
    </View>
  );
};

export default ActiveIntervCard;
