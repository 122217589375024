import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  textHeader1: {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '19.2px',
    letterSpacing: '0.71px',
    color: 'rgba(46, 46, 46, 1)',
    textAlign: 'left',
    display: 'flex',
    alignItems: 'center',
  },
  nonselectedCheckbox: {
    color: 'rgba(102, 227, 190, 1)',
    fontSize: '25px',
    marginRight: '10px',
    cursor: 'pointer',
  },
  selectedCheckbox: {
    color: 'rgba(102, 227, 190, 1)',
    fontSize: '25px',
    marginRight: '10px',
    cursor: 'pointer',
  },
  textheader2: {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '19px',
    letterSpacing: '0.7px',
    color: '#2E2E2E',
    paddingTop: '10%',
  },
  nonselectedRadiobox: {
    // color: "rgba(102, 227, 190, 1)",
    // fontSize: "25px",
    marginRight: '10px',
    cursor: 'pointer',
  },
  selectedRadiobox: {
    color: 'rgba(102, 227, 190, 1)',
    // fontSize: "25px",
    marginRight: '10px',
    cursor: 'pointer',
  },
  textHeader3: {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '24px',
    letterSpacing: '0.71px',
    textAlign: 'left',
    display: 'flex',
    alignItems: 'center',
    paddingTop: '10px',
  },
  textHeader3disabled: {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '24px',
    letterSpacing: '0.71px',
    textAlign: 'left',
    display: 'flex',
    alignItems: 'center',
    paddingTop: '10px',
    color: 'rgba(158, 158, 158, 1)',
  },
  textHeader4: {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '19px',
    letterSpacing: '0.71px',
    paddingTop: '10px',
    color: 'rgba(102, 102, 102, 1)',
  },
  textHeader5: {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '24px',
    letterSpacing: '0.71px',
    textAlign: 'left',
    display: 'flex',
    alignItems: 'center',
    paddingTop: '10px',
    color: 'rgba(102, 102, 102, 1)',
  },
  nonselectedRadioboxdisabled: {
    color: 'rgba(158, 158, 158, 1)',
    marginRight: '10px',
  },
  textDisable: {
    color: '#9E9E9E',
  },
  gridContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    paddingTop: '1%',
  },
  gridItem: {
    flex: '0 0 calc(20% - 16px)', // Each item takes 20% of the row minus margins
    margin: '8px', // Margin between items
    boxSizing: 'border-box', // Ensures padding and border are included in width
  },
  gridItemInvisible: {
    flex: '0 0 calc(20% - 16px)', // Matches the width of grid items
    margin: '8px',
    boxSizing: 'border-box',
    visibility: 'hidden', // Invisible but takes space
  },
}));

export default useStyles;
