import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import useStyles from './styles';
import ResetFields from '../../../interventions/interventionDetails/common/textFiledwithReset';

const SteelMaterialDetails = ({ data, appendChanged, pipValues }) => {
  const classes = useStyles();
  const factorUserInput = data?.find((item) => item.name === 'project__pi_quantityFactorOptionFive')?._id;
  const customLabels = ['37.67 (3.5 kg / sqft)', '43.06 (4 kg / sqft) (Default)', '48.44 (4.5 kg / sqft)', '53.82 (5 kg / sqft)', 'Custom'];
  const handleSelection = (selectedId) => {
    data?.forEach((pip) => {
      if (pip.type === 'boolean') {
        appendChanged(pip._id, false);
      }
    });
    appendChanged(selectedId, true);
  };

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <Typography variant="h5" className={classes.header}>
          Structural Steel Quantity Factor
        </Typography>
      </Grid>

      {data?.map((pip, index) => {
        if (pip.type === 'boolean' && index < customLabels.length) {
          const isSelected = !!pipValues[pip._id];
          return (
            <Grid item key={pip._id} container alignItems="center" spacing={1} style={{ marginLeft: '2%' }}>
              <Grid item>
                {isSelected ? (
                  <RadioButtonCheckedIcon className={classes.selectedRadiobox} onClick={() => handleSelection(pip._id)} />
                ) : (
                  <RadioButtonUncheckedIcon className={classes.nonselectedRadiobox} onClick={() => handleSelection(pip._id)} />
                )}
              </Grid>
              <Grid item>
                <Typography className={classes.textHeader3}>{customLabels[index]}</Typography>
              </Grid>
            </Grid>
          );
        }

        if (pip.type === 'number') {
          return (
            <Grid item key={pip._id} container alignItems="center" spacing={1} style={{ marginLeft: '4%' }}>
              <Grid xs={10} container alignItems="flex-start" direction="row" style={{ display: 'flex', alignItems: 'center' }}>
                <Grid xs={2}>
                  <ResetFields
                    id="outlined-basic"
                    variant="outlined"
                    value={pipValues[pip._id]}
                    placeholder=""
                    onChange={(e) => {
                      e.preventDefault();
                      appendChanged(pip._id, e.target.value);
                    }}
                    fullWidth
                    required
                    disabled={!pipValues[factorUserInput]}
                    onClick={(e) => {
                      appendChanged(pip._id, 20);
                    }}
                  />
                </Grid>
                <Grid className={classes.grid} style={{ paddingLeft: '10px' }}>
                  <Typography className={classes.textHeader2}>kg / sqft</Typography>
                </Grid>
              </Grid>
            </Grid>
          );
        }

        return null;
      })}
    </Grid>
  );
};

export default SteelMaterialDetails;
