import React, { useState, useEffect, useContext } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Grid, Typography, Button, Backdrop, CircularProgress } from '@material-ui/core';

import HeadingAndButton from '../../../../common/header/HeadingAndButton';
import InterventionOverview from '../components/interventionOverview';
import TableOverview from '../components/tableOverview';
import axios from '../../../../../config/Axios';
import { InterventionCategoryContext } from '../../../../../projectRouting/Index';
import { InterventionMappingContext } from '../../../../../projectRouting/Index';
import {
  calculateEnabledCount,
  getActivatedInterventionsInCategory,
  getEnabledAndActivatedInterventionsInCategory,
  getEnabledInterventionsInCategory,
  getTotalInterventionsInCategory,
  orderCategory,
} from '../utils';
import { PreviousPageContext } from '../../../../../routing/IndexRouting';
import { useParams } from 'react-router-dom';
import SummaryCategoriesButton from '../components/SummaryCategoriesButton';
import CategoryTable from '../components/categoryTable';
import InterventionSummaryTour from './InterventionSummaryTour';
import AddCustomIntervention from '../components/AddCustomIntervention';
import CasaSkeleton from '../../../../common/skeleton/CasaSkeleton';
import { MetafireFeatures } from '../../../../../metafire/utils/types';
import { useAppApiRequest } from '../../../../../hooks/useAppApiRequest';
import ApiError from '../../../../common/Error/ApiError';

const useStyles = makeStyles((theme) => ({
  gridWithPadding: {
    padding: theme.spacing(3),
  },
  main: {
    marginTop: '3%',
  },
  root: {
    paddingTop: '3%',
  },
  heading: {
    marginTop: '2%',
    width: '100%',
  },
  table: {
    marginTop: '3%',
  },
  paper: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: theme.spacing(1, 1, 0, 1),
    '& > *': {
      borderRadius: '10px',
      padding: theme.spacing(2, 2, 2, 2),
      margin: theme.spacing(1),
      width: theme.spacing(20),
      height: theme.spacing(8),
    },
  },
  tabbing: {
    padding: theme.spacing(2, 2, 2, 1),
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
  },
  endIco: {
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.571429px',
    color: 'rgba(0, 0, 0, 0.82)',
  },
  endIcoActive: {
    fontSize: '16px',
    lineHeight: '24px',
    fontStyle: 'normal',
    letterSpacing: '0.571429px',
    color: '#FFFFFF',
    fontWeight: '800',
  },
  label: {
    width: '100%',
    textTransform: 'none',
    textAlign: 'left',
    fontStyle: 'normal',
    fontWeight: '800',
    fontSize: '16px',
    lineHeight: '22px',
    letterSpacing: '0.714286px',
    color: 'rgba(0, 0, 0, 0.6)',
  },
}));

function InterventionSummary(props) {
  const classes = useStyles();
  const params = useParams();
  const { interventionCategoryContext, handleInterventionCategory } = useContext(InterventionCategoryContext);
  const { interventionMappingContext, handleInterventionMapping } = useContext(InterventionMappingContext);
  const { setPreviousPage } = useContext(PreviousPageContext);

  const [interventionData, setInterventionData] = useState(null);
  const [editCustomintervention, setEditCustomintervention] = useState(false);
  const [editCustominterventionID, setEditCustominterventionID] = useState('');
  const [flowDiagramData, setFlowDiagramData] = useState(null);
  const {
    data: interventionsResponse,
    isError,
    isFetching,
    refetch,
  } = useAppApiRequest(`/metafire?projectId=${params?.id}&feature=${MetafireFeatures.INTERVENTIONS}`, { method: 'GET' });
  const { data: flowDiagram } = useAppApiRequest(`/metafire?projectId=${params?.id}&feature=${MetafireFeatures.FLOWDIAGRAMS}`, { method: 'GET' });

  useEffect(() => {
    if (flowDiagram) {
      setFlowDiagramData(flowDiagram?.data?.data?.data);
    }
  }, [flowDiagram]);
  useEffect(() => {
    if (interventionsResponse) {
      setInterventionData(interventionsResponse?.data?.data?.data);
    }
  }, [interventionsResponse]);

  useEffect(() => {
    if (params?.id) {
      axios
        .get(`/ProjectInterventionMapping/project/${params?.id}`)
        .then((respo) => {
          if (respo && respo.data && respo.data.data && respo.data.data.data) handleInterventionMapping(respo.data.data.data);
        })
        .catch((err) => console.log(err));
    }
  }, [params?.id]);

  const handleEditCustomIntervention = (value) => setEditCustomintervention(value);
  const handleEditCustominterventionID = (value) => setEditCustominterventionID(value);

  const handleCreateReport = () => window.open(`/project/${params?.id}/pdf`);

  const handleActiveBtn = (value) => {
    handleInterventionCategory(value);
  };
  const handleRefresh = () => {
    if (params?.id) {
      refetch();
      handleEditCustomIntervention(false);
      handleEditCustominterventionID('');
    }
  };

  useEffect(() => {
    setPreviousPage('interventions');
  }, []);

  if (isError)
    return (
      <div className={classes.main}>
        <div className={classes.root}>
          <div className={classes.gridWithPadding}>
            <ApiError />
          </div>
        </div>
      </div>
    );

  const selectedInterventionByCategory = interventionData?.categorized?.find((data) => data?.name?.toLowerCase() === interventionCategoryContext);
  const existingCustomInterventionNames = selectedInterventionByCategory?.subcategories
    .filter((s) => {
      return s.name === 'Custom Intervention';
    })[0]
    ?.interventions?.map((ci) => {
      return ci.name.toLowerCase();
    });
  return (
    <>
      <Grid className={classes.main}>
        <Grid className={classes.root}>
          <Grid className={classes.gridWithPadding}>
            {/* handleCreateReport={handleCreateReport} */}
            <HeadingAndButton
              heading="Interventions"
              className={classes.heading}
              buttonStyle={classes.downloadReportButton}
              handleCreateReport={handleCreateReport}
            />
            {isFetching ? (
              <CasaSkeleton variant="rect" height="100px" width="100%" animation="wave" style={{ marginTop: 8 }} />
            ) : (
              <Grid
                container
                alignItems="left"
                item
                xs={12}
                // style={{ marginTop: '1%' }}
              >
                <InterventionOverview data={interventionData} />
                <Grid item xs={2} align="left">
                  <AddCustomIntervention
                    handleRefresh={() => handleRefresh()}
                    editModalClose={(value) => handleEditCustomIntervention(value)}
                    deselectID={(value) => handleEditCustominterventionID(value)}
                    editCustomintervention={editCustomintervention && editCustomintervention}
                    editCustominterventionID={editCustominterventionID && editCustominterventionID}
                    openBackdrop={isFetching}
                    handleCloseBackdrop={(value) => {}}
                    handleOpenBackdrop={(value) => {}}
                    existingCustomInterventionNames={existingCustomInterventionNames}
                  />
                </Grid>
              </Grid>
            )}
          </Grid>
          <Grid className={classes.tabbing}>
            <div className={classes.paper}>
              {isFetching ? (
                <CasaSkeleton variant="rect" height="100px" width="50%" animation="wave" style={{ marginBottom: 16 }} />
              ) : (
                orderCategory(interventionData?.categorized)?.map((category, index) => (
                  <>
                    <SummaryCategoriesButton
                      selectedType={interventionCategoryContext}
                      category={category}
                      // count={getTotalInterventionsInCategory(category)}
                      count={getEnabledAndActivatedInterventionsInCategory(category).length || 0}
                      key={index}
                      handleActiveBtn={(type) => handleActiveBtn(type)}
                    />
                  </>
                ))
              )}
            </div>
          </Grid>
          <Grid className={classes.gridWithPadding}>
            <Grid alignItems="center" container xs={12}>
              <TableOverview data={selectedInterventionByCategory} isLoading={isFetching} />
            </Grid>
            <Grid alignItems="center" container xs={12} className={classes.table}>
              <CategoryTable
                flowDiagramData={flowDiagramData}
                data={selectedInterventionByCategory}
                projectID={params?.id}
                handleRefresh={() => handleRefresh()}
                allinterv={interventionData?.categorized}
                handleEditCustomIntervention={(value) => handleEditCustomIntervention(value)}
                handleEditCustominterventionID={(value) => handleEditCustominterventionID(value)}
                handleOpenBackdrop={(v) => {}}
              />
            </Grid>
          </Grid>
        </Grid>
        <div>
          <Backdrop open={isFetching} style={{ zIndex: 3, color: '#66E3BE' }}>
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
        <InterventionSummaryTour selectedInterventionByCategory={selectedInterventionByCategory} />
      </Grid>
    </>
  );
}

export default InterventionSummary;
