import React from 'react';
import { View, Svg, Path, Text } from '@react-pdf/renderer';

// Helper to calculate SVG arc paths
const calculateArcPath = (cx, cy, radius, startAngle, endAngle) => {
  const startX = cx + radius * Math.cos(startAngle);
  const startY = cy + radius * Math.sin(startAngle);
  const endX = cx + radius * Math.cos(endAngle);
  const endY = cy + radius * Math.sin(endAngle);

  const largeArcFlag = endAngle - startAngle <= Math.PI ? 0 : 1;

  return `
    M ${startX} ${startY}
    A ${radius} ${radius} 0 ${largeArcFlag} 1 ${endX} ${endY}
    L ${cx} ${cy}
    Z
  `;
};

const DonutChart = (props) => {
  const cx = 60;
  const cy = 60;
  const fullCircle = 2 * Math.PI;
  const radius = 50;
  const percentage = props.percentage > 100 ? 99.99 : props.percentage;

  const filledEndAngle = (percentage / 100) * fullCircle - Math.PI / 2;
  const offSet = (+`${props.percentage}`?.length || 2) * 2;

  return (
    <Svg width={radius * 2 + 10} height={radius * 2 + 10}>
      <Path d={calculateArcPath(cx, cy, radius, -Math.PI / 2, fullCircle - Math.PI / 2)} fill="#f0f0f0" />

      <Path d={calculateArcPath(cx, cy, radius, -Math.PI / 2, filledEndAngle)} fill={props.color} />

      <Path d={calculateArcPath(cx, cy, radius * 0.7, 0, fullCircle)} fill="#ffffff" />
      <View>
        <Text x={cx - 3} y={cy + 3} alignmentBaseline="middle" textAnchor="middle" fontSize={12} fill={props.textColor}>
          {props.percentage}
        </Text>
        <Text x={cx + 5 + offSet} y={cy + 3} alignmentBaseline="middle" textAnchor="middle" fontSize={12} fill={props.textColor && props.textColor}>
          %
        </Text>
      </View>
    </Svg>
  );
};
export default DonutChart;
