import React from 'react';
import { Typography, Grid } from '@material-ui/core';
import useStyles from './styles';
import { useInterventionDetailsContext } from '../context/InterventionDetails';

const IndoorFlooringBathroomProjectInputs = (props) => {
  const classes = useStyles();
  const {
    schema,
    get: { getQuantityValue, getQuantityUnit },
  } = useInterventionDetailsContext();
  let subCategory = getQuantityValue(schema.indoorFlooringBathroom_subcategory);
  return (
    <Grid container item xs={12} direction="column">
      <Typography className={classes.textHeader1}>Project Inputs</Typography>
      {subCategory !== 'villa' && (
        <>
          <Grid container item xs={12} style={{ paddingTop: '1%' }}>
            <Grid item xs>
              <Typography className={classes.textHeader5}>Studio</Typography>
              <Typography className={classes.textHeader3}>
                {getQuantityValue(schema.indoorFlooringBathroom_studio)} {getQuantityUnit(schema.indoorFlooringBathroom_studio)}
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography className={classes.textHeader5}>1 BHK</Typography>
              <Typography className={classes.textHeader3}>
                {getQuantityValue(schema.indoorFlooringBathroom_oneBHK)} {getQuantityUnit(schema.indoorFlooringBathroom_oneBHK)}
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography className={classes.textHeader5}>2 BHK</Typography>
              <Typography className={classes.textHeader3}>
                {getQuantityValue(schema.indoorFlooringBathroom_twoBHK)} {getQuantityUnit(schema.indoorFlooringBathroom_twoBHK)}
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography className={classes.textHeader5}>3 BHK</Typography>
              <Typography className={classes.textHeader3}>
                {getQuantityValue(schema.indoorFlooringBathroom_threeBHK)} {getQuantityUnit(schema.indoorFlooringBathroom_threeBHK)}
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography className={classes.textHeader5}>4 BHK</Typography>
              <Typography className={classes.textHeader3}>
                {getQuantityValue(schema.indoorFlooringBathroom_fourBHK)} {getQuantityUnit(schema.indoorFlooringBathroom_fourBHK)}
              </Typography>
            </Grid>
          </Grid>
          <Grid container item xs={12} style={{ paddingTop: '1%' }}>
            <Grid item xs>
              <Typography className={classes.textHeader5}>Studio Carpet Area</Typography>
              <Typography className={classes.textHeader3}>
                {getQuantityValue(schema.indoorFlooringBathroom_studioCarpetArea)} {getQuantityUnit(schema.indoorFlooringBathroom_studioCarpetArea)}
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography className={classes.textHeader5}>1 BHK Carpet Area</Typography>
              <Typography className={classes.textHeader3}>
                {getQuantityValue(schema.indoorFlooringBathroom_oneBhkCarpetArea)} {getQuantityUnit(schema.indoorFlooringBathroom_oneBhkCarpetArea)}
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography className={classes.textHeader5}>2 BHK Carpet Area</Typography>
              <Typography className={classes.textHeader3}>
                {getQuantityValue(schema.indoorFlooringBathroom_twoBhkCarpetArea)} {getQuantityUnit(schema.indoorFlooringBathroom_twoBhkCarpetArea)}
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography className={classes.textHeader5}>3 BHK Carpet Area</Typography>
              <Typography className={classes.textHeader3}>
                {getQuantityValue(schema.indoorFlooringBathroom_threeBhkCarpetArea)} {getQuantityUnit(schema.indoorFlooringBathroom_threeBhkCarpetArea)}
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography className={classes.textHeader5}>4 BHK Carpet Area</Typography>
              <Typography className={classes.textHeader3}>
                {getQuantityValue(schema.indoorFlooringBathroom_fourBhkCarpetArea)} {getQuantityUnit(schema.indoorFlooringBathroom_fourBhkCarpetArea)}
              </Typography>
            </Grid>
          </Grid>
          {/* Penthouse */}
          <Grid container item xs={12} style={{ paddingTop: '1%' }}>
            <Grid item xs>
              <Typography className={classes.textHeader5}>PH 1 BHK</Typography>
              <Typography className={classes.textHeader3}>
                {getQuantityValue(schema.indoorFlooringBathroom_penthouseOneBHK)} {getQuantityUnit(schema.indoorFlooringBathroom_penthouseOneBHK)}
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography className={classes.textHeader5}>PH 2 BHK</Typography>
              <Typography className={classes.textHeader3}>
                {getQuantityValue(schema.indoorFlooringBathroom_penthouseTwoBHK)} {getQuantityUnit(schema.indoorFlooringBathroom_penthouseTwoBHK)}
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography className={classes.textHeader5}>PH 3 BHK</Typography>
              <Typography className={classes.textHeader3}>
                {getQuantityValue(schema.indoorFlooringBathroom_penthouseThreeBHK)} {getQuantityUnit(schema.indoorFlooringBathroom_penthouseThreeBHK)}
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography className={classes.textHeader5}>PH 4 BHK</Typography>
              <Typography className={classes.textHeader3}>
                {getQuantityValue(schema.indoorFlooringBathroom_penthouseFourBHK)} {getQuantityUnit(schema.indoorFlooringBathroom_penthouseFourBHK)}
              </Typography>
            </Grid>
          </Grid>
          <Grid container item xs={12} style={{ paddingTop: '1%' }}>
            <Grid item xs>
              <Typography className={classes.textHeader5}>PH 1 BHK Carpet Area</Typography>
              <Typography className={classes.textHeader3}>
                {getQuantityValue(schema.indoorFlooringBathroom_penthouseOneBhkCarpetArea)}{' '}
                {getQuantityUnit(schema.indoorFlooringBathroom_penthouseOneBhkCarpetArea)}
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography className={classes.textHeader5}>PH 2 BHK Carpet Area</Typography>
              <Typography className={classes.textHeader3}>
                {getQuantityValue(schema.indoorFlooringBathroom_penthouseTwoBhkCarpetArea)}{' '}
                {getQuantityUnit(schema.indoorFlooringBathroom_penthouseTwoBhkCarpetArea)}
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography className={classes.textHeader5}>PH 3 BHK Carpet Area</Typography>
              <Typography className={classes.textHeader3}>
                {getQuantityValue(schema.indoorFlooringBathroom_penthouseThreeBhkCarpetArea)}{' '}
                {getQuantityUnit(schema.indoorFlooringBathroom_penthouseThreeBhkCarpetArea)}
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography className={classes.textHeader5}>PH 4 BHK Carpet Area</Typography>
              <Typography className={classes.textHeader3}>
                {getQuantityValue(schema.indoorFlooringBathroom_penthouseFourBhkCarpetArea)}{' '}
                {getQuantityUnit(schema.indoorFlooringBathroom_penthouseFourBhkCarpetArea)}
              </Typography>
            </Grid>
          </Grid>
        </>
      )}
      {/* Villa */}
      {subCategory && subCategory === 'villa' && (
        <>
          <Grid container item xs={12} style={{ paddingTop: '1%' }}>
            <Grid item xs>
              <Typography className={classes.textHeader5}>1 BHK</Typography>
              <Typography className={classes.textHeader3}>
                {getQuantityValue(schema.indoorFlooringBathroom_villaOneBHK)} {getQuantityUnit(schema.indoorFlooringBathroom_villaOneBHK)}
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography className={classes.textHeader5}>2 BHK</Typography>
              <Typography className={classes.textHeader3}>
                {getQuantityValue(schema.indoorFlooringBathroom_villaTwoBHK)} {getQuantityUnit(schema.indoorFlooringBathroom_villaTwoBHK)}
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography className={classes.textHeader5}>3 BHK</Typography>
              <Typography className={classes.textHeader3}>
                {getQuantityValue(schema.indoorFlooringBathroom_villaThreeBHK)} {getQuantityUnit(schema.indoorFlooringBathroom_villaThreeBHK)}
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography className={classes.textHeader5}>4 BHK</Typography>
              <Typography className={classes.textHeader3}>
                {getQuantityValue(schema.indoorFlooringBathroom_villaFourBHK)} {getQuantityUnit(schema.indoorFlooringBathroom_villaFourBHK)}
              </Typography>
            </Grid>
          </Grid>
          <Grid container item xs={12} style={{ paddingTop: '1%' }}>
            <Grid item xs>
              <Typography className={classes.textHeader5}>1 BHK Carpet Area</Typography>
              <Typography className={classes.textHeader3}>
                {getQuantityValue(schema.indoorFlooringBathroom_villaOneBHKCarpetArea)} {getQuantityUnit(schema.indoorFlooringBathroom_villaOneBHKCarpetArea)}
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography className={classes.textHeader5}>2 BHK Carpet Area</Typography>
              <Typography className={classes.textHeader3}>
                {getQuantityValue(schema.indoorFlooringBathroom_villaTwoBHKCarpetArea)} {getQuantityUnit(schema.indoorFlooringBathroom_villaTwoBHKCarpetArea)}
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography className={classes.textHeader5}>3 BHK Carpet Area</Typography>
              <Typography className={classes.textHeader3}>
                {getQuantityValue(schema.indoorFlooringBathroom_villaThreeBHKCarpetArea)}{' '}
                {getQuantityUnit(schema.indoorFlooringBathroom_villaThreeBHKCarpetArea)}
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography className={classes.textHeader5}>4 BHK Carpet Area</Typography>
              <Typography className={classes.textHeader3}>
                {getQuantityValue(schema.indoorFlooringBathroom_villaFourBHKCarpetArea)} {getQuantityUnit(schema.indoorFlooringBathroom_villaFourBHKCarpetArea)}
              </Typography>
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  );
};
export default IndoorFlooringBathroomProjectInputs;
