import React from 'react';
import { Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import logo from '../../../../../assets/SDPlusLogoNew.png';

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#FFFFFF',
  },
  viewer: {
    width: window.innerWidth - 20,
    height: window.innerHeight - 20,
  },
  HeadingPage: {
    margin: '30px 30px 30px 30px',
    padding: 10,
    flexGrow: 1,
  },
  image: {
    height: '65px',
    width: '85px',
  },
  orgLogo: {
    height: '65px',
    width: '85px',
  },
  pageCover: {
    height: '100%',
    width: '521px',
    paddingTop: '10px',
  },
});

const HeadingPage = (props) => {
  return (
    <View style={styles.HeadingPage}>
      <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <View>
          <Image style={styles.image} src={logo} />
        </View>
        <View>
          <Image style={styles.orgLogo} src={`${props.asset && props.asset.URL}` + '?not-from-cache'} />
        </View>
      </View>
      <View style={{ flexDirection: 'row', display: 'flex' }}>
        {props.reportName !== 'Project' ? (
          <View>
            <View>
              <Text
                style={{
                  color: props.reportHeadingColor,
                  fontFamily: 'Lato',
                  fontWeight: 'Bold',
                  fontSize: '40px',
                  paddingTop: '5px',
                  paddingBottom: '2px',
                }}
              >
                {props.reportName}
              </Text>
            </View>
            <View>
              <Text style={{ marginTop: '2px', fontFamily: 'Lato', fontWeight: 'Bold', fontSize: '40px', color: '#2E2E2E' }}>
                {!props.category ? 'Report' : 'Impact Report'}
              </Text>
            </View>
          </View>
        ) : (
          <View>
            <View style={{ display: 'flex', flexDirection: 'row' }}>
              <Text
                style={{
                  color: props.reportHeadingColor,
                  fontFamily: 'Lato',
                  fontWeight: 'Bold',
                  fontSize: '40px',
                  paddingTop: '5px',
                  paddingBottom: '2px',
                }}
              >
                {props.reportName}
              </Text>
              <Text
                style={{
                  color: '#2E2E2E',
                  fontFamily: 'Lato',
                  fontWeight: 'Bold',
                  fontSize: '40px',
                  paddingTop: '5px',
                  paddingBottom: '2px',
                }}
              >
                {' Sustainability'}
              </Text>
            </View>
            <View>
              <Text style={{ marginTop: '2px', fontFamily: 'Lato', fontWeight: 'Bold', fontSize: '40px', color: '#2E2E2E' }}>Report</Text>
            </View>
          </View>
        )}
      </View>
      <View>
        <Text
          style={{
            fontFamily: 'Lato',
            color: '#666666',
            fontSize: '16px',
            marginTop: '10px',
          }}
        >
          {props.date}
        </Text>
        <View>
          <Text
            style={{
              fontFamily: 'Lato',
              fontWeight: 'bold',
              color: '#2E2E2E',
              fontSize: '24px',
              paddingTop: '20px',
              marginBottom: '2%',
            }}
          >
            {props.projectname}
          </Text>
        </View>
      </View>
      <View>
        <Image style={styles.pageCover} src={props.pageIcon} />
      </View>
    </View>
  );
};

export default HeadingPage;
