import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import Header from './Header';
import Footer from './Footer';
import rsToCr from '../../../../../utils/rsToCr';
import DonutChart from './Donut';
import numberWithCommas from '../../../../../utils/numberToComma';

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#FFFFFF',
  },
  viewer: {
    width: window.innerWidth - 20,
    height: window.innerHeight - 20,
  },
  viewPage2: {
    margin: '20px 30px 30px 30px',
    padding: 10,
    flexGrow: 1,
  },
  pageCover: {
    height: '100%',
    width: '521px',
    paddingTop: '20px',
  },
  footerMargin: {
    padding: 10,
  },
});

const ExecutiveReportPage = (props) => {
  const interventionData = props?.interventionData;
  const goalsData = props?.goals;
  const saving = interventionData && interventionData?.quantities?.overall?.finance?.opexSavings?.value;
  const fiveYearSaving = (interventionData && interventionData?.quantities?.overall?.finance?.opexSavings?.value * 5) || 0;
  const capex = (interventionData && interventionData?.quantities?.overall?.finance?.capex?.value) || 0;
  const energyGoal = goalsData?.filter((item) => item.id === 'goalsInsights_energy');
  const waterGoal = goalsData?.filter((item) => item.id === 'goalsInsights_water');
  const wasteGoal = goalsData?.filter((item) => item.id === 'goalsInsights_waste');
  const materialGoal = goalsData?.filter((item) => item.id === 'goalsInsights_waste');
  const energyCapex = interventionData?.categorized?.filter((item) => item.name === 'Energy')[0]?.quantities?.finance?.capex?.value;
  const waterCapex = interventionData?.categorized?.filter((item) => item.name === 'Water')[0]?.quantities?.finance?.capex?.value;
  const wasteCapex = interventionData?.categorized?.filter((item) => item.name === 'Waste')[0]?.quantities?.finance?.capex?.value;
  const materialCapex = interventionData?.categorized?.filter((item) => item.name === 'Materials')[0]?.quantities?.finance?.capex?.value;

  return (
    <View style={styles.viewPage2}>
      <Header companyname={props.companyname} />
      {/* new view */}
      <View style={{ flexDirection: 'row', display: 'flex', paddingTop: '10px' }}>
        <View>
          <Text style={{ fontFamily: 'Lato', fontWeight: 700, fontSize: '30px', textTransform: 'uppercase', color: '#1C241CED' }}>Your Project Is</Text>
        </View>
        <View>
          <Text
            style={{
              fontFamily: 'Lato',
              fontWeight: 700,
              fontSize: '30px',
              textTransform: 'uppercase',
              color: '#10DDC2',
              paddingLeft: '5px',
            }}
          >
            {`${props.sustainabilitypercentage}% More`}
          </Text>
        </View>
      </View>
      <View style={{ flexDirection: 'row', display: 'flex' }}>
        <View>
          <Text
            style={{
              fontFamily: 'Lato',
              fontWeight: 'Bold',
              fontSize: '30px',
              textTransform: 'uppercase',
              color: '#10DDC2',
            }}
          >
            Sustainable
          </Text>
        </View>
        <View>
          <Text style={{ fontFamily: 'Lato', fontWeight: 'Bold', fontSize: '30px', textTransform: 'uppercase', paddingLeft: '5px' }}>When</Text>
        </View>
      </View>
      <View style={{ flexDirection: 'row', display: 'flex' }}>
        <View>
          <Text style={{ fontFamily: 'Lato', fontWeight: 'Bold', fontSize: '30px', textTransform: 'uppercase' }}>Compared to the base case</Text>
        </View>
      </View>
      <View style={{ flexDirection: 'Column', display: 'flex', paddingTop: '30px' }}>
        <Text style={{ fontFamily: 'Lato', fontSize: '14px', color: '#1C241CED', fontWeight: 400 }}>Project savings in the next 5 years will be*</Text>
      </View>
      <View style={{ flexDirection: 'row', display: 'flex', paddingTop: '20px' }}>
        <View>
          <Text
            style={{
              fontFamily: 'Lato',
              fontWeight: 'Bold',
              fontSize: '72px',
              textTransform: 'uppercase',
              color: '#10DDC2',
            }}
          >
            {isNaN(fiveYearSaving) ? rsToCr('Rs', 0) : rsToCr('Rs', fiveYearSaving)}
          </Text>
        </View>
        <View>
          <Text style={{ fontFamily: 'Lato', fontWeight: 'Bold', fontSize: '36px', paddingLeft: '35px', paddingTop: '20px' }}>Cr</Text>
        </View>
      </View>
      {/* new view */}
      <View style={{ flexDirection: 'Column', display: 'flex', paddingTop: '20px' }}>
        <Text style={{ fontFamily: 'Lato', fontSize: '14px', color: '#1C241CED', fontWeight: 400 }}>With an increase in capital investment of</Text>
      </View>
      <View style={{ flexDirection: 'row', display: 'flex', paddingTop: '20px' }}>
        <View>
          <Text
            style={{
              fontFamily: 'Lato',
              fontWeight: 'Bold',
              fontSize: '72px',
              textTransform: 'uppercase',
              color: '#F57170',
            }}
          >
            {rsToCr('Rs', capex)}
          </Text>
        </View>
        <View>
          <Text style={{ fontFamily: 'Lato', fontWeight: 'Bold', fontSize: '36px', paddingLeft: '35px', paddingTop: '20px' }}>Cr</Text>
        </View>
      </View>
      {/* new view */}
      <View style={{ flexDirection: 'Column', display: 'flex', paddingTop: '20px' }}>
        <Text style={{ fontFamily: 'Lato', fontSize: '12px', color: '#1C241CED', fontWeight: 400 }}>{`*Project annual saving will be ${
          isNaN(saving) ? rsToCr('Rs', 0) : rsToCr('Rs', saving)
        } Cr`}</Text>
      </View>
      <View style={{ flexDirection: 'Column', display: 'flex', height: '53px', width: '100%', backgroundColor: '#13C6BD', marginTop: '20px' }}>
        <Text style={{ fontFamily: 'Lato', color: '#FFFFFF', fontWeight: 700, fontSize: '30px', paddingLeft: '10px', paddingTop: '8px' }}>GOALS</Text>
      </View>
      {/* new view */}
      <View style={{ flexDirection: 'row', display: 'flex', marginTop: '30px' }}>
        <View style={{ width: '5%' }} />
        <View style={{ direction: 'column', width: '25%' }}>
          <Text style={{ fontFamily: 'Lato', fontSize: '12px', fontWeight: 600, textAlign: 'center' }}>NET ZERO ENERGY</Text>
          <View>
            <DonutChart percentage={Math.round(energyGoal && energyGoal[0]?.value?.progressPercentage)} color="#FFF4D5" textColor="#F28D14" />
          </View>
          <Text style={{ fontFamily: 'Lato', fontSize: '8px', textAlign: 'center', marginTop: '2px' }}>Rs {numberWithCommas(Math.round(energyCapex))}</Text>
          <Text style={{ fontFamily: 'Lato', fontSize: '8px', textAlign: 'center', fontWeight: 400 }}>CAPEX Increase</Text>
        </View>
        <View style={{ width: '5%' }} />
        <View style={{ direction: 'column', width: '25%' }}>
          <Text style={{ fontFamily: 'Lato', fontSize: '12px', fontWeight: 600, textAlign: 'center' }}>NET ZERO WATER</Text>
          <View>
            <DonutChart percentage={Math.round(waterGoal && waterGoal[0]?.value?.progressPercentage)} color="#C9F5FF" textColor="#3BC7EE" />
          </View>
          <Text style={{ fontFamily: 'Lato', fontSize: '8px', textAlign: 'center', marginTop: '2px' }}>Rs {numberWithCommas(Math.round(waterCapex))}</Text>
          <Text style={{ fontFamily: 'Lato', fontSize: '8px', textAlign: 'center', fontWeight: 400 }}>CAPEX Increase</Text>{' '}
        </View>
        <View style={{ width: '5%' }} />
        <View style={{ direction: 'column', width: '25%' }}>
          <Text style={{ fontFamily: 'Lato', fontSize: '12px', fontWeight: 600, textAlign: 'center' }}>NET ZERO WASTE</Text>
          <View>
            <DonutChart percentage={Math.round(wasteGoal && wasteGoal[0]?.value?.progressPercentage)} color="#EEE1D7" textColor="#BC8A5F" />
          </View>
          <Text style={{ fontFamily: 'Lato', fontSize: '8px', textAlign: 'center', marginTop: '2px' }}>Rs {numberWithCommas(Math.round(wasteCapex))}</Text>
          <Text style={{ fontFamily: 'Lato', fontSize: '8px', textAlign: 'center', fontWeight: 400 }}>CAPEX Increase</Text>{' '}
        </View>
        <View style={{ width: '5%' }} />
        <View style={{ direction: 'column', width: '25%' }}>
          <Text style={{ fontFamily: 'Lato', fontSize: '10px', fontWeight: 600, textAlign: 'center' }}>NET ZERO EMBODIED CARBON</Text>
          <View style={{ marginBottom: '2px' }}>
            <DonutChart percentage={Math.round(materialGoal && materialGoal[0]?.value?.progressPercentage)} color="#EDCDFF" textColor="#916AAC" />
          </View>
          <Text style={{ fontFamily: 'Lato', fontSize: '8px', textAlign: 'center', marginTop: '6px' }}>Rs {numberWithCommas(Math.round(materialCapex))}</Text>
          <Text style={{ fontFamily: 'Lato', fontSize: '8px', textAlign: 'center', fontWeight: 400 }}>CAPEX Increase</Text>{' '}
        </View>
      </View>
      {/* new view */}
      <View style={{ marginTop: '1%' }}>
        <Footer year={props.year} />
      </View>
    </View>
  );
};

export default ExecutiveReportPage;
